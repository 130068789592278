export default {
    en: {
        '': '',
        'Accept All': 'Accept All',
        'Accept Selected': 'Accept Selected',
        'Account Expired!': 'Account Expired!',
        'Add': 'Add',
        'Add All': 'Add All',
        'Add Selected': 'Add Selected',
        'All': 'All',
        'Amount': 'Amount',
        'Apr': 'Apr',
        'Ascending': 'Ascending',
        'Aug': 'Aug',
        'Back': 'Back',
        'Balance (Due)': 'Balance (Due)',
        'Cancel': 'Cancel',
        'Category': 'Category',
        'Close': 'Close',
        'Close Filter': 'Close Filter',
        'Content': 'Content',
        'Current Password': 'Current Password',
        'Date': 'Date',
        'Dec': 'Dec',
        'Delete': 'Delete',
        'Delete All': 'Delete All',
        'Delete Selected': 'Delete Selected',
        'Deleted': 'Deleted',
        'Deleting...': 'Deleting...',
        'Descending': 'Descending',
        'Down': 'Down',
        'Download': 'Download',
        'Downloading...': 'Downloading...',
        'Download All': 'Download All',
        'Download Selected': 'Download Selected',
        'Downloaded': 'Downloaded',
        'E-Finance': 'E-Finance',
        'E-Billing': 'E-Billing',
        'E-CRM': 'E-CRM',
        'E-Drive': 'E-Drive',
        'E-HR': 'E-HR',
        'E-Learning': 'E-Learning',
        'E-Schedule': 'E-Schedule',
        'E-Shop': 'E-Shop',
        'E-Stock': 'E-Stock',
        'Edit': 'Edit',
        'Email': 'Email',
        'End Date': 'End Date',
        'End Time': 'End Time',
        'Enter New Password Again': 'Enter New Password Again',
        'Error!': 'Error!',
        'Exceed the limit of %s. Filter list before exporting again.': 'Exceed the limit of %s. Filter list before exporting again.',
        'Export': 'Export',
        'Expire': 'Expire',
        'Expire All': 'Expire All',
        'Expire Selected': 'Expire Selected',
        'Feb': 'Feb',
        'Go to Home Page': 'Go to Home Page',
        'Help': 'Help',
        'Home Page': 'Home Page',
        'Internal Server Error': 'Internal Server Error',
        'Internet or Server Error!': 'Internet or Server Error!',
        'Invalid Amount!': 'Invalid Amount!',
        'Invalid Data!': 'Invalid Data!',
        'Invalid mobile phone number.': 'Invalid mobile phone number.',
        'Invalid phone number.': 'Invalid phone number.',
        'Invalid Number!': 'Invalid Number!',
        'Invalid Number Range!': 'Invalid Number Range!',
        'Invalid Setup!': 'Invalid Setup!',
        'Jan': 'Jan',
        'Jul': 'Jul',
        'Jun': 'Jun',
        'List': 'List',
        'Location': 'Location',
        'Login': 'Login',
        'Logout': 'Logout',
        'Main System': 'Main',
        'Management': 'Management',
        'Mar': 'Mar',
        'May': 'May',
        'My Account': 'My Account',
        'My Profile': 'My Profile',
        'Name': 'Name',
        'Name/Mobile': 'Name/Mobile',
        'No': 'No',
        'No account!': 'No account!',
        'Nov': 'Nov',
        'Oct': 'Oct',
        'OK': 'OK',
        'Oops!': 'Oops!',
        'Open': 'Open',
        'Print': 'Print',
        'Picture': 'Picture',
        'Pictures': 'Pictures',
        'Please input data.': 'Please input data.',
        'Please input number.': 'Please input number.',
        'Please input URL.': 'Please input URL.',
        'Please input selection.': 'Please input selection.',
        'Please select.': 'Please select.',
        'Please select at least one.': 'Please select at least one.',
        'Privacy Policy': 'Privacy Policy',
        'Processing...': 'Processing...',
        'Profile': 'Profile',
        'Recall All': 'Recall All',
        'Recall Selected': 'Recall Selected',
        'Refresh Current Page': 'Refresh Current Page',
        'Reject Selected': 'Reject Selected',
        'Remove': 'Remove',
        'Review': 'Review',
        'Save': 'Save',
        'School2me': 'School2me',
        'Search': 'Search',
        'Sep': 'Sep',
        'Sort By': 'Sort By',
        'Start Date': 'Start Date',
        'Start Time': 'Start Time',
        'Status': 'Status',
        'Success': 'Success',
        'Successful!': 'Successful!',
        'Terms of Service': 'Terms of Service',
        'Up': 'Up',
        'Update': 'Update',
        'Updated': 'Updated',
        'View': 'View',
        'Warning': 'Warning',
        'Year': 'Year',
        'Yes': 'Yes',
        'You are not a Staff!': 'You are not a Staff!',
        'You are not a User!': 'You are not a User!',
    },
    zh: {
        '': '',
        'Accept All': '接受所有',
        'Accept Selected': '接受所选',
        'Account Expired!': '帐户已过期！',
        'Add': '添加',
        'Add All': '添加所有',
        'Add Selected': '添加所选',
        'All': '所有',
        'Amount': '共计',
        'Apr': '四月',
        'Ascending': '升序',
        'Aug': '八月',
        'Back': '退回',
        'Balance (Due)': '余额 (欠)',
        'Cancel': '取消',
        'Category': '类别',
        'Close': '关',
        'Close Filter': '关闭筛选',
        'Content': '内容',
        'Current Password': '当前密码',
        'Date': '日期',
        'Dec': '十二月',
        'Delete': '删除',
        'Delete All': '删除所有',
        'Delete Selected': '删除所选',
        'Deleted': '已删除',
        'Deleting...': '正在删除...',
        'Descending': '降序',
        'Down': '下',
        'Download': '下载',
        'Downloading...': '正在下载...',
        'Download All': '下载所有',
        'Download Selected': '下载所选',
        'Downloaded': '已下载',
        'E-Finance': '网上金融',
        'E-Billing': '网上账单',
        'E-CRM': '网上关系管理',
        'E-Drive': '网上存储',
        'E-HR': '网上人事管理',
        'E-Learning': '网上学习',
        'E-Schedule': '网上日程',
        'E-Shop': '网上商店',
        'E-Stock': '网上库存',
        'Edit': '更改',
        'Email': '电邮',
        'End Date': '结束日期',
        'End Time': '结束时间',
        'Enter New Password Again': '再次输入新密码',
        'Error!': '错误！',
        'Exceed the limit of %s. Filter list before exporting again.': '超过 %s 的限制。 再次输出前过滤列表。',
        'Export': '输出',
        'Expire': '过期',
        'Expire All': '过期全部',
        'Expire Selected': '过期已选',
        'Feb': '二月',
        'Go to Home Page': '去首页',
        'Help': '帮助信息',
        'Home Page': '主页',
        'Internal Server Error': '网路错误',
        'Internet or Server Error!': '互联网或服务器错误！',
        'Invalid Amount!': '错误的金额！',
        'Invalid Data!': '错误的数据！',
        'Invalid mobile phone number.': '错误的手机号码！',
        'Invalid phone number.': '错误的电话号码！',
        'Invalid Number!': '错误的号码！',
        'Invalid Number Range!': '错误的数字范围！',
        'Invalid Setup!': '错误的设置！',
        'Jan': '一月',
        'Jul': '七月',
        'Jun': '六月',
        'List': '列出',
        'Location': '地点',
        'Login': '登录',
        'Logout': '登出',
        'Main System': '主系统',
        'Management': '管理',
        'Mar': '三月',
        'May': '五月',
        'My Account': '我的帐户',
        'My Profile': '我的用户',
        'Name': '名称',
        'Name/Mobile': '姓名/手机',
        'New Password': '新密码',
        'No': '否',
        'No account!': '没有账户！',
        'Nov': '十一月',
        'Oct': '十月',
        'OK': '好',
        'Open': '开',
        'Oops!': '糟糕！',
        'Print': '印刷',
        'Picture': '图片',
        'Pictures': '图片',
        'Please input data.': '必须输入数据。',
        'Please input number.': '请输入号码。',
        'Please input URL.': '请输入网址。',
        'Please input selection.': '请输入选择。',
        'Please select.': '请选择。',
        'Please select at least one.': '请至少选择一个。',
        'Privacy Policy': '隐私政策',
        'Processing...': '处理中...',
        'Profile': '总则',
        'Recall All': '撤销所有',
        'Recall Selected': '撤销所选',
        'Refresh Current Page': '刷新当前页面',
        'Reject Selected': '拒绝所选',
        'Remove': '移除',
        'Review': '检阅',
        'Save': '保存',
        'School2me': 'School2me',
        'Search': '搜索',
        'Sep': '九月',
        'Sort By': '排序方式',
        'Start Date': '开始日期',
        'Start Time': '开始时间',
        'Status': '状态',
        'Successful!': '成功！',
        'Success': '成功',
        'Terms of Service': '服务条款',
        'Up': '上',
        'Update': '更新',
        'Updated': '已更新',
        'View': '查看',
        'Warning': '警告',
        'Year': '年',
        'Yes': '是',
        'You are not a Staff!': '您不是员工！',
        'You are not a User!': '您不是用户！',
    },
    ms: {
        '': '',
        'Accept All': 'Terima Semua',
        'Accept Selected': 'Terima Terpilih',
        'Account Expired!': 'Akaun Tamat!',
        'Add': 'Tambah',
        'Add All': 'Tambah Semua',
        'Add Selected': 'Tambah Terpilih',
        'All': 'Semua',
        'Amount': 'Jumlah',
        'Apr': 'Apr',
        'Ascending': 'Susunan menaik',
        'Aug': 'Ogos',
        'Back': 'Kembali',
        'Balance (Due)': 'Baki (Hutang)',
        'Cancel': 'Batalkan',
        'Category': 'Kategori',
        'Close': 'Tutup',
        'Close Filter': 'Tutup Penapis',
        'Content': 'Kandungan',
        'Current Password': 'Kata Laluan Semasa',
        'Date': 'Tarikh',
        'Dec': 'Des',
        'Delete': 'Padam',
        'Delete All': 'Padam Semua',
        'Delete Selected': 'Padam Terpilih',
        'Deleted': 'Dipadamkan',
        'Deleting...': 'Memadamkan ...',
        'Descending': 'Susunan menurun',
        'Down': 'Ke Bawah',
        'Download': 'Muat Turun',
        'Downloading...': 'Memuat turun...',
        'Download All': 'Download Semua',
        'Download Selected': 'Download Terpilih',
        'Downloaded': 'Dimuat turun',
        'E-Finance': 'E-Kewangan',
        'E-Billing': 'E-Bil',
        'E-CRM': 'E-CRM',
        'E-Drive': 'E-Penyimpanan',
        'E-HR': 'E-HR',
        'E-Learning': 'E-Pembelajaran',
        'E-Schedule': 'E-Jadual',
        'E-Shop': 'E-Kedai',
        'E-Stock': 'E-Stok',
        'Edit': 'Edit',
        'Email': 'E-mel',
        'End Date': 'Tarikh Tamat',
        'End Time': 'Masa Tamat',
        'Enter New Password Again': 'Masukkan Kata Laluan Baru Lagi',
        'Error!': 'Ralat!',
        'Exceed the limit of %s. Filter list before exporting again.': 'Melebihi had %s. Tapis senarai sebelum mengeksport semula.',
        'Export': 'Eksport',
        'Expire': 'Tamat',
        'Expire All': 'Tamat Semua',
        'Expire Selected': 'Tamat Terpilih',
        'Feb': 'Feb',
        'Go to Home Page': 'Pergi ke Halaman Utama',
        'Help': 'Maklumat Bantuan',
        'Home Page': 'Laman Utama',
        'Internal Server Error': 'Ralat Internet',
        'Internet or Server Error!': 'Ralat Internet atau Pelayan!',
        'Invalid Amount!': 'Jumlah Tidak Sah!',
        'Invalid Data!': 'Data Tidak Sah!',
        'Invalid mobile phone number.': 'Nombor telefon mudah alih tidak sah!',
        'Invalid phone number.': 'Nombor telefon tidak sah!',
        'Invalid Number!': 'Nombor Tidak Sah!',
        'Invalid Number Range!': 'Julat Nombor Tidak Sah!',
        'Invalid Setup!': 'Persediaan yang salah!',
        'Jan': 'Jan',
        'Jul': 'Jul',
        'Jun': 'Jun',
        'List': 'Senarai',
        'Location': 'Lokasi',
        'Login': 'Log Masuk',
        'Logout': 'Log keluar',
        'Main System': 'Sistem Utama',
        'Management': 'Pengurusan',
        'Mar': 'Mac',
        'May': 'Mei',
        'My Account': 'Akaun saya',
        'My Profile': 'Profil Saya',
        'Name': 'Nama',
        'Name/Mobile': 'Nama/Bimbit',
        'New Password': 'Kata Laluan Baru',
        'No': 'Tidak',
        'No account!': 'Tiada akaun!',
        'Nov': 'Nov',
        'Oct': 'Okt',
        'OK': 'Okey',
        'Open': 'Buka',
        'Oops!': 'Alamak!',
        'Print': 'Cetak',
        'Picture': 'Gambar',
        'Pictures': 'Gambar',
        'Please input data.': 'Sila masukkan data.',
        'Please input number.': 'Sila masukkan nombor.',
        'Please input URL.': 'Sila masukkan URL.',
        'Please input selection.': 'Sila masukkan pilihan.',
        'Please select.': 'Sila pilih.',
        'Please select at least one.': 'Sila pilih sekurang-kurangnya satu.',
        'Privacy Policy': 'Dasar Privasi',
        'Processing...': 'Memproses ...',
        'Profile': 'Profil',
        'Recall All': 'Tarik balik Semua',
        'Recall Selected': 'Tarik balik Terpilih',
        'Refresh Current Page': 'Muat semula Halaman Semasa',
        'Reject Selected': 'Tolak Terpilih',
        'Remove': 'Buang',
        'Review': 'Semak',
        'Save': 'Simpan',
        'School2me': 'School2me',
        'Search': 'Cari',
        'Sep': 'Sep',
        'Sort By': 'Disusun mengikut',
        'Start Date': 'Tarikh Mula',
        'Start Time': 'Masa Mula',
        'Status': 'Status',
        'Successful!': 'Berjaya!',
        'Success': 'Berjaya',
        'Terms of Service': 'Syarat Perkhidmatan',
        'Up': 'Ke Atas',
        'Update': 'Kemas kini',
        'Updated': 'Dikemas kini',
        'View': 'Lihat',
        'Warning': 'Amaran',
        'Year': 'Tahun',
        'Yes': 'Ya',
        'You are not a Staff!': 'Anda bukan Kakitangan!',
        'You are not a User!': 'Anda bukan Pengguna!',
    },
    id: {
        '': '',
        'Accept All': 'Terima Semua',
        'Accept Selected': 'Terima Dipilih',
        'Account Expired!': 'Akun Kedaluwarsa!',
        'Add': 'Tambah',
        'Add All': 'Tambahkan Semua',
        'Add Selected': 'Tambahkan yang Dipilih',
        'All': 'Semua',
        'Amount': 'Jumlah',
        'Apr': 'Apr',
        'Ascending': 'Urutan Naik',
        'Aug': 'Agus',
        'Back': 'Kembali',
        'Balance (Due)': 'Baki (Hutang)',
        'Cancel': 'Batalkan',
        'Category': 'Kategori...',
        'Close': 'Tutup',
        'Close Filter': 'Tutup Filter',
        'Content': 'Kandungan',
        'Current Password': 'kata Sandi Saat ini',
        'Date': 'Tanggal',
        'Dec': 'Des',
        'Delete': 'Hapus',
        'Delete All': 'Hapus Semua',
        'Delete Selected': 'Hapus Yang Dipilih',
        'Deleted': 'Dihapus',
        'Deleting...': 'Menghapus...',
        'Descending': 'Urutan Turun',
        'Down': 'Turun',
        'Download': 'Unduh',
        'Downloading...': 'Mengunduh...',
        'Download All': 'Unduh Semua',
        'Download Selected': 'Unduh Yang Dipilih',
        'Downloaded': 'Diunduh',
        'E-Finance': 'E-Keuangan',
        'E-Billing': 'E-Billing',
        'E-CRM': 'E-CRM',
        'E-Drive': 'E-Penyimpanan',
        'E-HR': 'E-HR',
        'E-Learning': 'E-Pembelajaran',
        'E-Schedule': 'E-Jadwal',
        'E-Shop': 'E-Kedai',
        'E-Stock': 'E-Stock',
        'Edit': 'Edit',
        'Email': 'Surel',
        'End Date': 'Tanggal Berakhir',
        'End Time': 'Akhir Waktu',
        'Enter New Password Again': 'Masukkan Kata Sandi Baru Lagi',
        'Error!': 'Kesalahan!',
        'Exceed the limit of %s. Filter list before exporting again.': 'Melebihi batas %s. Saring daftar sebelum mengekspor lagi.',
        'Export': 'Ekspor',
        'Expire': 'Tamat',
        'Expire All': 'Tamat Semua',
        'Expire Selected': 'Tamat Dipilih',
        'Feb': 'Feb',
        'Go to Home Page': 'Pergi ke Halaman Utama',
        'Help': 'Informasi bantuan',
        'Home Page': 'Halaman Depan',
        'Internal Server Error': 'Kesalahan Internet',
        'Internet or Server Error!': 'Internet atau Kesalahan Server!',
        'Invalid Amount!': 'Jumlah Salah!',
        'Invalid Data!': 'Data Salah!',
        'Invalid mobile phone number.': 'Nomor ponsel salah.',
        'Invalid phone number.': 'nomor telepon salah.',
        'Invalid Number!': 'Nomor Salah!',
        'Invalid Number Range!': 'Rentang Angka Tidak Valid!',
        'Invalid Setup!': 'Setup Salah!',
        'Jan': 'Jan',
        'Jul': 'Jul',
        'Jun': 'Jun',
        'List': 'Senarai',
        'Location': 'Lokasi',
        'Login': 'Log Masuk',
        'Logout': 'Log keluar',
        'Main System': 'Sistem Utama',
        'Management': 'Pengelolaan',
        'Mar': 'Mar',
        'May': 'Mei',
        'My Account': 'Akun saya',
        'My Profile': 'Profil Saya',
        'Name': 'Nama',
        'Name/Mobile': 'Nama/Ponsel',
        'New Password': 'Kata Sandi Baru',
        'No': 'Tidak',
        'No account!': 'Tidak ada akun!',
        'Nov': 'Nov',
        'Oct': 'Okt',
        'OK': 'Baik',
        'Open': 'Buka',
        'Oops!': 'Alamak!',
        'Print': 'Mencetak',
        'Picture': 'Foto-foto',
        'Pictures': 'Foto-foto',
        'Please input data.': 'Silakan masukan data.',
        'Please input number.': 'Silakan masukkan nomor.',
        'Please input URL.': 'Silakan masukkan URL.',
        'Please input selection.': 'Harap masukkan pilihan.',
        'Please select.': 'Silahkan pilih.',
        'Please select at least one.': 'Pilih setidaknya satu.',
        'Privacy Policy': 'Rahasia Pribadi',
        'Processing...': 'Pengolahan...',
        'Profile': 'Profil',
        'Recall All': 'Tarik balik Semua',
        'Recall Selected': 'Tarik balik yang Dipilih',
        'Refresh Current Page': 'Segarkan Halaman Saat Ini',
        'Reject Selected': 'Tolak Dipilih',
        'Remove': 'Hapus',
        'Review': 'Tinjau',
        'Save': 'Menyimpan',
        'School2me': 'School2me',
        'Search': 'Cari',
        'Sep': 'Sep',
        'Sort By': 'Sortir dengan',
        'Start Date': 'Mulai Tanggal',
        'Start Time': 'Waktu Mulai',
        'Status': 'Status',
        'Successful!': 'Berhasil!',
        'Success': 'Berhasil',
        'Terms of Service': 'Persyaratan Layanan',
        'Up': 'Naik',
        'Update': 'Perbarui',
        'Updated': 'Diperbarui',
        'View': 'Lihat',
        'Warning': 'Peringatan',
        'Year': 'Tahun',
        'Yes': 'Iya',
        'You are not a Staff!': 'Anda bukan Staf!',
        'You are not a User!': 'Anda bukan Pengguna!',
    },
};
