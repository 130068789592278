<template>
	<div class="err-page pl-30">
		<div class="msg-box">
			<!-- <h1 class="animated fadeInDown">{{$t('Error!')}}</h1> -->
			<h2 class="animated fadeInUp">
				<span v-if="errMessage" >{{$t('Oops!')}}<br>{{errMessage}}</span>
				<span v-else>{{$t('Successful!')}}</span>
			</h2>
			<h4 class="animated fadeInUp" v-if="user"><a href="" @click="reload" class="mr-10"><i class="el-icon-refresh-right"></i> {{$t('Refresh Current Page')}}</a> | <a href="" @click="goHome" class="ml-10"><i class="el-icon-s-home"></i> {{$t('Go to Home Page')}}</a></h4>
		</div>
	</div>
</template>

<script lang="ts">

	import RouteService from '@/services/common/route.service';
	import i18n from '@/lang';
	import { Vue, Component } from 'vue-property-decorator';
	import { UserStore } from '@/store/user.store';

	@Component({
		components: {
		},
	})
	export default class ErrPage extends Vue {

		private errMessage: string = i18n.t('Internet or Server Error!') as string;
		private user = UserStore._id;

		private reload() {
			RouteService.reload(this.$router, this.$router.currentRoute.path, this.$route.query, true);
		}

		private goHome() {
			RouteService.reload(this.$router, '/', {}, true);
		}

		mounted() {
			const e = new URL(location.href).searchParams.get('err');
			if (e) {
				this.errMessage = i18n.t(e) as string;
			} else if (e !== '') {
				this.errMessage = i18n.t('Internet or Server Error!') as string;
			} else {
				this.errMessage = '';
			}
		}

	}
</script>

<style lang="scss" scoped>
@import '../../layouts/system-layout/scss/_variables';
@import '../../layouts/system-layout/scss/_mixins';

.msg-box {
	// max-width: 360px;
	margin: 50px;

	h2 {
		@include text-bordered-shadow();
	}

	.mr-10 {
		margin-right: 10px;
	}

	.ml-10 {
		margin-left: 10px;
	}

	a {
		color: black;
	}

}
</style>

