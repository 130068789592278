const layoutConfig = {
	contentOnly: {
		navPos: '',
		boxed: false,
		footer: '',
	},
	pageOnly: {
		navPos: '',
		boxed: true,
		footer: '',
	},
	navLeft: {
		navPos: 'left',
		boxed: false,
		footer: 'below',
	},
	navRight: {
		navPos: 'right',
		boxed: false,
		footer: 'below',
	},
	navTop: {
		navPos: 'top',
		boxed: false,
		footer: 'below',
	},
	// navTopFooter: {
	// 	navPos: 'top',
	// 	boxed: false,
	// 	footer: 'below',
	// },
	navBottom: {
		navPos: 'bottom',
		boxed: false,
		footer: 'below',
	},
};

export default layoutConfig;
