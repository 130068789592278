export default class BrowserService {

    static changeFavicon(link: string) {
        let $favicon = document.querySelector('link[rel="icon"]') as any;
        // If a <link rel="icon"> element already exists,
        // change its href to the given link.
        if ($favicon !== null) {
            $favicon.href = link;
            // Otherwise, create a new element and append it to <head>.
        } else {
            $favicon = document.createElement('link');
            $favicon.rel = 'icon';
            $favicon.href = link;
            document.head.appendChild($favicon);
        }

    }
}
