
import { UserStore } from '@/store/user.store';
import ElementService from '../common/element.service';
import UtilService from '../common/util.service';
import CommonService from '../system/common.service';

export default class AccountService {

    private functions = (o: any) => {
        return {
            formatLogUser: CommonService.formatLogUser,
            formatBeacon: AccountService.formatBeacon.bind(o),
        };
    }

    constructor(obj: any, meta: any) {
        if (!meta.args) { meta.args = {}; }
        meta.args._id = UtilService.getId(UserStore.account);
        ElementService.initService(obj, meta, this.functions(obj));
    }

    static formatBeacon(val: any, item: any, ui: any) {
        return val && val.macId ? `${val.name}: ${val.macId}` : val;
    }

}
