<template>
	<div class="page-single-view flex column" @ready="setBackTop" ref="pageView" v-loading="!data && (!error || (!error.all && !error.page))">
		<div class="page-view-content flex column scrollable" v-if="!(error && error.all)" v-loading="!loading || loading.page">
			<elem-page-header v-if="data && data.page && data.page.view && data.page.view.title" :title="$t(data.page.view.title)" :info="data.page.view.options && data.page.view.options.titleInfo ? ' (' + $t(data.page.view.options.titleInfo) + ')' : ''">
				<template v-slot:right v-if="data.page.view.tag">
					<span :class="getTagClass(data.page.view.tag)" class="p-5 b-rad-3" v-html="formatTag(data.page.view.tag)"></span>
				</template>
			</elem-page-header>
			<!-- Need the key below to force a redraw when collapse/expand -->
			<div v-if="data && data.page && data.page.view" :key="collapse + clientWidth" class="page-view-form box grow flex grid">
				<div class="flex column box grow pr-10">
					<el-alert v-if="error && error.page && !(data && data.page && data.page.view && data.page.view.options && data.page.view.options.buttonBottom)" class="mb-10" type="error" :closable="false" show-icon :description="error.page"></el-alert>
					<el-alert v-if="success && !(data && data.page && data.page.view && data.page.view.options && data.page.view.options.buttonBottom)" class="mb-10" type="success" :closable="false" show-icon :description="success"></el-alert>
					<elem-form ref="formRef" :form.sync="formProp" :view="data.page.view" :value="data.page.value" :functions="functions"
						:resized="pageView && pageView.$el ? pageView.$el.clientWidth : 0" :size="size" :error.sync="error"
						:setDirty="data.page.view.setDirty"
					></elem-form>
					<el-alert v-if="error && error.page && (data && data.page && data.page.view && data.page.view.options && data.page.view.options.buttonBottom)" type="error" :closable="false" show-icon :description="error.page"></el-alert>
					<el-alert v-if="success && (data && data.page && data.page.view && data.page.view.options && data.page.view.options.buttonBottom)" type="success" :closable="false" show-icon :description="success"></el-alert>
				</div>
				<div class="flex column box" v-if="(data.page.view.filters && data.page.view.filters.length > 0) || (data.page.view.menu && data.page.view.menu.items && data.page.view.menu.items.length > 0)" :class="collapse ? '' : (data.page.view && data.page.view.menu && data.page.view.menu.class ? data.page.view.menu.class : 'col-2')">
					<el-button v-if="data.page.view.menu" size="small" class="box" :class="collapse ? 'collapse-button' : ''" @click="clickCollapse()" :icon="collapse ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"></el-button>
					<table v-if="!collapse && data.page.view.filters && data.page.view.filters.length > 0">
						<tr v-for="s in data.page.view.filters" :key="s.model" class="pb-5" :class="s.class">
							<td v-if="s.label" class="filter-row-label pr-5" :size="'small'">{{$t(s.label)}}</td>
							<td style="width:70%"><component :view="s" :style="s.style" :value.sync="filterProp" :model-key="s.model" :functions="functions" :size="'small'" :is="getComponent(s.type)"></component></td>
						</tr>
					</table>
					<el-menu
						v-if="data.page.view.menu"
						:default-active="currentIndex"
						:collapse="collapse"
						class="box grow"
						>
						<template v-for="item in data.page.view.menu.items">
							<el-menu-item :index="item.index" v-if="!item.subs" :key="item.index" @click="clickMenuItem(item)">
								<i :class="item.icon" :disabled="item.disabled" style="padding-left:0"></i>
								<span>{{$t(item.name)}}</span>
							</el-menu-item>
							<el-submenu v-else :index="item.index" :key="item.index">
								<template slot="title">
									<i :class="item.icon"></i>
									<span>{{$t(item.name)}}</span>
								</template>
								<el-menu-item :index="subitem.index" v-for="subitem in item.subs" :key="subitem.index" @click="clickMenuItem(subitem)">
									<i :class="subitem.icon" :disabled="subitem.disabled"></i>
									<span>{{$t(subitem.name)}}</span>
								</el-menu-item>
							</el-submenu>
						</template>
					</el-menu>
				</div>
			</div>
		</div>
		<div v-if="error && error.all" style="width: 100%">
			<el-alert type="error" :closable="false" show-icon :description="error.all"></el-alert>
		</div>
		<slot name="popup"></slot>
		<el-backtop v-if="data && data.page && data.page.view" target=".page-view-content" :right="15" :bottom="50">
			<div class="page-button">
				<i class="el-icon-caret-top"></i>
			</div>
		</el-backtop>
	</div>
</template>

<script lang="ts">

	import { Vue, Component, Prop, Ref, PropSync, Watch } from 'vue-property-decorator';
	import { Alert, Backtop, Button, Loading, Menu, MenuItem, Submenu, Form} from 'element-ui';
	import ElemPageHeader from '@/views/component/elem-page-header.vue';
	import ElemForm from '@/views/component/elem-form.vue';
	import UtilService from '@/services/common/util.service';
	import LodashService from '@/services/common/lodash.service';
	import RouteService from '@/services/common/route.service';
	import ComponentService from '@/services/common/component.service';

	Vue.use(Loading.directive);
	Vue.component(Menu.name, Menu);
	Vue.component(MenuItem.name, MenuItem);
	Vue.component(Submenu.name, Submenu);
	Vue.component(Alert.name, Alert);
	Vue.component(Backtop.name, Backtop);
	Vue.component(Button.name, Button);

	@Component({
		components: {
            ...ComponentService.importComponents(), ElemPageHeader, ElemForm,
        },
	})
	export default class PageView extends Vue {

		@PropSync('form') formProp!: Form | null;
		@Prop({}) loading!: any;
		@Prop({}) error: any;
		@Prop({}) success: any;
		@Prop({}) data!: any;
		@PropSync('filter') filterProp!: {};
		@Prop({default: 'medium'}) size!: any;
		@Prop({}) readonly functions: any;
		@Ref('pageView') readonly pageView!: Vue;
		private backTopSize: number = 40;
		private collapse: boolean = false;
		private currentIndex: string = '0';
		private clientWidth: number = 0;

		private getComponent(type: string) {
            return ComponentService.getComponent(type);
        }

		private async clickMenuItem(row: any) {
			const clickFunc = row.clickFunc || this.data.page.view.menu.clickFunc;
			if (row && clickFunc && this.functions && this.functions[clickFunc]) {
				const args: any = {};
				if (this.data.page.view.menu.fields) {
					for (const f of this.data.page.view.menu.fields) {
						if (row.hasOwnProperty(f)) {
							args[f] = row[f];
						}
					}
				}
				await this.functions[clickFunc](this.data.page.value, this.data.page.value, {args});
			}
			this.$nextTick(() => {
				this.setMenuState(row.index);
			});
		}

		private setBackTop() {
			this.backTopSize = (this.$el ? this.$el.clientWidth : 0) + 15;
		}

		private clickCollapse() {
			(this as any).$nextTick(() => {
				this.collapse = !this.collapse;
			});
		}

		private setMenuState(index: string | null) {
			if (this.data && this.data.page && this.data.page.view && this.$el) {
				this.collapse = this.$el.clientWidth < 720;
				if (index !== null) {
					this.currentIndex = index;
					const i: any = this.$route.query || {};
					i.index = index;
					RouteService.addParamsToUrl(this.$route, i);
				}
			}
		}

		private resize() {
			this.clientWidth = this.$el ? this.$el.clientWidth : 0;
			if (this.data && this.data.page && this.data.page.view && this.data.page.view.menu) {
				this.setMenuState(RouteService.getQueryParam('index') || (this.data.page.view.menu.currentIndex ? this.data.page.view.menu.currentIndex : null));
			}
			this.setBackTop();
		}
		private resizeDebounced = LodashService.throttle(this.resize.bind(this), 300);

		private getTagClass(f: any) {
			const value = this.data && this.data.page && this.data.page.value ? LodashService.get(this.data.page.value, f.model) : '';
			return 'header-badge badge-' + UtilService.getColor(f.cell, value);
		}

		private updateTabErrors() {
			if ((this.$refs.formRef as any)) { (this.$refs.formRef as any).updateTabErrors(); }
		}

		private formatTag(f: any) {
			return f && this.data && this.data.page && this.data.page.value ? UtilService.formatData(LodashService.get(this.data.page.value, f.model), this.data.page.value, f, []) : '';
		}

		@Watch('data.page')
        onPageChanged(val: any, oldVal: any) {
			this.resizeDebounced();
		}

		mounted() {
			this.resizeDebounced();
			window.addEventListener('resize', this.resizeDebounced);
		}

		beforeDestroy() {
			window.removeEventListener('resize', this.resizeDebounced);
		}

	}
</script>

<style lang="scss">
	@import '../../layouts/system-layout/scss/variables';

	.page-single-view {

		padding: 0 !important;
		height: 100%;
		overflow: hidden;
		// display: flex;
		// flex-flow: column;
		// overflow: scroll;

		.el-menu-item.is-active {
			background-color: #1C7EBB;
			color: #fff;
    		font-weight: bold;
		}

		.el-submenu__title > i, .el-menu-item > i {
			margin-right: 5px;
			width: 24px;
			text-align: center;
			font-size: 18px;
			vertical-align: middle;
		}

		.el-submenu .el-menu-item {
			height: 40px;
			line-height: 40px;
		}

		.page-header {
			border-bottom-width: 1px;
			min-height: 32px;
		}

		.page-view-content {
			padding: 10px;
			display: flex;
			flex-flow: column;
			flex-grow: 1;

			.page-view-form {
				border: 0;
				border-bottom-right-radius: 4px;
				border-bottom-left-radius: 4px;
				display: flex;
				flex-grow: 1;
				padding: 10px;
				background-color: #FFFFFF;
				.elem-form {
					display: flex;
					flex-grow: 1;
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}

		.page-button {
			height: 40px;
			width: 40px;
			background-color: #f2f5f6;
			box-shadow: 0 0 6px rgba(0,0,0, .12);
			text-align: center;
			line-height: 40px;
			color: #1989fa;
			border: 1px solid #ebebeb;
			border-radius: 50%;
		}

		.page-button-right {
			display: inline-block;
			float: right;
		}

		.page-button-left {
			display: inline-block;
		}

		.tag-right {
			height: 40px;
			line-height: 40px;
			display: inline-block;
			margin-right: 10px;
			float: right;
		}

	}

	.el-menu--vertical {
		.el-menu-item i {
			padding-right: 5px;
		}
	}

	@media (max-width: 768px) {
		.page-single-view {
			.page-view-content {
				padding: 5px;

				.page-view-form {
					padding: 5px;
					.elem-form {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
			.collapse-button {
				padding: 8px 5px;
				width: 32px;
			}

			.el-menu--collapse {
				width: 32px;
			}

			.el-submenu__title, .el-menu-item {
				height: 32px;
				line-height: 32px;
				padding: 0 5px !important;
				> i {
					font-size: 12px;
				}
			}
			
		}

		.el-menu--vertical {
			.el-menu-item i {
				padding-right: 5px;
			}
		}

	}

</style>


