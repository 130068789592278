export default {
    en: {
        ' ': ' ',
        '%': '%',
        'Accept': 'Accept',
        'Access Denied!': 'Access Denied!',
        'Account': 'Account',
        'Account Group': 'Account Group',
        'Account Collections': 'Account Collections',
        'Account Payments': 'Account Payments',
        'Accounting Code': 'Accounting Code',
        'Accounting Integration': 'Accounting Integration',
        'Accounts': 'Accounts',
        'Active': 'Active',
        'Address': 'Address',
        'Admin': 'Admin',
        'Administrator': 'Administrator',
        'Alerts': 'Alerts',
        'Already exists!': 'Already exists!',
        'Appointment': 'Appointment',
        'Appointments': 'Appointments',
        'Approve': 'Approve',
        'Approvers': 'Approvers',
        'Approved': 'Approved',
        'Are you sure?': 'Are you sure?',
        'Are you sure to delete this?': 'Are you sure to delete this?',
        'Asset': 'Asset',
        'Assets': 'Assets',
        'Assignment': 'Assignment',
        'Assignments': 'Assignments',
        'Attendance': 'Attendance',
        'Attendances': 'Attendances',
        'Balance': 'Balance',
        'Bank Transfer': 'Bank Transfer',
        'Batch': 'Batch',
        'Beacons': 'Beacons',
        'Bills': 'Bills',
        'Book': 'Book',
        'Books': 'Books',
        'Broadcasts': 'Broadcasts',
        'Calendar': 'Calendar',
        'Cancelled': 'Cancelled',
        'Cannot exceed': 'Cannot exceed',
        'Cash': 'Cash',
        'Change All': 'Change All',
        'Change Selected': 'Change Selected',
        'Cheque': 'Cheque',
        'Claim': 'Claim',
        'Claims': 'Claims',
        'Class': 'Class',
        'Classes': 'Classes',
        'Clear': 'Clear',
        'Closed': 'Closed',
        'Collections': 'Collections',
        'Commissions': 'Commissions',
        'Companies': 'Companies',
        'Company': 'Company',
        'Confidentials': 'Confidentials',
        'Contacts': 'Contacts',
        'Continue': 'Continue',
        'Continue to force delete?': 'Continue to force delete?',
        'Copied! You can paste this link in the content of other modules or social media.': 'Copied! You can paste this link in the content of other modules or social media.',
        'Copy': 'Copy',
        'Copy All': 'Copy All',
        'Copy Selected': 'Copy Selected',
        'Copy to other Account': 'Copy to other Account',
        'Copy to other Accounts': 'Copy to other Accounts',
        'Country': 'Country',
        'Countries': 'Countries',
        'Credit Card': 'Credit Card',
        'Credit Notes': 'Credit Notes',
        'Daily Log': 'Daily Log',
        'Daily Logs': 'Daily Logs',
        'Dashboard': 'Dashboard',
        'Date/Details': 'Date/Details',
        'Day': 'Day',
        'Debit Card': 'Debit Card',
        'Decline': 'Decline',
        'Declined': 'Declined',
        'Description': 'Description',
        'Details': 'Details',
        'Delivery Remarks': 'Delivery Remarks',
        'Deliveries': 'Deliveries',
        'Division': 'Division',
        'Divisions': 'Divisions',
        'Document': 'Document',
        'Documents': 'Documents',
        'Donations': 'Donations',
        'Donation Drives': 'Donation Drives',
        'Done': 'Done',
        'Due Date': 'Due Date',
        'Duties': 'Duties',
        'Duty': 'Duty',
        'Draft': 'Draft',
        'E-Wallet': 'E-Wallet',
        'End': 'End',
        'Engagement': 'Engagement',
        'Enquiries': 'Enquiries',
        'Enquiry': 'Enquiry',
        'Enrollment': 'Enrollment',
        'Enrollments': 'Enrollments',
        'Enter date.': 'Enter date.',
        'Enter description.': 'Enter description.',
        'Enter time.': 'Enter time.',
        'Enter date and time.': 'Enter date and time.',
        'Enter remarks.': 'Enter remarks.',
        'Enter same password as your login.': 'Enter same password as your login.',
        'Expense': 'Expense',
        'Expenses': 'Expenses',
        'Event': 'Event',
        'Events': 'Events',
        'Expired': 'Expired',
        'Failed': 'Failed',
        'Failed to delete as data is required in other modules!': 'Failed to delete as data is required in other modules!',
        'Failure': 'Failure',
        'Files': 'Files',
        'For internal use only.': 'For internal use only.',
        'From': 'From',
        'Group': 'Group',
        'Groups': 'Groups',
        'Holiday': 'Holiday',
        'Holidays': 'Holidays',
        'Homework': 'Homework',
        'Hub': 'Hub',
        'ID': 'ID',
        'Import': 'Import',
        'Important': 'Important',
        'Inactive': 'Inactive',
        'Incomes': 'Incomes',
        'Individuals': 'Individuals',
        'Integration': 'Integration',
        'Invalid email address.': 'Invalid email address.',
        'Invalid Password!': 'Invalid Password!',
        'Inventory': 'Inventory',
        'Inventories': 'Inventories',
        'Leave': 'Leave',
        'Leaves': 'Leaves',
        'Ledgers': 'Ledgers',
        'Lesson': 'Lesson',
        'Lessons': 'Lessons',
        'Logs': 'Logs',
        'Lookups': 'Lookups',
        'Main': 'Main',
        'Media': 'Media',
        'Modules': 'Modules',
        'Member': 'Member',
        'Members': 'Members',
        'Mobile': 'Mobile',
        'Mobiles': 'Mobiles',
        'Month': 'Month',
        'More': 'More',
        'Message Queues': 'Message Queues',
        'Messages': 'Messages',
        'Message': 'Message',
        'Module': 'Module',
        'Monitors': 'Monitors',
        'Mosques': 'Mosques',
        'Must select other account!': 'Must select other account!',
        'No change!': 'No change!',
        'No class!': 'No class!',
        'No group!': 'No group!',
        'No Data!': 'No Data!',
        'Notification': 'Notification',
        'Notifications': 'Notifications',
        'Newsletters': 'Newsletters',
        'Online': 'Online',
        'Operation': 'Operation',
        'Optional': 'Optional',
        'Order': 'Order',
        'Orders': 'Orders',
        'Others': 'Others',
        'Overdue': 'Overdue',
        'Package': 'Package',
        'Parent': 'Parent',
        'Parents': 'Parents',
        'Partners': 'Partners',
        'Payment': 'Payment',
        'Payments': 'Payments',
        'Payslip': 'Payslip',
        'Payslips': 'Payslips',
        'PDF': 'PDF',
        'Pending': 'Pending',
        'Pending Payment': 'Pending Payment',
        'Pending Review': 'Pending Review',
        'Period': 'Period',
        'Petty Cash': 'Petty Cash',
        'Phones': 'Phones',
        'Portfolio': 'Portfolio',
        'Portfolios': 'Portfolios',
        'Publish': 'Publish',
        'Published': 'Published',
        'Purchase': 'Purchase',
        'Purchases': 'Purchases',
        'Payment Vouchers': 'Payment Vouchers',
        'Please wait...': 'Please wait...',
        'Point': 'Point',
        'Points': 'Points',
        'Product': 'Product',
        'Products': 'Products',
        'Program': 'Program',
        'Programs': 'Programs',
        'Progress': 'Progress',
        'Pricing Code': 'Pricing Code',
        'Pricing Packages': 'Pricing Packages',
        'Pricing Items': 'Pricing Items',
        'Pricings': 'Pricings',
        'Quantity': 'Quantity',
        'Queues': 'Queues',
        'Quotations': 'Quotations',
        'Recall': 'Recall',
        'Recalled': 'Recalled',
        'Receiving': 'Receiving',
        'Recipient': 'Recipient',
        'Recipients': 'Recipients',
        'Ref.': 'Ref.',
        'Refresh List': 'Refresh List',
        'Refresh?': 'Refresh?',
        'Refunds': 'Refunds',
        'Registration': 'Registration',
        'Registrations': 'Registrations',
        'Reject': 'Reject',
        'Rejected': 'Rejected',
        'Remarks': 'Remarks',
        'Remarks (for internal use)': 'Remarks (for internal use)',
        'Repeat header info in every row.': 'Repeat header info in every row.',
        'Report': 'Report',
        'Reports': 'Reports',
        'Request': 'Request',
        'Requests': 'Requests',
        'Resend': 'Resend',
        'Resend (Alert)': 'Resend (Alert)',
        'Resend (No Alert)': 'Resend (No Alert)',
        'Resent': 'Resent',
        'Reset details?': 'Reset details?',
        'Response': 'Response',
        'Responses': 'Responses',
        'Revise': 'Revise',
        'Revised': 'Revised',
        'Reviewed': 'Reviewed',
        'Re-Open': 'Re-Open',
        'Roles': 'Roles',
        'Sales': 'Sales',
        'Save All': 'Save All',
        'Save Selected': 'Save Selected',
        'Saved': 'Saved',
        'Scheduled': 'Scheduled',
        'Schedule': 'Schedule',
        'Schedules': 'Schedules',
        'Sections': 'Sections',
        'Select': 'Select',
        'Select Items': 'Select Items',
        'Send': 'Send',
        'Send (Alert)': 'Send (Alert)',
        'Send (No Alert)': 'Send (No Alert)',
        'Send All': 'Send All',
        'Send Selected': 'Send Selected',
        'Send Later': 'Send Later',
        'Send Now': 'Send Now',
        'Send Revised': 'Send Revised',
        'Send Revised (Alert)': 'Send Revised (Alert)',
        'Send Revised (No Alert)': 'Send Revised (No Alert)',
        'Send To': 'Send To',
        'Sent': 'Sent',
        'Shares': 'Shares',
        'Shorteners': 'Shorteners',
        'Selected': 'Selected',
        'Sort': 'Sort',
        'Staff': 'Staff',
        'Start': 'Start',
        'Statements': 'Statements',
        'Stocks': 'Stocks',
        'Student': 'Student',
        'Students': 'Students',
        'Subject': 'Subject',
        'Subjects': 'Subjects',
        'Submission': 'Submission',
        'Submissions': 'Submissions',
        'Submit': 'Submit',
        'Submitted': 'Submitted',
        'Subscribers': 'Pelanggan',
        'Sub-Total': 'Sub-Total',
        'Summary': 'Summary',
        'Supervisor': 'Supervisor',
        'Tags': 'Tags',
        'Task': 'Task',
        'Tasks': 'Tasks',
        'Tax': 'Tax',
        'Tax (%)': 'Tax (%)',
        'Temperature': 'Temperature',
        'The data has been updated by other user. Please refresh the page and try again.': 'The data has been updated by other user. Please refresh the page and try again.',
        'Thermometers': 'Thermometers',
        'Time': 'Time',
        'Title': 'Title',
        'To': 'To',
        'Total': 'Total',
        'Tracking': 'Tracking',
        'Type': 'Type',
        'Unknown': 'Unknown',
        'Unlock': 'Unlock',
        'Unpublish': 'Unpublish',
        'Unpublished': 'Unpublished',
        'Updated By': 'Updated By',
        'Upload Error!': 'Upload Error!',
        'Upload File': 'Upload File',
        'Unselected': 'Unselected',
        'User': 'User',
        'Users': 'Users',
        'Validation Error!': 'Validation Error!',
        'Visitor': 'Visitor',
        'Visitors': 'Visitors',
        'Work Hour': 'Work Hour',
        'Work Hours': 'Work Hours',
        'You have not save changes! Continue?': 'You have not save changes! Continue?',
    },
    zh: {
        ' ': ' ',
        '%': '%',
        'Accept': '接受',
        'Access Denied!': '进入被拒绝',
        'Account': '帐户',
        'Account Group': '帐户组',
        'Account Collections': '账户收款',
        'Account Payments': '帐户付款',
        'Accounting Code': '会计代码',
        'Accounting Integration': '会计系统集成',
        'Accounts': '帐户',
        'Active': '活跃',
        'Address': '地址',
        'Admin': '管理',
        'Administrator': '管理员',
        'Alerts': '警报',
        'Already exists!': '已经有了！',
        'Appointment': '预约',
        'Appointments': '预约',
        'Approve': '批准',
        'Approvers': '批准者',
        'Approved': '已批准',
        'Are you sure?': '您确定吗？',
        'Are you sure to delete this?': '您确定要删除吗？',
        'Asset': '资产',
        'Assets': '资产',
        'Assignment': '作业',
        'Assignments': '作业',
        'Attendance': '出勤',
        'Attendances': '出勤',
        'Balance': '结余',
        'Bank Transfer': '银行转帐',
        'Batch': '批处理',
        'Beacons': '信标',
        'Bills': '账单',
        'Book': '书本',
        'Books': '书本',
        'Broadcasts': '广播',
        'Calendar': '日历',
        'Cancelled': '已取消',
        'Cannot exceed': '不能超过',
        'Cash': '现金',
        'Change All': '更改所有',
        'Change Selected': '更改所选',
        'Cheque': '支票',
        'Claim': '索偿',
        'Claims': '索偿',
        'Class': '班级',
        'Classes': '班级',
        'Clear': '清除',
        'Closed': '已关闭',
        'Collections': '收款',
        'Commissions': '佣金',
        'Companies': '公司',
        'Company': '公司',
        'Confidentials': '机密',
        'Contacts': '联系',
        'Continue': '继续',
        'Continue to force delete?': '继续强制删除？',
        'Copied! You can paste this link in the content of other modules or social media.': '已复制！您可以将此链接粘贴到其他模块或社交媒体的内容中。',
        'Copy': '复制',
        'Copy All': '复制所有',
        'Copy Selected': '复制所选',
        'Copy to other Account': '复制到其他账户',
        'Copy to other Accounts': '复制到其他账户',
        'Country': '国家',
        'Countries': '国家',
        'Credit Card': '信用卡',
        'Credit Notes': '信用票据',
        'Daily Log': '日记',
        'Daily Logs': '日记',
        'Dashboard': '仪表盘',
        'Date/Details': '日期/详细信息',
        'Day': '日',
        'Debit Card': '借记卡',
        'Decline': '拒绝接受',
        'Declined': '拒绝接受',
        'Description': '描述',
        'Details': '详情',
        'Deliveries': '交货',
        'Delivery Remarks': '交货备注',
        'Divisions': '部门',
        'Division': '部门',
        'Document': '文件',
        'Documents': '文件',
        'Donations': '捐款',
        'Donation Drives': '捐款活动',
        'Done': '完毕',
        'Due Date': '到期日',
        'Duties': '职责',
        'Duty': '职责',
        'Draft': '草稿',
        'E-Wallet': '电子钱包',
        'End': '结束',
        'Engagement': '参与',
        'Enquiries': '咨询',
        'Enquiry': '咨询',
        'Enrollment': '登记',
        'Enrollments': '登记',
        'Enter date.': '输入日期。',
        'Enter description.': '输入描述。',
        'Enter time.': '输入时间。',
        'Enter date and time.': '输入日期和时间。',
        'Enter remarks.': '输入备注。',
        'Enter same password as your login.': '输入相同与您登录的密码。',
        'Expense': '开支',
        'Expenses': '开支',
        'Event': '活动',
        'Events': '活动',
        'Expired': '已过期',
        'Failed': '失败',
        'Failed to delete as data is required in other modules!': '删除失败，因为其他地方仍然需要数据！',
        'Failure': '失败',
        'Files': '文件',
        'For internal use only.': '仅限内部使用。',
        'From': '从',
        'Group': '团组',
        'Groups': '团组',
        'Holiday': '假期',
        'Holidays': '假期',
        'Homework': '功课',
        'Hub': '枢纽',
        'ID': '编号',
        'Import': '输入',
        'Important': '重要',
        'Inactive': '不活跃',
        'Incomes': '收入',
        'Individuals': '个人',
        'Integration': '系统集成',
        'Invalid email address.': '不正确的电邮地址。',
        'Invalid Password!': '密码错误！',
        'Inventory': '库存',
        'Inventories': '库存',
        'Leave': '请假',
        'Leaves': '请假',
        'Ledgers': '总帐',
        'Lesson': '课业',
        'Lessons': '课业',
        'Logs': '记录',
        'Lookups': '查找表',
        'Main': '主页',
        'Media': '媒体',
        'Module': '模块',
        'Modules': '模块',
        'Member': '会员',
        'Members': '会员',
        'Mobile': '手机',
        'Mobiles': '手机',
        'Month': '月',
        'More': '更多',
        'Message Queues': '信息队列',
        'Message': '信息',
        'Messages': '信息',
        'Monitors': '监视器',
        'Mosques': '清真寺',
        'Must select other account!': '必须选择其他帐户！',
        'No change!': '没有改变！',
        'No class!': '没有班级！',
        'No group!': '没有团组！',
        'No Data!': '没有数据！',
        'Notification': '通知',
        'Notifications': '通知',
        'Newsletters': '通讯',
        'Online': '线上',
        'Operation': '运行',
        'Optional': '可选',
        'Order': '订单',
        'Orders': '订单',
        'Others': '其他',
        'Overdue': '逾期',
        'Package': '配套',
        'Parent': '家长',
        'Parents': '家长',
        'Partners': '伙伴',
        'Payment': '付款',
        'Payments': '付款',
        'Payslip': '工资单',
        'Payslips': '工资单',
        'PDF': 'PDF',
        'Pending': '有待',
        'Pending Payment': '待付款',
        'Pending Review': '待检阅',
        'Period': '时期',
        'Petty Cash': '小钱',
        'Phones': '电话',
        'Portfolio': '选辑',
        'Portfolios': '选辑',
        'Publish': '发布',
        'Published': '已发布',
        'Purchase': '购买',
        'Purchases': '购买',
        'Payment Vouchers': '付款凭证',
        'Please wait...': '请稍等...',
        'Point': '积分',
        'Points': '积分',
        'Product': '产品',
        'Products': '产品',
        'Program': '培训班',
        'Programs': '培训班',
        'Progress': '进展',
        'Pricings': '定价',
        'Pricing Code': '定价代码',
        'Pricing Packages': '定价配套',
        'Pricing Items': '定价项目',
        'Quantity': '数量',
        'Queues': '队列',
        'Quotations': '报价单',
        'Recall': '撤销',
        'Recalled': '已撤销',
        'Receiving': '收货',
        'Recipient': '收件者',
        'Recipients': '收件者',
        'Ref.': '参考号',
        'Refresh List': '刷新列表',
        'Refresh?': '刷新？',
        'Refunds': '退款',
        'Registration': '注册',
        'Registrations': '注册',
        'Reject': '拒绝',
        'Rejected': '已拒绝',
        'Remarks': '备注',
        'Remarks (for internal use)': '备注 (供内部使用)',
        'Repeat header info in every row.': '在每一行中重复标题信息。',
        'Report': '报告',
        'Reports': '报告',
        'Request': '要求',
        'Requests': '要求',
        'Resend': '重发',
        'Resend (Alert)': '重发 (警报)',
        'Resend (No Alert)': '重发 (没有警报)',
        'Resent': '再发送',
        'Reset details?': '重设项目？',
        'Response': '回应',
        'Responses': '回应',
        'Revise': '修改',
        'Revised': '已修改',
        'Reviewed': '已审核',
        'Re-Open': '重开',
        'Roles': '角色',
        'Sales': '营业',
        'Save All': '保存所有',
        'Save Selected': '保存所选',
        'Saved': '已保存',
        'Scheduled': '定于',
        'Schedule': '日程',
        'Schedules': '日程',
        'Sections': '部分',
        'Select': '选择',
        'Select Items': '选择项目',
        'Send': '发送',
        'Send (Alert)': '发送 (警报)',
        'Send (No Alert)': '发送 (没有警报)',
        'Send All': '发送所有',
        'Send Selected': '发送所选',
        'Send Later': '稍后发送',
        'Send Now': '现在发送',
        'Send Revised': '发送修订',
        'Send Revised (Alert)': '发送修订 (警报)',
        'Send Revised (No Alert)': '发送修订 (没有警报)',
        'Send To': '发给',
        'Sent': '已发送',
        'Shares': '分享',
        'Shorteners': '缩短',
        'Selected': '已选',
        'Sort': '升序',
        'Staff': '员工',
        'Start': '开始',
        'Statements': '对账单',
        'Stocks': '库存',
        'Student': '学生',
        'Students': '学生',
        'Subject': '科目',
        'Subjects': '科目',
        'Submission': '作业提交',
        'Submissions': '作业提交',
        'Submit': '提交',
        'Submitted': '已提交',
        'Subscribers': '订户',
        'Sub-Total': '小计',
        'Summary': '总结',
        'Supervisor': '主管',
        'Tags': '标签',
        'Task': '任务',
        'Tasks': '任务',
        'Tax': '税',
        'Tax (%)': '税 （％）',
        'Temperature': '温度',
        'The data has been updated by other user. Please refresh the page and try again.': '数据已被其他用户更新。 请刷新页面，然后重试',
        'Thermometers': '体温计',
        'Time': '时间',
        'Title': '标题',
        'To': '到',
        'Total': '总计',
        'Tracking': '追踪',
        'Type': '类型',
        'Unknown': '未知',
        'Unlock': '开锁',
        'Unpublish': '取消',
        'Unpublished': '已取消',
        'Updated By': '更改者',
        'Upload Error!': '上传错误！',
        'Upload File': '上传文件',
        'Unselected': '没选',
        'User': '用户',
        'Users': '用户',
        'Validation Error!': '验证错误！',
        'Visitor': '访客',
        'Visitors': '访客',
        'Work Hour': '工作时间',
        'Work Hours': '工作时间',
        'You have not save changes! Continue?': '您尚未保存更改！ 继续？',
    },
    ms: {
        ' ': ' ',
        '%': '%',
        'Accept': 'Terima',
        'Access Denied!': 'Akses dinafikan!',
        'Account': 'Akaun',
        'Account Group': 'Kumpulan Akaun',
        'Account Collections': 'Pengumpulan Akaun',
        'Account Payments': 'Pembayaran Akaun',
        'Accounting Code': 'Kod Perakaunan',
        'Accounting Integration': 'Integrasi Perakaunan',
        'Accounts': 'Akaun',
        'Active': 'Aktif',
        'Address': 'Alamat',
        'Admin': 'Pentadbiran',
        'Administrator': 'Pentadbir',
        'Alerts': 'Isyarat',
        'Already exists!': 'Sudah ada!',
        'Appointment': 'Temujanji',
        'Appointments': 'Temujanji',
        'Approve': 'Lulus',
        'Approvers': 'Pelulus',
        'Approved': 'Diluluskan',
        'Are you sure?': 'Adakah anda pasti?',
        'Are you sure to delete this?': 'Adakah anda pasti memadamkannya?',
        'Asset': 'Aset',
        'Assets': 'Aset',
        'Assignment': 'Tugasan',
        'Assignments': 'Tugasan',
        'Attendance': 'Kehadiran',
        'Attendances': 'Kehadiran',
        'Balance': 'Baki',
        'Bank Transfer': 'Pemindahan bank',
        'Batch': 'Batch',
        'Beacons': 'Beacon',
        'Bills': 'Bil',
        'Book': 'Buku',
        'Books': 'Buku',
        'Broadcasts': 'Siaran',
        'Calendar': 'Kalendar',
        'Cancelled': 'Dibatalkan',
        'Cannot exceed': 'Tidak boleh melebihi',
        'Cash': 'Tunai',
        'Change All': 'Tukar Semua',
        'Change Selected': 'Tukar Terpilih',
        'Cheque': 'Cek',
        'Claim': 'Tuntutan',
        'Claims': 'Tuntutan',
        'Class': 'Kelas',
        'Classes': 'Kelas',
        'Clear': 'Padam',
        'Closed': 'Tertutup',
        'Collections': 'Pungutan',
        'Commissions': 'Komisen',
        'Companies': 'Syarikat',
        'Company': 'Syarikat',
        'Confidentials': 'Sulit',
        'Contacts': 'Kenalan',
        'Continue': 'Teruskan',
        'Continue to force delete?': 'Terus paksa memadam?',
        'Copied! You can paste this link in the content of other modules or social media.': 'Sudah Disalin! Anda boleh menampal pautan ini dalam kandungan modul lain atau media sosial.',
        'Copy': 'Salin',
        'Copy All': 'Salin Semua',
        'Copy Selected': 'Salin Terpilih',
        'Copy to other Account': 'Salin ke Akaun lain',
        'Copy to other Accounts': 'Salin ke Akaun lain',
        'Country': 'Negara',
        'Countries': 'Negara',
        'Credit Card': 'Kad Kredit',
        'Credit Notes': 'Nota Kredit',
        'Daily Log': 'Log Harian',
        'Daily Logs': 'Log Harian',
        'Dashboard': 'Papan Pemuka',
        'Date/Details': 'Tarikh/Butiran',
        'Day': 'Hari',
        'Debit Card': 'Kad Debit',
        'Decline': 'Enggan menerima',
        'Declined': 'Enggan menerima',
        'Description': 'Penerangan',
        'Details': 'Butiran',
        'Deliveries': 'Penghantaran',
        'Delivery Remarks': 'Keterangan Penghantaran',
        'Division': 'Pembahagian',
        'Divisions': 'Pembahagian',
        'Document': 'Dokumen',
        'Documents': 'Dokumen',
        'Donations': 'Derma',
        'Donation Drives': 'Kempen Derma',
        'Done': 'Selesai',
        'Due Date': 'Tarikh Akhir',
        'Duties': 'Kewajiban',
        'Duty': 'Kewajiban',
        'Draft': 'Draf',
        'E-Wallet': 'E-Dompet',
        'End': 'Tamat',
        'Engagement': 'Penglibatan',
        'Enquiries': 'Pertanyaan',
        'Enquiry': 'Pertanyaan',
        'Enrollment': 'Enrolment',
        'Enrollments': 'Pendaftaran',
        'Enter date.': 'Masukkan tarikh.',
        'Enter description.': 'Masukkan keterangan.',
        'Enter time.': 'Masukkan masa.',
        'Enter date and time.': 'Masukkan tarikh dan masa.',
        'Enter remarks.': 'Masukkan kenyataan.',
        'Enter same password as your login.': 'Masukkan kata laluan yang sama dengan log masuk anda.',
        'Expense': 'Perbelanjaan',
        'Expenses': 'Perbelanjaan',
        'Event': 'Acara',
        'Events': 'Acara',
        'Expired': 'Tamat',
        'Failed': 'Gagal',
        'Failed to delete as data is required in other modules!': 'Gagal memadam kerana data diperlukan dalam modul lain!',
        'Failure': 'Kegagalan',
        'Files': 'Fail',
        'For internal use only.': 'Untuk kegunaan dalaman sahaja.',
        'From': 'Dari',
        'Group': 'Kumpulan',
        'Groups': 'Kumpulan',
        'Holiday': 'Percutian',
        'Holidays': 'Percutian',
        'Homework': 'Kerja Rumah',
        'Hub': 'Hub',
        'ID': 'ID',
        'Import': 'Import',
        'Important': 'Penting',
        'Inactive': 'Tidak aktif',
        'Incomes': 'Pendapatan',
        'Individuals': 'Individu',
        'Integration': 'Integrasi',
        'Invalid email address.': 'Alamat emel salah.',
        'Invalid Password!': 'Salah kata laluan!',
        'Inventory': 'Inventori',
        'Inventories': 'Inventori',
        'Leave': 'Cuti',
        'Leaves': 'Cuti',
        'Ledgers': 'Lejar',
        'Lesson': 'Pelajaran',
        'Lessons': 'Pengajaran',
        'Logs': 'Log',
        'Lookups': 'Jadual Pencarian',
        'Main': 'Utama',
        'Media': 'Media',
        'Module': 'Modul',
        'Modules': 'Modul',
        'Member': 'Ahli',
        'Members': 'Ahli',
        'Month': 'Bulan',
        'More': 'Lebih',
        'Message Queues': 'Antrian Mesej',
        'Message': 'Mesej',
        'Messages': 'Mesej',
        'Monitors': 'Monitor',
        'Mosques': 'Masjid',
        'Must select other account!': 'Mesti pilih akaun lain!',
        'Mobile': 'Bimbit',
        'Mobiles': 'Bimbit',
        'No change!': 'Tiada perubahan!',
        'No class!': 'Tiada kelas!',
        'No group!': 'Tiada kumpulan!',
        'No Data!': 'Tiada data!',
        'Notification': 'Pemberitahuan',
        'Notifications': 'Pemberitahuan',
        'Newsletters': 'Surat Berita',
        'Online': 'Dalam Talian',
        'Operation': 'Operasi',
        'Optional': 'Pilihan',
        'Order': 'Pesanan',
        'Orders': 'Pesanan',
        'Others': 'Yang lain',
        'Overdue': 'Terlambat',
        'Package': 'Pakej',
        'Parent': 'Ibu bapa',
        'Parents': 'Ibu bapa',
        'Partners': 'Rakan kongsi',
        'Payment': 'Pembayaran',
        'Payments': 'Pembayaran',
        'Payslip': 'Slip gaji',
        'Payslips': 'Slip gaji',
        'PDF': 'PDF',
        'Pending': 'Tertunda',
        'Pending Payment': 'Pembayaran Tertunda',
        'Pending Review': 'Semakan Tertunda',
        'Period': 'Tempoh',
        'Petty Cash': 'Wang Runcit',
        'Phones': 'Telefon',
        'Portfolio': 'Portofolio',
        'Portfolios': 'Portofolio',
        'Publish': 'Menerbitkan',
        'Published': 'Pembelian',
        'Purchase': 'Pembelian',
        'Purchases': 'Pembelian',
        'Payment Vouchers': 'Baucar Bayaran',
        'Please wait...': 'Sila tunggu...',
        'Point': 'Mata',
        'Points': 'Mata',
        'Product': 'Produk',
        'Products': 'Produk',
        'Program': 'Program',
        'Programs': 'Program',
        'Progress': 'Kemajuan',
        'Pricings': 'Harga',
        'Pricing Code': 'Kod Harga',
        'Pricing Packages': 'Pakej Harga',
        'Pricing Items': 'Item Harga',
        'Quantity': 'Kuantiti',
        'Queues': 'Antrian',
        'Quotations': 'Sebutharga',
        'Recall': 'Tarik Balik',
        'Recalled': 'Ditarik',
        'Receiving': 'Penerimaan',
        'Recipient': 'Penerima',
        'Recipients': 'Penerima',
        'Ref.': 'Ruj.',
        'Refresh List': 'Muat semula Senarai',
        'Refresh?': 'Muat semula?',
        'Refunds': 'Bayaran Balik',
        'Registration': 'Pendaftaran',
        'Registrations': 'Pendaftaran',
        'Reject': 'Tolak',
        'Rejected': 'Ditolak',
        'Remarks': 'Kenyataan',
        'Remarks (for internal use)': 'Kenyataan (untuk kegunaan dalaman)',
        'Repeat header info in every row.': 'Ulang maklumat pengepala dalam setiap baris.',
        'Report': 'Laporan',
        'Reports': 'Laporan',
        'Request': 'Permintaan',
        'Requests': 'Permintaan',
        'Resend': 'Hantar Semula',
        'Resend (Alert)': 'Hantar Semula (Isyarat)',
        'Resend (No Alert)': 'Hantar Semula (Tanpa Isyarat)',
        'Resent': 'Hantar semula',
        'Reset details?': 'Tetapkan semula butiran?',
        'Response': 'Jawapan',
        'Responses': 'Jawapan',
        'Revise': 'Semak',
        'Revised': 'Semakan',
        'Reviewed': 'Disemak',
        'Re-Open': 'Buka Semula',
        'Roles': 'Peranan',
        'Sales': 'Jualan',
        'Save All': 'Simpan Semua',
        'Save Selected': 'Simpan Terpilih',
        'Saved': 'Disimpan',
        'Scheduled': 'Dijadualkan',
        'Schedule': 'Jadual',
        'Schedules': 'Jadual',
        'Sections': 'Bahagian',
        'Select': 'Pilih',
        'Select Items': 'Pilih Item',
        'Send': 'Hantar',
        'Send (Alert)': 'Hantar (Isyarat)',
        'Send (No Alert)': 'Hantar (Tanpa Isyarat)',
        'Send All': 'Hantar Semua',
        'Send Selected': 'Hantar Terpilih',
        'Send Later': 'Hantar Kemudian',
        'Send Now': 'Hantar Sekarang',
        'Send Revised': 'Hantar Disemak',
        'Send Revised (Alert)': 'Hantar Disemak (Isyarat)',
        'Send Revised (No Alert)': 'Hantar Disemak (Tanpa Isyarat)',
        'Send To': 'Hantar kepada',
        'Sent': 'Dihantar',
        'Shares': 'Berkongsi',
        'Shorteners': 'Pemendek',
        'Selected': 'Terpilih',
        'Sort': 'Susun',
        'Staff': 'Kakitangan',
        'Start': 'Mula',
        'Statements': 'Penyata',
        'Stocks': 'Inventori',
        'Student': 'Pelajar',
        'Students': 'Pelajar',
        'Subject': 'Subjek',
        'Subjects': 'Subjek',
        'Submission': 'Penyerahan',
        'Submissions': 'Penyerahan',
        'Submit': 'Serah',
        'Submitted': 'Diserahkan',
        'Subscribers': 'Subscribers',
        'Sub-Total': 'Jumlah kecil',
        'Summary': 'Ringkasan',
        'Supervisor': 'Penyelia',
        'Tags': 'Teg',
        'Task': 'Tugas',
        'Tasks': 'Tugas',
        'Tax': 'Cukai',
        'Tax (%)': 'Cukai (%)',
        'Temperature': 'Suhu',
        'The data has been updated by other user. Please refresh the page and try again.': 'Data telah dikemas kini oleh pengguna lain. Muat semula halaman dan cuba lagi',
        'Thermometers': 'Termometer',
        'Time': 'Masa',
        'Title': 'Tajuk',
        'To': 'Ke',
        'Total': 'Jumlah',
        'Tracking': 'Penjejakan',
        'Type': 'Jenis',
        'Unknown': 'Tidak Diketahui',
        'Unlock': 'Buka kunci',
        'Unpublish': 'Batalkan',
        'Unpublished': 'Dibatalkan',
        'Updated By': 'Dikemaskini Oleh',
        'Upload Error!': 'Ralat Muat Naik!',
        'Upload File': 'Muat Naik Fail',
        'Unselected': 'Tidak dipilih',
        'User': 'Pengguna',
        'Users': 'Pengguna',
        'Validation Error!': 'Ralat Pengesahan!',
        'Visitor': 'Pelawat',
        'Visitors': 'Pelawat',
        'Work Hour': 'Waktu Kerja',
        'Work Hours': 'Waktu Kerja',
        'You have not save changes! Continue?': 'Anda tidak menyimpan perubahan ! Teruskan?',
    },
    id: {
        ' ': ' ',
        '%': '%',
        'Accept': 'Terima',
        'Access Denied!': 'Akses ditolak!',
        'Account': 'Akun',
        'Account Group': 'Grup Akun',
        'Account Collections': 'Koleksi Akun',
        'Account Payments': 'Pembayaran Akun',
        'Accounting Code': 'Kode Akuntansi',
        'Accounting Integration': 'Integrasi Akuntansi',
        'Accounts': 'Akun',
        'Active': 'Aktif',
        'Address': 'Alamat',
        'Admin': 'Administrasi',
        'Administrator': 'Administrator',
        'Alerts': 'Lansiran',
        'Already exists!': 'Sudah ada!',
        'Appointment': 'Janji Bertemu',
        'Appointments': 'Janji temu',
        'Approve': 'Lulus',
        'Approvers': 'Pelulus',
        'Approved': 'Diluluskan',
        'Are you sure?': 'Anda yakin?',
        'Are you sure to delete this?': 'Anda yakin ingin menghapus ini?',
        'Asset': 'Aset',
        'Assets': 'Aset',
        'Assignment': 'Pekerjaan',
        'Assignments': 'Pekerjaan',
        'Attendance': 'Kehadiran',
        'Attendances': 'Kehadiran',
        'Balance': 'Baki',
        'Bank Transfer': 'Transfer Bank',
        'Batch': 'Batch',
        'Beacons': 'Beacon',
        'Bills': 'Tagihan',
        'Book': 'Buku',
        'Books': 'Buku',
        'Broadcasts': 'Siaran',
        'Calendar': 'Kalendar',
        'Cancelled': 'Dibatalkan',
        'Cannot exceed': 'Tidak boleh lebih dari',
        'Cash': 'Tunai',
        'Change All': 'Ubah Semua',
        'Change Selected': 'Ubah Yang Dipilih',
        'Cheque': 'Cek',
        'Claim': 'Klaim',
        'Claims': 'Klaim',
        'Class': 'Kelas',
        'Classes': 'Kelas',
        'Clear': 'Menghapus',
        'Closed': 'Tutup',
        'Collections': 'Koleksi',
        'Commissions': 'Komisi',
        'Companies': 'Perusahaan',
        'Company': 'Perusahaan',
        'Confidentials': 'Rahasia',
        'Contacts': 'Kontak',
        'Continue': 'Lanjutkan',
        'Continue to force delete?': 'Lanjutkan untuk menghapus paksa?',
        'Copied! You can paste this link in the content of other modules or social media.': 'Sudah Disalin! Anda dapat menempelkan tautan ini di konten modul atau media sosial lain.',
        'Copy': 'Salin',
        'Copy All': 'Salin Semua',
        'Copy Selected': 'Salin Yang Dipilih',
        'Copy to other Account': 'Salin ke Akun lain',
        'Copy to other Accounts': 'Salin ke Akun lain',
        'Country': 'Negara',
        'Countries': 'Negara',
        'Credit Card': 'Kartu Kredit',
        'Credit Notes': 'Nota Kredit',
        'Daily Log': 'Log Harian',
        'Daily Logs': 'Log Harian',
        'Dashboard': 'Dasbor',
        'Date/Details': 'Tanggal/Detail',
        'Day': 'Hari',
        'Debit Card': 'Kartu Debit',
        'Decline': 'Menolak untuk menerima',
        'Declined': 'Menolak untuk menerima',
        'Description': 'Deskripsi',
        'Details': 'Detail',
        'Deliveries': 'Pengiriman',
        'Delivery Remarks': 'Keterangan Pengiriman',
        'Division': 'Divisi',
        'Divisions': 'Divisi',
        'Document': 'Dokumen',
        'Documents': 'Dokumen',
        'Donations': 'Donasi',
        'Donation Drives': 'Kampanye Donasi',
        'Done': 'Selesai',
        'Due Date': 'Tanggal Jatuh Tempo',
        'Duties': 'Kewajiban',
        'Duty': 'Kewajiban',
        'Draft': 'Draf',
        'E-Wallet': 'E-Dompet',
        'End': 'Akhir',
        'Engagement': 'Keterlibatan',
        'Enquiries': 'Pertanyaan',
        'Enquiry': 'Pertanyaan',
        'Enrollment': 'Enrolment',
        'Enrollments': 'Pendaftaran',
        'Enter date.': 'Masukkan tanggal.',
        'Enter description.': 'Masukkan deskripsi.',
        'Enter time.': 'Masukkan waktu.',
        'Enter date and time.': 'Masukkan tanggal dan waktu.',
        'Enter remarks.': 'Masukkan catatan.',
        'Enter same password as your login.': 'Masukkan kata sandi yang sama dengan login Anda.',
        'Expense': 'Perbelanjaan',
        'Expenses': 'Perbelanjaan',
        'Events': 'Acara',
        'Expired': 'Kedaluwarsa',
        'Failed': 'Gagal',
        'Failed to delete as data is required in other modules!': 'Gagal menghapus karena data diperlukan di modul lain!',
        'Failure': 'Kegagalan',
        'For internal use only.': 'Hanya untuk penggunaan internal.',
        'Files': 'File',
        'From': 'Dari',
        'Group': 'Kelompok',
        'Groups': 'Kelompok',
        'Holiday': 'Liburan',
        'Holidays': 'Liburan',
        'Homework': 'Pekerjaan Rumah',
        'Hub': 'Pusat',
        'ID': 'ID',
        'Import': 'Impor',
        'Important': 'Penting',
        'Inactive': 'Non-aktif',
        'Incomes': 'Pendapatan',
        'Individuals': 'Individu',
        'Integration': 'Integrasi',
        'Invalid email address.': 'Alamat email salah.',
        'Invalid Password!': 'Kata sandi salah!',
        'Inventory': 'Inventaris',
        'Inventories': 'Inventories',
        'Leave': 'Cuti',
        'Leaves': 'Cuti',
        'Ledgers': 'Lejar',
        'Lesson': 'Pelajaran',
        'Lessons': 'Pelajaran',
        'Logs': 'Log',
        'Lookups': 'Tabel Pencarian',
        'Main': 'Utama',
        'Media': 'Media',
        'Module': 'Modul',
        'Modules': 'Modul',
        'Member': 'Anggota',
        'Members': 'Anggota',
        'Month': 'Bulan',
        'More': 'Lebih',
        'Message Queues': 'Antrian Pesan',
        'Message': 'Pesan',
        'Messages': 'Pesan',
        'Monitors': 'Monitor',
        'Mosques': 'Masjid',
        'Must select other account!': 'Harus memilih akun lain!',
        'Mobile': 'Ponsel',
        'Mobiles': 'Ponsel',
        'No change!': 'Tidak ada perubahan!',
        'No class!': 'Tidak ada kelas!',
        'No group!': 'Tidak ada kumpulan!',
        'No Data!': 'Tidak ada data!',
        'Notification': 'Notifikasi',
        'Notifications': 'Notifikasi',
        'Newsletters': 'Buletin',
        'Online': 'On line',
        'Operation': 'Operasi',
        'Optional': 'Pilihan',
        'Order': 'Pemesanan',
        'Orders': 'Pesanan',
        'Others': 'Lainnya',
        'Overdue': 'Terlambat',
        'Package': 'Paket',
        'Parent': 'Induk',
        'Parents': 'Induk',
        'Partners': 'Mitra',
        'Payment': 'Pembayaran',
        'Payments': 'Pembayaran',
        'Payslip': 'Slip gaji',
        'Payslips': 'Slip gaji',
        'PDF': 'PDF',
        'Pending': 'Tertunda',
        'Pending Payment': 'Pembayaran Tertunda',
        'Pending Review': 'Tinjauan Tertunda',
        'Period': 'Titik',
        'Petty Cash': 'Kas Kecil',
        'Phones': 'Telepon',
        'Portfolio': 'Portofolio',
        'Portfolios': 'Portofolio',
        'Publish': 'Menerbitkan',
        'Published': 'Diterbitkan',
        'Purchase': 'Pembelian',
        'Purchases': 'Pembelian',
        'Payment Vouchers': 'Voucher Pembayaran',
        'Please wait...': 'Mohon tunggu...',
        'Point': 'Poin',
        'Points': 'Poin',
        'Product': 'Produk',
        'Products': 'Produk',
        'Program': 'Program',
        'Programs': 'Program',
        'Progress': 'Kemajuan',
        'Pricings': 'Harga',
        'Pricing Code': 'Kode Harga',
        'Pricing Packages': 'Paket Harga',
        'Pricing Items': 'Item Harga',
        'Quantity': 'Kuantitas',
        'Queues': 'Antrian',
        'Quotations': 'Kutipan',
        'Recall': 'Tarik Balik',
        'Recalled': 'Ditarik',
        'Receiving': 'Penerimaan',
        'Recipient': 'Penerima',
        'Recipients': 'Penerima',
        'Ref.': 'Ref.',
        'Refresh List': 'Segarkan Senarai',
        'Refresh?': 'Muat semula?',
        'Refunds': 'Pengembalian Uang',
        'Registration': 'Pendaftaran',
        'Registrations': 'Pendaftaran',
        'Reject': 'Tolak',
        'Rejected': 'Ditolak',
        'Remarks': 'Catatan',
        'Remarks (for internal use)': 'Catatan (untuk penggunaan internal)',
        'Repeat header info in every row.': 'Ulangi info header di setiap baris.',
        'Report': 'Laporan',
        'Reports': 'Laporan',
        'Request': 'Permintaan',
        'Requests': 'Permintaan',
        'Resend': 'Kirim Ulang',
        'Resend (Alert)': 'Kirim Ulang (Lansiran)',
        'Resend (No Alert)': 'Kirim Ulang (Tidak ada Lansiran)',
        'Resent': 'Dikirim Ulang',
        'Reset details?': 'Setel ulang detail?',
        'Response': 'Respons',
        'Responses': 'Respons',
        'Revise': 'Perbaiki',
        'Revised': 'Diperbaiki',
        'Reviewed': 'Ditinjau',
        'Re-Open': 'Buka Kembali',
        'Roles': 'Peranan',
        'Sales': 'Penjualan',
        'Save All': 'Simpan Semua',
        'Save Selected': 'Simpan Yang Dipilih',
        'Saved': 'Disimpan',
        'Scheduled': 'Dijadwalkan',
        'Schedule': 'Jadwal',
        'Schedules': 'Jadwal',
        'Sections': 'Bagian',
        'Select': 'Pilih',
        'Select Items': 'Pilih Item',
        'Send': 'Kirim',
        'Send (Alert)': 'Kirim (Lansiran)',
        'Send (No Alert)': 'Kirim (Tidak ada Lansiran)',
        'Send All': 'Kirim Semua',
        'Send Selected': 'Kirim Yang Dipilih',
        'Send Later': 'Kirim Nanti',
        'Send Now': 'Kirim Sekarang',
        'Send Revised': 'Kirim Revisi',
        'Send Revised (Alert)': 'Kirim Revisi (Lansiran)',
        'Send Revised (No Alert)': 'Kirim Revisi (Tidak ada Lansiran)',
        'Send To': 'Kirim ke',
        'Sent': 'Terkirim',
        'Shares': 'Bagikan',
        'Shorteners': 'Penyingkat',
        'Selected': 'Terpilih',
        'Sort': 'Sortir',
        'Staff': 'Staf',
        'Start': 'Mula',
        'Statements': 'Pernyataan',
        'Stocks': 'Inventaris',
        'Student': 'Siswa',
        'Students': 'Siswa',
        'Subject': 'Subyek',
        'Subjects': 'Subyek',
        'Submission': 'Penyerahan',
        'Submissions': 'Penyerahan',
        'Submit': 'Serahkan',
        'Submitted': 'Diserahkan',
        'Subscribers': 'Pelanggan',
        'Sub-Total': 'Sub-Total',
        'Summary': 'Ringkasan',
        'Supervisor': 'Pengawas',
        'Tags': 'Tag',
        'Task': 'Tugas',
        'Tasks': 'Tugas',
        'Tax': 'Pajak',
        'Tax (%)': 'Pajak (%)',
        'Temperature': 'Suhu',
        'The data has been updated by other user. Please refresh the page and try again.': 'Data telah diperbarui oleh pengguna lain. Harap segarkan halaman dan coba lagi',
        'Thermometers': 'Termometer',
        'Time': 'Waktu',
        'Title': 'Judul',
        'To': 'Ke',
        'Total': 'Total',
        'Tracking': 'Pelacakan',
        'Type': 'Jenis',
        'Unknown': 'Tidak Diketahui',
        'Unlock': 'Membuka kunci',
        'Unpublish': 'Batalkan',
        'Unpublished': 'Dibatalkan',
        'Updated By': 'Diperbaharui oleh',
        'Upload Error!': 'Kesalahan Unggahan!',
        'Upload File': 'Unggah Data',
        'Unselected': 'Tidak dipilih',
        'User': 'Pengguna',
        'Users': 'Pengguna',
        'Validation Error!': 'Kesalahan Validasi!',
        'Visitor': 'Pelawat',
        'Visitors': 'Pelawat',
        'Work Hour': 'Jam kerja',
        'Work Hours': 'Jam kerja',
        'You have not save changes! Continue?': 'Anda belum menyimpan perubahan !. Terus?',
    },
};
