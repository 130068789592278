import set from 'lodash/set';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import times from 'lodash/times';
import replace from 'lodash/replace';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import merge from 'lodash/merge';
import concat from 'lodash/concat';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';
import escape from 'lodash/escape';
import extend from 'lodash/extend';
import camelCase from 'lodash/camelCase';

class LodashService {

    static set = set;
    static get = get;
    static debounce = debounce;
    static throttle = throttle;
    static map = map;
    static pickBy = pickBy;
    static times = times;
    static replace = replace;
    static reduce = reduce;
    static filter = filter;
    static find = find;
    static findLastIndex = findLastIndex;
    static findIndex = findIndex;
    static flatten = flatten;
    static sortBy = sortBy;
    static merge = merge;
    static concat = concat;
    static isString = isString;
    static isNumber = isNumber;
    static isBoolean = isBoolean;
    static isDate = isDate;
    static isArray = isArray;
    static isObject = isObject;
    static capitalize = capitalize;
    static uniq = uniq;
    static escape = escape;
    static extend = extend;
    static camelCase = camelCase;

    static flattenMap = (list: any, field: string) => {
        const m: any = LodashService.map(LodashService.filter(list, (f) => {
            return f && !LodashService.isEmpty(f[field]);
        }), field);
        if (m && m.length > 0) {
            return LodashService.flatten(m);
        }
        return [];
    }

    static getArray(val: any) {
        if (LodashService.isArray(val)) {
            return val;
        } else {
            return LodashService.isEmpty(val) ? [] : [val];
        }
    }

    static isEmpty(item: any) {
        // tslint:disable-next-line: use-isnan
        return (item === null || item === undefined || Number.isNaN(item) || (typeof item === 'object' && (Object.keys(item).length === 0 && (!item.getTime || !item.getTime()))) || (typeof item === 'string' && String(item).trim().length === 0));
    }
}
export default LodashService;
