import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getSidebarStatus, getSize, setSidebarStatus, setSize } from '@/services/common/cookies.service';
import store from '@/store/index';

export enum DeviceType {
    Mobile,
    Desktop,
}

export interface IAppState {
    device: DeviceType;
    sidebar: {
        opened: boolean
        withoutAnimation: boolean;
    };
    size: string;
    dirty: boolean;
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
    sidebar = {
        opened: getSidebarStatus() !== 'closed',
        withoutAnimation: false,
    };
    device = DeviceType.Desktop;
    size = getSize() || 'medium';
    dirty = false;

    @Mutation
    private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
        this.sidebar.opened = !this.sidebar.opened;
        this.sidebar.withoutAnimation = withoutAnimation;
        if (this.sidebar.opened) {
            setSidebarStatus('opened');
        } else {
            setSidebarStatus('closed');
        }
    }

    @Mutation
    private CLOSE_SIDEBAR(withoutAnimation: boolean) {
        this.sidebar.opened = false;
        this.sidebar.withoutAnimation = withoutAnimation;
        setSidebarStatus('closed');
    }

    @Mutation
    private TOGGLE_DEVICE(device: DeviceType) {
        this.device = device;
    }

    @Mutation
    private SET_SIZE(size: string) {
        this.size = size;
        setSize(this.size);
    }

    @Mutation
    private SET_DIRTY(d: boolean) {
        this.dirty = d;
    }

    @Action
    ToggleSideBar(withoutAnimation: boolean) {
        this.TOGGLE_SIDEBAR(withoutAnimation);
    }

    @Action
    CloseSideBar(withoutAnimation: boolean) {
        this.CLOSE_SIDEBAR(withoutAnimation);
    }

    @Action
    ToggleDevice(device: DeviceType) {
        this.TOGGLE_DEVICE(device);
    }

    @Action
    SetSize(size: string) {
        this.SET_SIZE(size);
    }

    @Action
    SetDirty(d: boolean) {
        this.SET_DIRTY(d);
    }

}

export const AppStore = getModule(App);
