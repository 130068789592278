<template slot-scope="{}">
	<page-single-view
		:loading="loading"
		:error="error"
		:data="data"
		:size="size"
		:form.sync="form"
		:functions="functions">
	</page-single-view>
</template>

<script lang="ts">
	import { Vue, Component } from 'vue-property-decorator';
	import { Form } from 'element-ui';
	import request from '@/services/common/request.service';
	import PageSingleView	from '@/views/component/page-single-view.vue';
	import ErrorService from '@/services/common/error.service';
	import i18n from '@/lang';

	@Component({
		components: {
			PageSingleView,
		},
	})
	export default class SystemStatic extends Vue {
		private form: Form | null = null;
		private loading = {page: false};
		private error = {all: '', page: '', fields: {}};
		private data: any = null;
		private size: string = 'medium';
		private functions: any = {
		};

		async mounted() {
			try {
				this.form = null; // Need this otherwise not reactive
				this.data = {page: null};
				let url;
				let params;
				if (this.$route && this.$route.meta && this.$route.meta.apiPath) {
					url = this.$route.meta.apiPath;
					params = {...this.$route.meta.args, ...this.$route.params};
				} else if (this.$router && this.$router.currentRoute && this.$router.currentRoute.meta) {
					url = this.$router.currentRoute.meta.apiPath;
					params = {...this.$router.currentRoute.meta.args, ...this.$route.params};
				}
				if (url) {
					delete params.apiPath;
					this.data.page  = await request({url, method: 'get', params});
				} else {
					this.error.all = i18n.t('Invalid Setup!') as string;
				}
			} catch (err) {
				this.error.all = ErrorService.getMessage(err);
			}
		}

	}
</script>

