import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface ISettingsState {
    navPos: string;
    toolbar: string;
    boxed: boolean;
    toolbarPos: string;
    footer: string;
}

@Module({ dynamic: true, store, name: 'settings' })
class Settings extends VuexModule implements ISettingsState {
    navPos: string = '';
    toolbar: string = '';
    boxed: boolean = false;
    toolbarPos: string = '';
    footer: string = '';

    @Mutation
    private CHANGE_SETTING(payload: { key: string, value: any }) {
        const { key, value } = payload;
        if (Object.prototype.hasOwnProperty.call(this, key)) {
            (this as any)[key] = value;
        }
    }

    @Action
    ChangeSetting(payloads: ISettingsState) {
        for (const [key, value] of Object.entries(payloads)) {
            this.CHANGE_SETTING({key, value});
        }
    }
}

export const SettingsStore = getModule(Settings);
