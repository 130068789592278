
import mime from 'mime-types';
import axios from 'axios';
import Qs from 'qs';
import printJS from 'print-js';
import request from '@/services/common/request.service';
import LodashService from '../common/lodash.service';
import { Vue } from 'vue-property-decorator';
import UtilService from '../common/util.service';

export default class FilesService {

    static apiPath = '/api/files';
    static imageExtension = ['.jpg', '.jpe', '.jpeg', '.png', '.bmp', '.gif', '.webp'];
    static videoExtension = ['.mp4'];
    static audioExtension = ['.mp3'];
    static fileExtension = ['.pdf'];
    static dataExtension = ['.dat'];
    static txtExtension = ['.txt'];
    static excelExtension = ['.xls', '.xlsx'];
    static csvExtension = ['.csv'];

    private static getSize(ext: string) {
        switch (ext) {
            case '_tiny':
                return {w: 50, h: 50, m: 'lfit', limit: 0};
            case '_thumbnail':
                return {w: 150, h: 150, m: 'lfit', limit: 0};
            case '_small':
                return {w: 360, h: 360, m: 'lfit', limit: 1};
            case '_medium':
                return {w: 720, h: 720, m: 'lfit', limit: 1};
            case '_large':
                return {w: 1024, h: 1024, m: 'lfit', limit: 1};
            default:
                return {w: 2048, h: 2048, m: 'lfit', limit: 1};
        }
    }

    private static getImageSizeExt(store: string, ext: string) {
        if (store === 'oss') {
            const size = FilesService.getSize(ext);
            return {ext: '', arg: `?x-oss-process=image/resize,w_${size.w},h_${size.h},m_${size.m},limit_${size.limit}`};
        } else {
            return {ext, arg: ''};
        }

    }

    static isPdf(v: any) {
        let ext = v && v.ext ? v.ext : v;
        if (ext) { ext = String(ext).toLowerCase(); }
        return ext === '.pdf';
    }

    static isExtension(v: any, extensions: string[]) {
        let ext = v && v.ext ? v.ext : v;
        if (ext) { ext = String(ext).toLowerCase(); }
        return !!LodashService.find(extensions, (e) => e === ext);
    }

    static isImage(v: any) {
        return FilesService.isExtension(v, FilesService.imageExtension);
    }

    static isVideo(v: any) {
        return FilesService.isExtension(v, FilesService.videoExtension);
    }

    static isAudio(v: any) {
        return FilesService.isExtension(v, FilesService.audioExtension);
    }

    static isData(v: any) {
        return FilesService.isExtension(v, FilesService.dataExtension);
    }

    static async getUploadUrl(query: any) {
        return request({url: `${FilesService.apiPath}/upload`, method: 'get', params: query});
    }

    static async getSignedUrl(account: any, item: any, imageSize: string, options: any, rotate?: number) {
        if (item) {
            const query: any = {
                account,
                filePath: {path: item.path, ext: item.ext},
                store: item.store,
                module: options.module,
            };
            if (item && item.ext && (FilesService.isImage(item) || (imageSize && (FilesService.isPdf(item) || FilesService.isVideo(item) || FilesService.isAudio(item))))) {
                const store = item.store || 's3';
                const size = FilesService.getImageSizeExt(store, imageSize);
                if (rotate && rotate !== 360) {
                    query.fileArg += '/rotate,' + rotate;
                }
                if (FilesService.isImage(item)) {
                    query.filePath = {path: item.path + size.ext, ext: item.ext};
                } else {
                    query.filePath = {path: item.path + size.ext, ext: item.ext + '.png'};
                }
            }
            const url = await request({url: `${FilesService.apiPath}/signed-url`, method: 'get', params: query});
            return url + '';
        } else {
            return '';
        }
    }

    static async createThumbnail(query: any) {
        try {
            const ret = await request({url: `${FilesService.apiPath}/thumbnail`, method: 'get', params: query});
            return ret;
        } catch (err) {
            // Ignore - no thumbnail is not a serious bug
            // console.log(err);
            return Promise.resolve(null);
        }
    }

    static getUrl(v: any, imageSize: string, options: any, rotate?: number) {
        if (v && v.path) {
            const store = v.store || 's3';
            const cloudUrl = options && options.stores ? options.stores[store].cloudUrl : '';
            if (v && v.ext && (FilesService.isImage(v) || (imageSize && (FilesService.isPdf(v) || FilesService.isVideo(v) || FilesService.isAudio(v))))) {
                const size = FilesService.getImageSizeExt(store, imageSize);
                if (rotate && rotate !== 360) {
                    size.arg += '/rotate,' + rotate;
                }
                if (FilesService.isPdf(v)) {
                    // TODO remove this after s3 migratiom
                    return cloudUrl && v && v.path ? (cloudUrl + '/' + v.path + size.ext + v.ext + '.png' + size.arg) : '';
                } else if (FilesService.isImage(v)) {
                    return cloudUrl && v && v.path ? (cloudUrl + '/' + v.path + size.ext + v.ext + size.arg) : '';
                } else {
                    return cloudUrl && v && v.path ? (cloudUrl + '/' + v.path + v.ext + size.ext + '.png' + size.arg) : '';
                }
            } else {
                if (imageSize && FilesService.isData(v)) {
                    return '';
                } else {
                    return cloudUrl && v && v.path ? (cloudUrl + '/' + v.path + v.ext) : '';
                }
            }
        } else if (v && v.url) {
            return v.url;
        } else {
            return v && LodashService.isString(v) ? v : '';
        }
    }

    static getContentType(v: any) {
        return mime.lookup(v ? v.ext : v);
    }

    static downloadFile(f: any, options: any) {
        const url = f.url ? f.url : FilesService.getUrl(f, '', options);
        return axios({url, method: 'get', responseType: 'blob'});
    }

    static async uploadFile(file: any, params: any, options: any) {
        request.defaults.timeout = options.noTimeOut ? 0 : 60000;
        file.onUploadProgress = (ev: any) => {
            Vue.set(file, 'loaded', ev && ev.total ? UtilService.getNumber(((ev.loaded / ev.total) * 100), 2) : 0);
        };
        file.cancelRequest = axios.CancelToken.source();
        const url = options.url + '?' + Qs.stringify(params, {arrayFormat: 'indices'});
        const data = await request({url, method: 'PUT', data: file.raw, headers: {'Content-Type': 'application/octet-stream'}, cancelToken: file.cancelRequest.token, onUploadProgress: file.onUploadProgress});
        file.cancelRequest = null;
        return data;
    }

    static async printFile(f: any, options: any) {
        await printJS({printable: FilesService.getUrl(f, '', options), type: f.ext === '.pdf' ? 'pdf' : 'image', showModal: true});
    }

    static getIcon(file: any) {
        const kind = file && file.ext ? FilesService.getType(file) : file;
        switch (kind) {
            case 'Image':
                return 'fa-image';
            case 'Video':
                return 'fa-video';
            case 'Audio':
                return 'fa-headphones';
            case 'PDF':
                return 'fa-file-pdf';
            default:
                return 'fa-file';
        }
    }

    static getType(file: any) {
        if (FilesService.isImage(file)) {
            return 'Image';
        } else if (FilesService.isVideo(file)) {
            return 'Video';
        } else if (FilesService.isAudio(file)) {
            return 'Audio';
        } else if (FilesService.isPdf(file)) {
            return 'PDF';
        } else if (FilesService.isData(file)) {
            return 'File';
        } else {
            return '';
        }
    }

    static getFileTypeImage(file: any) {
        const kind = file && file.ext ? FilesService.getType(file) : file;
        switch (kind) {
            case 'Image':
                return '/static/images/image.png';
            case 'Video':
                return '/static/images/video.png';
            case 'Audio':
                return '/static/images/audio.png';
            case 'PDF':
                return '/static/images/pdf.png';
            default:
                return '/static/images/file.png';
        }
    }

    static getIconClass(ff: any) {
        const f = ff && LodashService.isArray(ff) && ff.length > 0 && ff[0] ? ff[0] : ff;
        return (!f || FilesService.isImage(f)) ? '' : ('fas elem-file-icon ' + FilesService.getIcon(f));
    }

    static getExtensions(types: string[]): string[] {
        let ret: string[] = [];
        for (const s of types) {
            switch ((s || '').toLowerCase()) {
                case 'image':
                    ret = ret.concat(FilesService.imageExtension);
                    break;
                case 'video':
                    ret = ret.concat(FilesService.videoExtension);
                    break;
                case 'audio':
                    ret = ret.concat(FilesService.audioExtension);
                    break;
                case 'pdf':
                    ret = ret.concat(['.pdf']);
                    break;
                case 'file':
                    ret = ret.concat(FilesService.fileExtension);
                    break;
                case 'text':
                    ret = ret.concat(FilesService.txtExtension);
                    break;
                case 'excel':
                    ret = ret.concat(FilesService.excelExtension);
                    break;
                case 'csv':
                    ret = ret.concat(FilesService.csvExtension);
                    break;
                default:
                    ret.push('.' + s);
            }
        }
        return ret;
    }

}
