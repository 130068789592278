import { Vue, Component, Prop } from 'vue-property-decorator';
import RouteService from '@/services/common/route.service';
import ViewService from '@/services/common/view.service';

// Render less component to load layout dynamically from props passed from parent
@Component
export default class Layout extends Vue {

    @Prop({required: true}) name!: string;

    created() {
        if (this.name) {
            const n = ViewService.loadLayout(this.name);
            RouteService.setLayout(this.$route);
            if (this.$parent) { this.$parent.$emit('update:layout', n); }
        }
    }

    render() {
        return (this.$slots.default && this.$slots.default.length > 0 ? this.$slots.default[0] : null) as any;
    }

}
