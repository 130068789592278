// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../flags/4x3/my.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../flags/1x1/my.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../flags/4x3/kh.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../flags/1x1/kh.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../flags/4x3/ph.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../flags/1x1/ph.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../flags/4x3/th.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../flags/1x1/th.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../flags/4x3/id.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../flags/1x1/id.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../flags/4x3/sg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("../flags/1x1/sg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flag-icon,.flag-icon-background{background-size:contain;background-position:50%;background-repeat:no-repeat}.flag-icon{position:relative;display:inline-block;width:1.33333333em;line-height:1em}.flag-icon:before{content:\"\\00a0\"}.flag-icon.flag-icon-squared{width:1em}.flag-icon-my{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.flag-icon-my.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.flag-icon-kh{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.flag-icon-kh.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.flag-icon-ph{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.flag-icon-ph.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.flag-icon-th{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.flag-icon-th.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}.flag-icon-id{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}.flag-icon-id.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ")}.flag-icon-sg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ")}.flag-icon-sg.flag-icon-squared{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
