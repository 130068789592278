
import { Vue } from 'vue-property-decorator';
import {UserStore} from '@/store/user.store';
import MomentService from '@/services/common/moment.service';
import i18n from '@/lang';
import LodashService from './lodash.service';
import { v4 as uuidv4 } from 'uuid';

export default class UtilService {

    static initObject(data: any, modelKey: string, isArray: boolean) {
        // Set up parent object with reactivity
        if (modelKey && modelKey.indexOf('.') >= 0) {
            const fields = modelKey.split('.');
            fields.splice(fields.length - 1, 1);
            if (fields.length > 0) {
                let p = data;
                // tslint:disable-next-line: prefer-for-of
                for (let f = 0; f < fields.length; f++) {
                    const dd = LodashService.get(p, fields[f]);
                    if (!dd) {
                        // Last one can be array or object
                        Vue.set(p, fields[f], (f === fields.length - 1) ? (isArray ? [] : {}) : {});
                        p = LodashService.get(p, fields[f]);
                    } else {
                        p = dd;
                    }
                }
            }
        }
    }

    static getModel(data: any, modelKey: string, options: any) {
        let v = modelKey ? LodashService.get(data, modelKey) : data;
        if (v === undefined && options && options.hasOwnProperty('default')) {
            v = options.default;
        }
        return v;
    }

    static initModel(obj: any, modelKey: string, options: any) {
        let v = modelKey && obj.data ? LodashService.get(obj.data, modelKey) : obj.data;
        if (v === undefined && options && options.hasOwnProperty('default')) {
            v = UtilService.cloneObject(options.default);
        }
        UtilService.setModel(obj, modelKey, v);
    }

    static initMultiModels(obj: any, modelKey: string, modelKeys: string, options: any) {
        if (LodashService.isArray(modelKeys)) {
            let array: any[] = [];
            for (const a of modelKeys) {
                const aa = UtilService.getModel(obj.data, a, options);
                if (aa) {
                    if (LodashService.isArray(aa)) {
                        array = array.concat(aa);
                    } else {
                        array.push(aa);
                    }
                }
            }
            UtilService.setModel(obj, modelKey, array);
        } else {
            UtilService.initModel(obj, modelKeys, options);
        }
    }

    static setModel(obj: any, modelKey: string, val: any) {
        if (modelKey) {
            if (modelKey && modelKey.indexOf('.') >= 0) {
                const fields = modelKey.split('.');
                const k = fields[fields.length - 1];
                fields.splice(fields.length - 1, 1);
                const key = fields.join('.');
                let o = LodashService.get(obj.data, key);
                if (!o) {
                    Vue.set(obj.data, key, {});
                    o = LodashService.get(obj.data, key);
                }
                if (o) { Vue.set(o, k, val); }
            } else {
                if (obj.data) { Vue.set(obj.data, modelKey, val); }
            }
        } else {
            if (obj) { Vue.set(obj, 'data', val); }
        }
    }

    static resetFieldError(form: any, errorProp: any, propKey: string) {
        // Backend Error
        if (errorProp && !UtilService.isEmpty(errorProp.fields)) {
            if (!UtilService.isEmpty(LodashService.get(errorProp.fields, propKey))) {
                Vue.set(errorProp.fields, propKey, null);
            }
        }
        // Reset page level error only if no backend and frontend errors.
        // if (errorProp && !UtilService.isEmpty(errorProp.page) && (UtilService.isEmpty(errorProp.fields) && (!form || !LodashService.find(form.fields, (f) => f.validateState === 'error')))) {
        if (errorProp) {
            let empty = true;
            if (errorProp.fields) {
                for (const f in errorProp.fields) {
                    if (errorProp.fields.hasOwnProperty(f) && errorProp.fields[f]) {
                        empty = false;
                        break;
                    }
                }
            }
            if (errorProp && empty) {
                Vue.set(errorProp, 'page', null);
            }
        }
    }

    static setFieldError(errorProp: any, propKey: string, message: string) {
        if (errorProp) {
            Vue.set(errorProp.fields, propKey, message);
        }
    }

    static checkDependency(item: any, dependent: any) {
        const check = (d: any, m: any) => {
            const ret = LodashService.get(item, m && m.model ? m.model : m);
            let found: boolean = ret === false ? ret : !UtilService.isEmpty(ret);
            if (found && d.hasOwnProperty('empty') && ret === d.empty) {
                found = false; // Define own empty example: show/hide 0
            } else if (!found && d.hasOwnProperty('nonEmptyValue') && (d.nonEmptyValue === ret)) {
                found = true;
            }
            if (found) {
                const value = m && m.hasOwnProperty('value') ? m.value : (d.hasOwnProperty('value') ? d.value : null);
                if (value) {
                    if (LodashService.isArray(value)) {
                        found = LodashService.find(value, (v) => (String(ret) === String(v)));
                    } else if (LodashService.isArray(ret)) {
                        found = LodashService.find(ret, (v) => (String(v) === String(value)));
                    } else {
                        found = (String(ret) === String(value));
                    }
                }
            }
            return found;
        };
        if (dependent && dependent.model) {
            if (LodashService.isArray(dependent.model)) {
                let f1 = 0;
                for (const m of dependent.model) {
                    let ff = m ? check(dependent, m) : true;
                    ff = m && m.hide ? !ff : ff;
                    if (ff) {
                        f1 += 1;
                        if (!dependent.all) { break; }
                    }
                }
                return dependent.all ? (f1 === dependent.model.length) : !!f1 ;
            } else {
                const f2 = check(dependent, dependent.model);
                return (dependent.hide ? !f2 : f2);
            }
        } else {
            return true;
        }
    }

    /*
    * Get all the ids in object or arrays. Same as server side.
    */
    static getId(item: any) {
        if (LodashService.isArray(item)) {
            const ids = [];
            for (const obj of item) {
                ids.push(obj && obj.hasOwnProperty('_id') ? obj._id : obj);
            }
            return ids;
        } else {
            return item && item.hasOwnProperty('_id') ? item._id : item;
        }
    }

    static parseJson(str: any, removeLine: boolean) {
        if (str) {
            if (removeLine) {
                str = String(str).replace(/(\r\n|\n|\r)/gm, '');
            }
            try {
                const s = JSON.parse(str);
                if (s === Infinity) {
					return str; // Must be _id which consists of number and e
				} else {
					return s;
				}
            } catch (e) {
                return str;
            }
        } else {
            return null;
        }
    }

    static isEmptyRow(item: any, idLabel: string, defaultRow: any) {
        let empty = true;
        for (const a of item) {
            const row = UtilService.removeEmptyFields(UtilService.cloneObject(a));
            delete row.id;
            if (defaultRow && !defaultRow.hasOwnProperty(idLabel || '_id')) {
                delete row[idLabel || '_id'];
            }
            if (UtilService.isEmpty(row)) { // Is empty, check next row
                continue;
            } else if (LodashService.isEmpty(defaultRow)) { // Simple check - {}, '', undefined
                empty = false; // Is not empty, exit
                break;
            } else { // Is not empty, check against key fields in default row
                for (const d in defaultRow) {
                    if (!UtilService.isEmpty(row[d])) {
                        empty = false;
                        break;
                    }
                }
            }
        }
        return empty;
    }

    /*
     * Is Empty checks for many types of empty data - different from server side!!!
     * If same as default, consider empty
     * If {0}, [null], {undefined}, etc. consider empty
     */
    static isEmpty(item: any, defaultRow?: any) {
        // tslint:disable-next-line: use-isnan
        if (item === null || item === undefined || Number.isNaN(item) || (typeof item === 'object' && (Object.keys(item).length === 0 && (!item.getTime || !item.getTime()))) || (typeof item === 'string' && item.trim().length === 0)) {
            return true;
        } else if (LodashService.isArray(item) && defaultRow && item.length > 0) {
            return UtilService.isEmptyRow(item, '_id', defaultRow);
        } else if (LodashService.isObject(item) && Object.keys(item).length > 0) {
            // Take care of [undefined] or [null]
            for (const o of Object.keys(item)) {
                if (item.hasOwnProperty(o) && !UtilService.isEmpty((item as any)[o])) { // Take care of checkbox/number
                    return false;
                }
            }
            return true;
        } else if (item === false || item === true) {
            return false;
        } else {
            return false;
        }
    }

    static mergeObject(obj1: any, obj2: any) {
        return obj2 ? LodashService.merge({}, obj1, obj2) : obj1; // Do not alter the object content
    }

    static cloneObject(obj: any): any {
        const copyFunctions = (src: any, dest: any) => {
            if (LodashService.isObject(src)) {
                for (const o in src) {
                    if (typeof (src as any)[o] === 'function') {
                        dest[o] = (src as any)[o];
                    }
                }
            }
        };
        if (LodashService.isArray(obj)) {
            const ret = [];
            for (const o of obj) {
                ret.push(UtilService.cloneObject(o));
            }
            return ret;
        } else if (LodashService.isObject(obj)) {
            const ret = obj ? UtilService.parseJson(JSON.stringify(obj), false) : {};
            copyFunctions(obj, ret); // Need this as clone rules does not carry over functions
            return ret;
        } else {
            return obj;
        }
    }

    static compareObject(obj1: any, obj2: any, id?: string) {
        const idString = id || '_id';
        return String(obj1 && obj1[idString] ? obj1[idString] : (obj1 || '')) === String(obj2 && obj2[idString] ? obj2[idString] : (obj2 || ''));
    }

    static setAll(obj: any, src: any) {
        for (const s of Object.keys(src)) {
            Vue.set(obj, s, src[s]);
        }
    }

    static copyFields(obj: any, fields: string[]) {
        const ret: any = {};
        if (!UtilService.isEmpty(obj)) {
            const keys = LodashService.sortBy(Object.keys(obj), (s) => s); // Reorder so that we can do strongify and compare
            for (const s of keys) {
                if (LodashService.find(fields, (f) => f === s)) {
                    ret[s] = obj[s];
                }
            }
        }
        return ret;
    }

    // Reset all fields if it is not found in src, otherwise copy from source.
    // static resetAndCopyObject(obj: any, src: any) {
    //     if (obj && !UtilService.isEmpty(obj)) {
    //         const keys = Object.keys(obj); // Reorder so that we can do stringify and compare
    //         for (const a of keys) {
    //             if (obj.hasOwnProperty(a) && !UtilService.isEmpty(obj[a])) {
    //                 obj[a] = src && src.hasOwnProperty(a) ? src[a] : undefined;
    //             }
    //         }
    //         const skeys = Object.keys(src); // Reorder so that we can do stringify and compare
    //         for (const a of skeys) {
    //             if (src.hasOwnProperty(a) && !UtilService.isEmpty(src[a])) {
    //                 obj[a] = src[a];
    //             }
    //         }
    //     } else {
    //         obj = src;
    //     }
    // }

    static resetFields(obj: any) {
        let reset = false;
        if (obj && !UtilService.isEmpty(obj)) {
            for (const a of Object.keys(obj)) {
                if (obj.hasOwnProperty(a) && !UtilService.isEmpty(obj[a])) {
                    obj[a] = undefined;
                    reset = true;
                }
            }
        }
        return reset;
    }

    static removeEmptyFields(obj: any) {
        const ret: any = {};
        if (obj && !UtilService.isEmpty(obj)) {
            const keys = LodashService.sortBy(Object.keys(obj), (s) => s); // Reorder so that we can do stringify and compare
            for (const a of keys) {
                if (obj.hasOwnProperty(a) && !UtilService.isEmpty(obj[a])) {
                    ret[a] = obj[a];
                }
            }
        }
        return ret;
    }

    static getIndexById(id: string, array: any[], defaultPos: number, key?: string) {
        if (id) {
            if (array && LodashService.isArray(array)) {
                for (let m = 0; m < array.length; m++) {
                    if (array.hasOwnProperty(m) && array[m] && LodashService.get(array[m], key || `_id`) === id) {
                        return m;
                    }
                }
            }
        }
        return defaultPos;
    }

    static getObjById(id: string, obj: any, key: string, defaultObj: any) {
        if (obj) {
            if (LodashService.isArray(obj)) {
                for (const v of obj) {
                    if (v && LodashService.get(v, key) === id) {
                       return v;
                    }
                }
            } else {
                if (obj && LodashService.get(obj, key) === id) {
                    return obj;
                }
            }
        }
        return defaultObj;
    }

    static getNumber(value: any, decimal: number) {
        const n = (value && LodashService.isString(value) ? value.replace(/[^\d.-]/g, '') : value) || 0;

        const toFixed = (val: any, dec: number) => {
            const str = val ? (val.toFixed ? val.toString() : val) : '';
            const d = dec === undefined ? 2 : dec;
            const num = str ? (str.indexOf('e') < 0 ? Math.round(Number(str + 'e' + d)) + 'e-' + d : str) : 0;
            let r = Number(num).toFixed(d);
            if (Number(r) === 0) { r = Number(0).toFixed(dec); }
            return r;
        };

        return LodashService.isNumber(n) ? Number(toFixed(n, decimal)) : Number(toFixed(Number(n), decimal));
    }

    static toFixed(value: any, decimal: number, noGroup?: boolean) {
        const locale = UserStore.language;
        return UtilService.getNumber(value, decimal).toLocaleString(locale, { minimumFractionDigits: decimal, useGrouping: !noGroup});
    }

    static getColor(view: any, value: any) {
        const color = view ? (view.colors ? LodashService.find(view.colors, (l: any) => l[view.idLabel || '_id'] === value) : {[view.valLabel || 'name']: view.color}) : null;
        return (color ? color[view.valLabel || 'name'] : 'default');
    }

    static formatEditAmount(value: any, decimal: number) {
        return UtilService.toFixed(UtilService.getNumber(value, decimal), decimal, true);
    }

    static formatPrependAppend(value: string, view: any, translate: boolean) {
        return (view.format && view.format.prepend ? (view.format.prependNoBold ? '' : '<strong class="mr-5">') + (view.format.i18n ? i18n.t(view.format.prepend) : view.format.prepend) + (view.format.hasOwnProperty('prependSeparator') ? view.format.prependSeparator : ':') + (view.format.prependNoBold ? '' : '</strong>') : '')
            + (value ? (translate ? i18n.t(value) : value) : (view.format ? (view.format.noLabel || '') : ''))
            + (view.format && view.format.append ? (view.format.i18n ? i18n.t(view.format.append) : view.format.append) : '');
    }

    static formatDuration(value: string, unit: string, longFormat?: boolean) {
        if (value) {
            if (!unit) { unit = ''; }
            let secNum = parseInt(value, 10); // don't forget the second param
            const negative = secNum < 0 ? true : false;
            secNum = Math.abs(secNum);
            if (unit.indexOf('s') !== 0) {
                secNum = secNum * 60;
            }
            let hours: any = Math.floor(secNum / 3600);
            let minutes: any = Math.floor((secNum - (hours * 3600)) / 60);
            let seconds: any = secNum - (hours * 3600) - (minutes * 60);
            let ret = '';
            if (longFormat) {
                ret = (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (unit.indexOf('s') === 0 ? (seconds ? seconds + 's' : '') : '');
            } else {
                if (hours < 10) { hours = '0' + hours; }
                if (minutes < 10) { minutes = '0' + minutes; }
                if (seconds < 10) { seconds = '0' + seconds; }
                ret = hours + ':' + minutes + (unit.indexOf('s') === 0 ? ':' + seconds : '');
            }
            return negative ? `-${ret}` : ret;
        } else {
            return '';
        }
    }

    static formatDate(value: any, view: any) {
        if (value) {
            return MomentService.format(value, view.format && view.format.format ? view.format.format : 'DD-MMM-YYYY');
        } else {
            return '';
        }
    }

    static formatTime(value: any, view: any) {
        if (value) {
            // const p = parent && view.format.timeZone ? LodashService.get(parent, view.format.timeZone) : '';
            // const a = UserStore.account && UserStore.account.timeZone ? UserStore.account.timeZone : '';
            // const timeZone = p || a || UserStore.timeZone;
            if (value && LodashService.isString(value) && value.length <= 5 && value.indexOf(':') >= 0) {
                return value;
            } else {
                return MomentService.format(value, view.format.format || UtilService.getDateFormat(false, true, false));
            }
        } else {
            return '';
        }
    }

    static formatDateTimeRange(startDate: any, endDate: any, parent: any, view: any) {
        return MomentService.formatRange(startDate, endDate, view.format.dayFormat || UtilService.getDateFormat(true, false, false), view.format.timeFormat || UtilService.getDateFormat(false, true, false));
    }

    static formatDateRange(startDate: any, endDate: any, parent: any, view: any) {
        return MomentService.formatRange(startDate, endDate, view.format && view.format.dayFormat ? view.format.dayFormat : UtilService.getDateFormat(true, false, false), '');
    }

    static formatTimeRange(startDate: any, endDate: any, parent: any, view: any) {
        return MomentService.formatRange(startDate, endDate, '', view.format.timeFormat || UtilService.getDateFormat(false, true, false));
    }

    static formatSize(value: any, view: any) {
        if (value !== undefined && view.format) {
            switch (view.format.value) {
                case 'MB':
                    return UtilService.toFixed(Number(value / 1048576), view.format.decimal !== undefined ? view.format.decimal : 1) + ' MB';
                case 'GB':
                    return UtilService.toFixed(Number(value / 1073741824), view.format.decimal !== undefined ? view.format.decimal : 1) + ' GB';
                default:
                    return value;
            }
        } else {
            return value;
        }
    }

    static getDateFormat(date: boolean, time: boolean, input: boolean) {
        return date && time ? (input ? 'dd-MMM-yyyy h:mm A' : 'DD-MMM-YYYY h:mm A') : (time ? 'h:mm A' : (input ? 'dd-MMM-yyyy' : 'DD-MMM-YYYY'));
    }

    static formatData(value: any, parent: any, view: any, functions: any) {
        const t = view && view.format ? view.format.type : '';
        let ret = value;
        let translate = view.i18n;
        switch (t) {
            case 'default':
                ret = value === undefined || value === null ? view.format.value : value;
                break;
            case 'boolean':
                ret = value ? ((view.format && view.format.trueLabel) || 'Yes') : (view.format && view.format.hasOwnProperty('falseLabel') ? view.format.falseLabel : 'No');
                break;
            case 'number':
                if (!value && view.format.hasOwnProperty('default')) { value = view.format.default; }
                const decimalNum = view.format.decimal !== undefined ? view.format.decimal : 0;
                ret = (value !== undefined && value !== view.format.empty) ? UtilService.toFixed(value || 0, decimalNum) : undefined;
                translate = false;
                break;
            case 'amount':
                if (!value && view.format.hasOwnProperty('default')) { value = view.format.default; }
                const decimal = view.format.decimal !== undefined ? view.format.decimal : 2;
                ret = (value !== undefined && value !== view.format.empty) ? UtilService.toFixed(value || 0, decimal) : undefined;
                translate = false;
                break;
            case 'size':
                ret = UtilService.formatSize(value, view);
                translate = false;
                break;
            case 'length':
                ret = value.length;
                translate = false;
                break;
            case 'date':
                ret = UtilService.formatDate(value, view);
                translate = false;
                break;
            case 'time':
                ret = UtilService.formatTime(value, view);
                translate = false;
                break;
            case 'duration':
                ret = UtilService.formatDuration(value, view.format.unit, view.format.long);
                translate = false;
                break;
            case 'object':
                const list = view.format.list;
                if (value === undefined && view.format.hasOwnProperty('default')) { value = view.format.default; }
                const object = list ? LodashService.find(list, (l) => l[view.format.idLabel || '_id'] === value) : null;
                ret = object ? object[view.format.valLabel || 'name'] : value;
                break;
            case 'badge':
                if (!value && view.format.hasOwnProperty('default')) { value = view.format.default; }
                if (view.format && view.format.format && view.format.format !== 'badge') {
                    value = UtilService.formatData(value, parent, {...view, format: view.format.format}, functions);
                }
                if (value) {
                    const color = view.format.colors ? LodashService.find(view.format.colors, (l: any) => l[view.format.idLabel || '_id'] === value) : {[view.format.valLabel || 'name']: view.format.color};
                    ret = '<span class="badge badge-' + (color ? color[view.format.valLabel || 'name'] : 'default') + '">' + (view.i18n ? i18n.t(value) : value) + '</span>';
                } else {
                    ret = value;
                }
                translate = false;
                break;
            case 'tag':
                const colors = view.format.colors;
                if (!value && view.format.hasOwnProperty('default')) { value = view.format.default; }
                const tag = colors ? LodashService.find(colors, (l) => l[view.format.idLabel || '_id'] === value) : (view.format.color ? {[view.format.valLabel || 'name']: view.format.color} : null);
                ret = tag ? '<span class="el-tag el-tag--' + tag[view.format.valLabel || 'name'] + ' el-tag--dark ' + (view.format.class ? view.format.class : '') + '" >' + (view.i18n ? i18n.t(value) : value) + '</span>' : (view.i18n ? i18n.t(value) : value);
                translate = false;
                break;
            case 'element':
                if (!value && view.format.hasOwnProperty('default')) { value = view.format.default; }
                ret = value ? (view.format.value ? ('<' + view.format.value + (view.format.class ? (' class="' + view.format.class + '">') : '>') + (view.i18n ? i18n.t(value) : value) + '</' + view.format.value + '>') : (view.i18n ? i18n.t(value) : value)) : '';
                translate = false;
                break;
            case 'pre':
                const str = JSON.stringify(value) || '';
                ret = `<pre style="white-space: break-spaces;">${str}</pre>`;
                translate = false;
                break;
            case 'icon':
                value = value && view.format.valLabel ? value[view.format.valLabel] : value;
                ret = value ? (`<i class="${view.format.class}"></i> ` + (view.i18n ? i18n.t(value) : value)) : '';
                translate = false;
                break;
            case 'filter':
                const func = functions && view.format.value && functions[view.format.value] ? functions[view.format.value] : null;
                if (process.env.NODE_ENV !== 'production' && functions && view.format.value && !functions[view.format.value]) {
                    console.error('Missing function: ', view.format.value);
                }
                ret = func ? func(value, parent, view, functions) : value;
                translate = false;
                break;
            case 'none':
                ret = '';
                break;
            default:
                if (value && LodashService.isArray(value)) {
                    if (value.length > 0) {
                        const retArray = [];
                        translate = false;
                        for (let v of value) {
                            if (v) {
                                if (view.format && view.format.valLabel) {
                                    // Rows may be {_id: 'xxx'} and also {'xxx'}
                                    v = view.format.valLabel && !LodashService.isString(v) ? LodashService.get(v, view.format.valLabel) : v;
                                }
                                if (v) { retArray.push(view.i18n ? i18n.t(v) : v); }
                            }
                        }
                        if ((parent && parent.bullet) || (view.format && view.format.separator === '</li><li>')) {
                            ret = '<ul class="m-0" style="padding-inline-start: 20px;"><li>' + retArray.join('</li><li>') + '</li></ul>';
                        } else {
                            ret = retArray.join(view.format && view.format.separator ? view.format.separator : ', ');
                        }
                    } else {
                        ret = '';
                    }
                } else if (value && LodashService.isObject(value)) {
                    if (view.format && view.format.valLabel) {
                        ret = view.format.valLabel ? LodashService.get((value as any), view.format.valLabel) : value;
                    }
                } else if (LodashService.isBoolean(value)) {
                    ret = value ? ((view.format && view.format.trueLabel) || 'Yes') : (view.format && view.format.hasOwnProperty('falseLabel') ? view.format.falseLabel : 'No');
                }
                break;
        }
        return (ret || (view && view.format && view.format.showEmpty)) ? UtilService.formatPrependAppend(ret, view, translate) : '';
    }

    static formatObj(v: any, item: any, view: any, functions: any) {
        switch (item.kind) {
            case 'Checkbox':
            case 'Terms':
				return v && v !== 'false' ? '<i class="fas fa-check-square color-success"></i>' : '<i class="far fa-square"></i>';
			case 'Options':
				return v && v instanceof Array ? (v.length > 0 ? (v.length === 1 ? v[0] : (item && item.bullet ? `<ul><li>${v.join('</li><li>')}</li></ul>` : v.join(', '))) : '') : (v !== undefined && v !== null ? v : '');
			case 'Date':
				return UtilService.formatDate(v, {format: {type: 'date'}});
			case 'Time':
				return UtilService.formatTime(v, {format: {type: 'time'}});
			case 'Staff':
				return v !== undefined ? (v && v.name ? v.name : v) : '';
			case 'Temperature':
				return (v !== undefined && v !== null) ? v + ' &#8451;' : '';
            case 'Duration':
				return UtilService.formatDuration(v, item && item.unit ? item.unit : '', item && item.longFormat ? !!item.longFormat : false);
            default:
                return (v !== undefined && v !== null) ? v : '';
        }
    }

    static setUniqueId(item: any) {
        if (item && LodashService.isObject(item)) {
            (item as any).id = (item as any)._id ? (item as any)._id : uuidv4();
        }
        return item;
    }

    static sprintf(str: string, ...args: any) {
        let i = 0;
        while (/%s/.test(str)) {
            str = str.replace('%s', args[i++]);
        }
        return str;
    }

    static getLocale(language: string) {
        switch (language) {
            case 'id':
                return 'id';
            case 'ms':
                return 'ms-my';
            case 'zh':
                return 'zh-cn';
            default:
                return 'en-gb';
        }
    }

    static isNumeric(str: string) {
        if (typeof str !== 'string') { return false; } // we only process strings!
        return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }

    static getFirstInArray(obj: any) {
		return obj && LodashService.isArray(obj) ? (obj.length > 0 ? obj[0] : null) : obj;
	}
}
