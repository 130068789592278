<template>
    <div class="page-header card-base card-shadow--small flex">
        <slot name="left" class="box center page-button-left"></slot>
        <span class="box center grow page-title" v-if="title">{{title}}
            <span v-if="info">{{info}}</span>
        </span>
        <slot name="right" class="box center page-button-right"></slot>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { PageHeader } from 'element-ui';

    Vue.component(PageHeader.name, PageHeader);

    @Component
    export default class ElemPageHeader extends Vue {

        @Prop({}) title!: string;
        @Prop({}) info!: string;

    }
</script>

<style lang="scss" scoped>
    @import '../../layouts/system-layout/scss/variables';

    .page-header {

        background-color: lighten($text-color, 80%);

        &.card-base {
            padding: 5px;
            //  line-height: unset;
        }

        .page-title {
            padding-left: 10px;
            font-weight: bold;
            line-height: 32px;
        }

        margin-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid lighten($text-color, 60%);
        border-bottom-width: 0;

        .header-badge {
            border-color: white;
            border-width: thin;
            border-style: ridge;
            min-width: 50px;
            text-align: center;
        }
    }

     @media (max-width: 768px) {

         .page-header {
             &.card-base {
                padding: 0;
            }
         }
     }

</style>
