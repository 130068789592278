export default class ComponentService {

    static getComponent(type: string) {
        switch (type) {
            case 'select':
                return 'ElemSelect';
            case 'date':
                return 'ElemDate';
            case 'daterange':
                return 'ElemDateRange';
            case 'image':
                return 'ElemImage';
            case 'alert':
                return 'ElemAlert';
            case 'map':
                return 'ElemMap';
            case 'button':
                return 'ElemButton';
            case 'carousel':
                return 'ElemCarousel';
            case 'gallery':
                return 'ElemGallery';
            case 'textarea':
                return 'ElemTextArea';
            case 'editor':
                return 'ElemEditor';
            case 'uploader':
                return 'ElemUploader';
            case 'checkbox':
                return 'ElemCheckbox';
            case 'list':
                return 'ElemList';
            case 'table':
                return 'ElemTable';
            case 'dropdown':
                return 'ElemDropdown';
            case 'radio':
                return 'ElemRadio';
            case 'form':
                return 'ElemForm';
            case 'link':
                return 'ElemLink';
            case 'html':
                return 'ElemHtml';
            case 'time':
                return 'ElemTime';
            case 'tree':
                return 'ElemTree';
            case 'rate':
                return 'ElemRate';
            case 'slider':
                return 'ElemSlider';
            case 'progress':
                return 'ElemProgress';
            case 'captcha':
                return 'ElemCaptcha';
            case 'chat':
                return 'ElemChat';
            case 'qrcode':
                return 'ElemQrcode';
            case 'popover':
                return 'ElemPopover';
            case 'file':
                return 'ElemFile';
            case 'lang':
                return 'ElemLang';
            case 'color':
                return 'ElemColor';
            case 'icon':
                return 'ElemIcon';
            case 'input':
            default:
                return 'ElemInput';
        }
    }

    static importComponents() {
        return {
            ElemInput: () => import('@/views/component/elem-input.vue'),
            ElemSelect: () => import('@/views/component/elem-select.vue'),
            ElemDate: () => import('@/views/component/elem-date.vue'),
            ElemDateRange: () => import('@/views/component/elem-daterange.vue'),
            ElemImage: () => import('@/views/component/elem-image.vue'),
            ElemAlert: () => import('@/views/component/elem-alert.vue'),
            ElemMap: () => import('@/views/component/elem-map.vue'),
            ElemButton: () => import('@/views/component/elem-button.vue'),
            ElemCarousel: () => import('@/views/component/elem-carousel.vue'),
            ElemGallery: () => import('@/views/component/elem-gallery.vue'),
            ElemTextArea: () => import('@/views/component/elem-textarea.vue'),
            ElemCheckbox: () => import('@/views/component/elem-checkbox.vue'),
            ElemList: () => import('@/views/component/elem-list.vue'),
            ElemTable: () => import('@/views/component/elem-table.vue'),
            ElemDropdown: () => import('@/views/component/elem-dropdown.vue'),
            ElemRadio: () => import('@/views/component/elem-radio.vue'),
            ElemForm: () => import('@/views/component/elem-form.vue'),
            ElemHtml: () => import('@/views/component/elem-html.vue'),
            ElemTime: () => import('@/views/component/elem-time.vue'),
            ElemLink: () => import('@/views/component/elem-link.vue'),
            ElemTree: () => import('@/views/component/elem-tree.vue'),
            ElemRate: () => import('@/views/component/elem-rate.vue'),
            ElemSlider: () => import('@/views/component/elem-slider.vue'),
            ElemProgress: () => import('@/views/component/elem-progress.vue'),
            ElemCaptcha: () => import('@/views/component/elem-captcha.vue'),
            ElemChat: () => import('@/views/component/elem-chat.vue'),
            ElemPopover: () => import('@/views/component/elem-popover.vue'),
            ElemFile: () => import('@/views/component/elem-file.vue'),
            ElemLang: () => import('@/views/component/elem-lang.vue'),
            // The following only used in edit mode or they are big...so load when needed.
            ElemQrcode: () => import('@/views/component/elem-qrcode.vue'),
            ElemUploader: () => import('@/views/component/elem-uploader.vue'),
            ElemColor: () => import('@/views/component/elem-color.vue'),
            ElemIcon: () => import('@/views/component/elem-icon.vue'),
            ElemEditor: () => import('@/views/component/elem-editor.vue'),
        };
    }

}
