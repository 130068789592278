<template>
    <div class="elem-form flex column" v-if="view">
        <el-form class="box grow flex column" :ref="'formElem'" :model="model || {}"
        :validate-on-rule-change="false" @validate="updateTabErrors"
        novalidate="true">
            <div class="flex grid wrap" :class="view.button_class">
                <div class="action-row flex wrap box grow" v-if="!options.buttonBottom">
                    <elem-button v-for="button in filterDependent(view.buttons)" :view="button" :value="model" :key="button.label" :functions="functions" :size="size" :class="button.class" :form="formProp" :formView="view" :error.sync="errorProp"></elem-button>
                </div>
                <slot name="status"></slot>
            </div>
            <el-tabs :type="options.tabType" :stretch="options.tabStretch" v-model="tab" @tab-click="clickTab" v-if="viewTabs && viewTabs.length > 0 && viewTabs[0].name">
                <el-tab-pane :name="t.name" v-for="t in viewTabs" :key="t.name">
                    <span slot="label">{{$t(t.name)}} <i v-if="t.required" class="el-icon-star-on warning-text"></i><i v-if="tabErrors[t.name]" class="el-icon-warning danger-text"></i></span>
                </el-tab-pane>
            </el-tabs>
            <div v-for="t in viewTabs" :key="t.name" :ref="'tabName'+t.name" v-show="(!t.name || (t.name === tab))" :class="[t.class, (!t.class || t.class.indexOf('pt-') < 0) ? 'pt-20' : '']">
                <span v-for="(f, index) in filterDependent(t.fields)" :key="index+(f.model||'')" :class="f.class" :style="getFieldStyle(f)">
                    <el-form-item v-if="f && !f.fields" class="elem-field" :class="getFieldClass(f, errorProp)" :prop="f.prop || f.model" :rules="f.rules">
                        <div v-if="f && (f.label || f.buttons && f.buttons.length > 0)" class="elem-field-label flex">
                            <span v-if="f && f.label" :class="f.label_class || 'box grow'">{{$t(f.label)}} <span v-if="f && f.info" v-html="f.i18n ? $t(f.info) : f.info"></span> <i v-if="f && isRequired(f.rules)" class="el-icon-star-on" :class="{'warning-text': !isPartial(f.rules)}"></i></span>
                            <span v-if="f && f.buttons && f.buttons.length > 0" class="flex wrap" :class="f.button_class">
                                <elem-button v-for="(button, index12) in filterDependent(f.buttons)" :view="button" :value.sync="model" :model-key="f.model" :key="index12" :functions="functions" :size="f.size || 'mini'"></elem-button>
                            </span>
                        </div>
                        <component v-if="f && f.type" class="elem-field-value" :view="f" :value.sync="model" :prop-key="f.prop" :model-key="f.model" :functions="functions" :size="size" :resized="resized" :form="formProp" :error.sync="errorProp" :is="getComponent(f.type)"></component>
                        <div v-else v-html="formatData(f)" class="elem-field-value" :class="f.class"></div>
                        <elem-help :view="f" v-if="f.help && (f.help.message || f.help.messageFunc) && (!f.help.showFunc || (f.help.showFunc && showFunc(f, f.help.showFunc))) && checkDependent(f.help.depend)" :trigger="f.help.trigger" :value="model" :prop-key="f.prop" :model-key="f.model" :functions="functions"></elem-help>
                        <div class="el-form-item__error" v-if="getFieldError(f)">
                            <i class="el-icon-warning"></i> {{getFieldError(f)}}
                        </div>
                    </el-form-item>
                    <template v-else>
                        <span v-for="(f2, index2) in filterDependent(f.fields)" :key="index2+(f2.model || '')" :class="f2.class" :style="getFieldStyle(f2)">
                            <el-form-item class="elem-field" :class="getFieldClass(f2, errorProp)" :prop="f2.prop || f2.model" :rules="f2.rules">
                                <div v-if="f2 && f2.label" class="elem-field-label flex">
                                    <span :class="f2.label_class || 'box grow'">{{$t(f2.label)}} <span v-if="f2 && f2.info" v-html="f2.i18n ? $t(f2.info) : f2.info"></span> <i v-if="f2 && isRequired(f2.rules)" class="el-icon-star-on" :class="{'warning-text': !isPartial(f2.rules)}"></i></span>
                                    <span v-if="f2.buttons && f2.buttons.length > 0" class="flex wrap" :class="f.button_class">
                                        <elem-button v-for="(button, index22) in filterDependent(f2.buttons)" :view="button" :value.sync="model" :model-key="f2.model" :key="index22" :functions="functions" :size="f2.size || 'mini'"></elem-button>
                                    </span>
                                </div>
                                <component v-if="f2 && f2.type" class="elem-field-value" :view="f2" :value.sync="model" :prop-key="f2.prop" :model-key="f2.model" :functions="functions" :size="size" :resized="resized" :form="formProp" :error.sync="errorProp" :is="getComponent(f2.type)"></component>
                                <div v-else v-html="formatData(f2)" class="elem-field-value" :class="f2.class"></div>
                                <elem-help :view="f2" v-if="f2.help && (f2.help.message || f2.help.messageFunc) && (!f2.help.showFunc || (f2.help.showFunc && showFunc(f2, f2.help.showFunc))) && checkDependent(f2.help.depend)" :trigger="f2.help.trigger" :value="model" :prop-key="f2.prop" :model-key="f2.model" :functions="functions"></elem-help>
                                <div class="el-form-item__error" v-if="getFieldError(f2)">
                                    <i class="el-icon-warning"></i> {{getFieldError(f2)}}
                                </div>
                            </el-form-item>
                        </span>
                    </template>
                </span>
            </div>
             <div class="flex grid wrap" v-if="options.buttonBottom">
                <div class="action-row p-10 flex wrap box grow">
                    <elem-button v-for="button in filterDependent(view.buttons)" :view="button" :value="model" :key="button.label" :functions="functions" :size="size" :class="button.class" :form="formProp" :formView="view" :error.sync="errorProp"></elem-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script lang="ts">

    import { Vue, Component, Prop, PropSync, Watch, Ref } from 'vue-property-decorator';
    import i18n, { setMessage } from '@/lang';
    import i18nElemForm from '@/lang/component/elem-form';
    import { Form, FormItem, Row, Col, Tabs, TabPane, Tag, ButtonGroup } from 'element-ui';
    import { UserStore } from '@/store/user.store';
    import ElemHelp from '@/views/component/elem-help.vue';
    import UtilService from '@/services/common/util.service';
    import ComponentService from '@/services/common/component.service';
    import ElementService from '@/services/common/element.service';
    import LodashService from '@/services/common/lodash.service';

    Vue.component(Form.name, Form);
    Vue.component(FormItem.name, FormItem);
    Vue.component(Row.name, Row);
    Vue.component(Col.name, Col);
    Vue.component(Tabs.name, Tabs);
    Vue.component(TabPane.name, TabPane);
    Vue.component(Tag.name, Tag);
    Vue.component(ButtonGroup.name, ButtonGroup);

    @Component({
        components: {
            ...ComponentService.importComponents(), ElemHelp,
        },
    })
    export default class ElemForm extends Vue {

        @Prop() view!: any;
        @Prop() modelKey!: string;
        @Prop() readonly propKey!: string;
        @PropSync('value') data!: any;
        @Prop() readonly functions: any;
        @Prop() readonly resized!: number;
        @Prop() readonly size!: string;
        @PropSync('error') errorProp!: any;
        @PropSync('form') formProp!: any;
        @Prop() readonly setDirty!: boolean;
        @Ref('formElem') readonly formElement!: Vue;
        tab: string = '';
        private title: string = '';
        private defaultOptions: any = {
            tabType: '',
            tabStretch: false,
            bottomButton: false,
        };
        tabErrors: any = {};

        get model() {
            return UtilService.getModel(this.data, this.modelKey, this.options);
        }

        set model(val) {
            UtilService.resetFieldError(this.formProp, this.errorProp, this.propKey || this.modelKey);
            UtilService.setModel(this, this.modelKey, val);
        }

        get options() {
            return ElementService.getOptions(this);
        }

        get viewTabs() {
            return this.view && this.view.tabs ? LodashService.filter(this.view.tabs, (t) => t && this.checkDependent(t.depend)) : [];
        }

        formatData(f: any) {
            return UtilService.formatData(f.model ? LodashService.get(this.model, f.model) : f.value, this.model, f, this.functions);
        }

        private isRequired(rules: any) {
            return ElementService.isRequired(rules);
        }

        private isPartial(rules: any) {
            return ElementService.isPartial(rules);
        }

        private getSelectedAccount() {
            if (this.model && UserStore) {
                Vue.set(this.model, 'selectedAccount', UserStore.account);
            }
        }

        private init() {
            UtilService.initModel(this, this.modelKey, this.options);
            if (this.view) {
                // Auto assign tab
                // (this.title !== this.view.title) Do for Edit and View
                if (this.viewTabs && this.viewTabs.length > 0 && ((this.model && !this.model._id) || !this.title || (!this.tab || this.view.hasOwnProperty('defaultTab') || !LodashService.find(this.viewTabs, (t) => this.tab === t.name)))) {
                    this.tab = this.viewTabs[this.view.defaultTab || 0] ? (this.viewTabs[this.view.defaultTab || 0].name) : (this.viewTabs[0] ? this.viewTabs[0].name : '');
                }
                this.title = this.view.title;
                ElementService.setFormValidate(this.view, this.functions, this.model);
            }
            if (this.errorProp && !LodashService.isString(this.errorProp)) {
                this.errorProp.page = null;
                this.errorProp.fields = {};
            }
            Vue.set(this, 'tabErrors', {});
            this.getSelectedAccount();
        }

        getComponent(type: string) {
            return ComponentService.getComponent(type);
        }

        getFieldClass(f: any, errorProp: any) {
            return f && errorProp && errorProp.fields && errorProp.fields[f.prop || f.model] ?  (f.field_class ? {'is-error': true, [f.field_class]: true} : {'is-error': true}) : (f.field_class || {});
        }

        getFieldStyle(f: any) {
            const style = f.style || {};
            if (!style[`min-width`] && !style[`width`]) {
                style[`min-width`] = '200px';
            } else if (style[`width`]) {
                style[`min-width`] = style[`width`];
            }
            return style;
        }

        checkDependent(depend: any) {
            return UtilService.checkDependency(this.model, depend);
        }

        filterDependent(list: any[]) {
            return LodashService.filter(list, (l: any) => {
                return l && this.checkDependent(l.depend);
            });
        }

        showFunc(view: any, func: string) {
            return this.functions && this.functions[func] && this.functions[func](LodashService.get(this.data, view.model), this.data, view);
        }

        getFieldError(f: any) {
            let found = '';
            if (f && f.model && this.errorProp && this.errorProp.fields) {
                found = LodashService.get(this.errorProp.fields, f.prop || f.model);
            }
            return found;
        }

        async clickTab(tab: any) {
            this.tab = tab.name;
            const t = LodashService.find(this.viewTabs, (tt) => tt.name === this.tab);
            if (t && t.func && this.functions[t.func]) {
                await this.functions[t.func](UtilService.getModel(this.data, this.modelKey, this.options), this.data, t);
            }
        }

        private setFormFunc() {
            if (this.formElement) {
                Vue.set(this, 'formProp', this.formElement);
                (this.formElement as any).setDirty = this.setDirty;
                ElementService.setDirty(this.formElement, false);
            }
            if (this.tab) { this.clickTab({name: this.tab}); }
        }

        updateTabErrors() {
            // Give ui some time to update.
            setTimeout(() => {
                const tabErrors: any = {};
                if (this.view && this.viewTabs && this.viewTabs.length > 0) {
                    for (const t of this.viewTabs) {
                        const tab: any = this.$refs['tabName' + t.name];
                        if (tab && tab[0]) {
                            const found = tab[0].querySelectorAll('.el-form-item.is-error');
                            tabErrors[t.name] = found && found.length > 0;
                        }
                    }
                }
                Vue.set(this, 'tabErrors', tabErrors);
            });
        }

        @Watch('view')
        onViewChanged(val: any, oldVal: any) {
            this.init();
            this.setFormFunc();
        }

        created() {
            setMessage(i18nElemForm);
            this.init();
        }

        mounted() {
            this.setFormFunc();
        }

    }
</script>

<style lang="scss">

    @import '../../layouts/system-layout/scss/variables';

    .elem-form {
        background-color: #FFFFFF;

        i.warning {
            color: $text-color-warning;
        }

        .el-tabs__header.is-top {
            margin: 0;
        }

        .el-tabs.el-tabs--card .el-tabs__item:not(.is-active) {
            background-color: lightgrey;
        }

        .el-input__suffix {
            .el-input__suffix-inner {
                i.el-icon-circle-close {
                    color: $text-color-danger;
                }
            }
        }

        .el-form-item.is-error .elem-map > input, .el-form-item.is-error .elem-map > input:focus, .el-form-item.is-error .elem-editor {
            border-color: $text-color-danger;
        }

        .action-row {

            .elem-button {
                margin-left: 0;
                margin-right: 5px;
                margin-bottom: 5px;
            }

            .elem-button.right{
                margin-left: auto;
                margin-right: 5px;
            }

        }

        .elem-field {
            line-height: 20px;
            margin-left: 10px;
            margin-right: 10px;

            .el-form-item__content {
                width: 100%;
                line-height: 20px;
                font-size: 1rem;
            }

            .elem-field-label {
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .elem-field-value {
                margin-bottom: 0;
                overflow-wrap: break-word;
            }

            .el-form-item__error {
                position: static;
                line-height: 14px;
            }

        }

    }


</style>
