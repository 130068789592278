import { Vue } from 'vue-property-decorator';
import i18n from '@/lang';
import UtilService from '../common/util.service';
import { UserStore } from '@/store/user.store';
import LodashService from '../common/lodash.service';
import { MenuStore } from '@/store/menu.store';

export default class CommonService {

    static formatListTitle(v: any, item: any, view: any, functions: any) {
        const important = item && item.important ? `<span class="elem-tooltip"><i class="el-icon-star-on warning-text"></i><span class="elem-tooltiptext"><span class="el-tooltip__popper is-light">${i18n.t('Important')}</span></span></span>` : '';
        const status = item && (item.status === 'Recalled' || item.status === 'Cancelled') ? `<span class="elem-tooltip"><i class="fas fa-times-circle danger-text"></i><span class="elem-tooltiptext"><span class="el-tooltip__popper is-light">${i18n.t(item.status)}</span></span></span>` : '';
        const val = important + status + v;
        return (val ? val : '');
    }

    static formatViewTitle(v: any, item: any, view: any, functions: any) {
        const refNumModel = view.format && view.format.args && view.format.args.i18n ? i18n.t(item[view.format.args.refNumModel || 'refNum']) : item[view.format.args.refNumModel || 'refNum'];
        const refNum = item.refNum && view && view.format && view.format.args && view.format.args.label ? ('<b>' + i18n.t(view.format.args.label) + '</b>: ' + refNumModel + '</br>') : '';
        let model = LodashService.get(item, view.model);
        if (view.i18n) { model = i18n.t(model); }
        const val = (item.important ? '<i class="el-icon-star-on warning-text"></i> ' : '') + ((item.status === 'Recalled' || item.status === 'Cancelled') ? '<i class="fas fa-times-circle danger-text"></i> ' : '')
            + (model ? `<b class="font-size-17">${model}</b><br/>` : '')
            + refNum
            + (item.updated ? UtilService.formatData(item.updated, item, {format: {type: 'date', i18n: true, format: UtilService.getDateFormat(true, true, false), prepend: 'Updated'}}, functions) : '')
            + (item.account && item.account.name && item.updated ? ' / ' : '')
            + (item.account && item.account.name ? item.account.name : '')
            + (item.scheduled && UserStore.appType === 'Staff' ? '<br/>' + UtilService.formatData(item.scheduled, item, {format: {type: 'date', i18n: true, format: UtilService.getDateFormat(true, true, false), prepend: 'Scheduled'}}, functions) : '');
        return (val ? val : '');
    }

    static formatHiddenAmount(val: any, item: any, view: any, functions: any) {
        return !MenuStore.unlock ? '****' : UtilService.formatData(val, item, {format: {type: 'amount'}}, {});
    }

    static formatHiddenValue(val: any, item: any, view: any, functions: any) {
        return !MenuStore.unlock ? '****' : val;
    }

    static formatLogUser(v: any, item: any, view: any, functions: any) {
        return v ? v : (item.updatedBy && item.updatedBy.displayName || '');
    }

    static formatGenderIcon(v: any, item: any, view: any, functions: any) {
        return v ? `<i class="fas ${String(v).toLowerCase() === 'female' ? 'fa-female fa-fw fa-2x color-success' : 'fa-male fa-fw fa-2x color-primary'}"></i>` : '';
    }

    static getAddress(item: any) {
        const address: string[] = [];
        if (item.addressComponents) {
            let last = '';
            for (const a of item.addressComponents) {
                if (a.long_name !== last) {
                    address.push(a.long_name);
                }
                last = a.long_name;
            }
        }
        return address;
    }

    static sendToChanged(val: any, item: any, view: any) {
        if (view.model !== 'all' && val && (val.groups || val.members || val.intakes)) {
            Vue.set(val, 'all', false);
            if (view.model === 'intakes' && val && val.intakes) {
                Vue.set(val, 'groups', false);
                Vue.set(val, 'members', false);
            }
            if (view.model !== 'intakes' && val && (val.groups || val.members)) {
                Vue.set(val, 'intakes', false);
            }
        } else if (view.model === 'all' && val && val.all) {
            Vue.set(val, 'groups', false);
            Vue.set(val, 'members', false);
            Vue.set(val, 'intakes', false);
        }
    }

}
