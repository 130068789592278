import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import RouteService from '@/services/common/route.service';
import commonRoutes from './common.router';
import { MenuStore } from '@/store/menu.store';

Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = commonRoutes;

const createRouter = () => new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: commonRoutes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher; // reset router
}

router.beforeEach((to: Route, from: Route, next: any) => {
    RouteService.checkTransition(router, to, from, next);
});

router.beforeResolve((to: Route, from, next) => {
    NProgress.start();
    next();
});

router.afterEach((to: Route, from: Route) => {
    MenuStore.SetRoute(to && to.meta ? to.meta : {});
    NProgress.done();
});

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = (router as any).history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: string) {
    // @ts-ignore
    return originalPush.call(this, location).catch((err: any) => err); // Silent same path error
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location: string) {
    // @ts-ignore
    return originalReplace.call(this, location).catch((err) => err); // Silent same path error
};

export default router;
