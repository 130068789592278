
import { Vue } from 'vue-property-decorator';
import request from '@/services/common/request.service';
import i18n, { setMessage } from '@/lang';
import printJS from 'print-js';
import { AppStore } from '@/store/app.store';
import { UserStore } from '@/store/user.store';
import { Loading } from 'element-ui';
import ErrorService from '@/services/common/error.service';
import RouteService from '@/services/common/route.service';
import UtilService from '@/services/common/util.service';
import ElementService from './element.service';
import LodashService from './lodash.service';
import { MenuStore } from '@/store/menu.store';
import MomentService from './moment.service';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FilesService from '../admin/files.service';

export default class ViewService {

    static getMenuLayout(route: any) {
        if (window.location && window.location.pathname && window.location.pathname.indexOf('/site/help') === 0) { // TODO Remove this once we can use System after migration
            return 'system-layout';
        } else {
            const getPathName = (p: string) => {
                if (p) {
                    const pathnames = p.split('/');
                    return pathnames && pathnames.length > 1 ? `/${pathnames[1]}` : `/`;
                } else {
                    return p;
                }
            };

            const pathname = getPathName(window.location.pathname);
            if (UserStore.menu) {
                const found: any = LodashService.find(UserStore.menu, (m: any) => m && (getPathName(m.alias) === pathname || getPathName(m.path) === pathname));
                if (found && found.meta && found.meta.siteLayout) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const layout = window.location.pathname.indexOf('/site/edit/') === 0 ? urlParams.get('layout') : ''; // Dont get from query if read mode
                    return layout ? layout : (found && found.meta && found.meta.siteLayout ? found.meta.siteLayout : '');
                }
            }
            return '';
        }

    }

    static async initView(route: any, obj: any) {
        if (route && route.meta) {
            if (route.meta.i18ns && route.meta.i18ns.length > 0) {
                for (const m of route.meta.i18ns) {
                    const i18nData = await import(`@/lang${m}`);
                    if (i18nData && i18nData.default) { setMessage(i18nData.default); }
                }
            }
            if (route.meta.services && route.meta.services.length > 0) {
                for (const m of route.meta.services) {
                    const s = await import(`@/services${m}.service`);
                    if (s && s.default) {
                        const ss = new s.default(obj, route.meta);
                    }
                }
            }
        }
    }

    static resetViewError(error: any) {
        if (!error) { error = {}; }
        if (error.all) { Vue.set(error, 'all', null); }
        if (error.list) { Vue.set(error, 'list', null); }
        if (error.page) { Vue.set(error, 'page', null); }
        if (!LodashService.isEmpty(error.fields)) { Vue.set(error, 'fields', {}); }
        return error;
    }

    static async listPageData(route: any, data: any, error: any, loading: any, view: any, args: any, cb: (args: any) => any) {
        error = ViewService.resetViewError(error);
        if (!loading) { loading = {}; }
        // if (!loading.list) {
        try {
            loading.list = loading.page = true;
            const savedWidgets = LodashService.get(data, 'list.view.widgets') || null;
            if (!UtilService.isEmpty(savedWidgets)) {
                args.noWidgets = true;
            }
            const saveTableStateSearch = LodashService.get(data, 'list.view.tableState.search') || null;
            if (data && data.page && data.page.view && !args.noRefreshPage) {
                data.page = {view: {title: data.page.view.title}};
            }
            if (data && data.list && data.list.view && data.list.view.tableState && !args.tableState) {
                args.tableState = data.list.view.tableState;
            }
            const ret = await cb(args);
            if (ret && ret.page && ret.page.value && ret.page.value) {
                ViewService.setQueryParams(ret.page.value, route, view);
            }
            if (saveTableStateSearch && ret) {
                LodashService.set(ret, 'list.view.tableState.search', saveTableStateSearch);
            }
            if (!UtilService.isEmpty(savedWidgets) && ret && UtilService.isEmpty(LodashService.get(ret, 'list.view.widgets'))) {
                LodashService.set(ret, 'list.view.widgets', savedWidgets);
            }
            loading.list = loading.page = false;
            return ret;
        } catch (err) {
            if (data && data.list) {
                error.list = ErrorService.getMessage(err);
            } else {
                error.all = ErrorService.getMessage(err);
            }
            loading.list = loading.page = false;
        }
        // }
    }

    static setQueryParams(value: any, route: any, view: any) {
        if (route) {
            const p: any = view.args || route.query;
            if (value && value._id) { p._id = value._id; }
            if (!UtilService.isEmpty(p)) {
                RouteService.addParamsToUrl(route, p);
            }
        }
    }

    private static async pageData(route: any, data: any, error: any, loading: any, view: any, args: any, cb: (args: any) => any) {
        const saveData = data ? data.page : null;
        error = ViewService.resetViewError(error);
        try {
            loading.page = true;
            const ret = await cb(args);
            if (ret) { ViewService.setQueryParams(ret.value, route, view); }
            const noAccount = !!(route && route.meta && route.meta.noAccount);
            if (ret && ret.value && !noAccount && !ret.value.account && UserStore.account) {
                ret.value.account = UtilService.getId(UserStore.account);
            }
            // Force a refresh
            if (data && data.page && data.page.view) {
                data.page = {view: {title: data.page.view.title}};
            }
            loading.page = false;
            return ret;
        } catch (err) {
            if (data && data.page) {
                data.page = saveData;
                error.page = ErrorService.getMessage(err);
                const fieldErrors = ErrorService.getFieldMessages(err);
                const message = data && data.page ? ElementService.updateFormFieldErrors(data.page.value, data.page.view, error, fieldErrors) : '';
                error.page = (error.page ? i18n.t(error.page) : '') + (error.page && message ? ' ' : '') + message;
            } else {
                error.all = ErrorService.getMessage(err);
            }
            error.err = err;
            loading.page = false;
            return data ? data.page : null;
        }
    }

    static async viewData(route: any, data: any, error: any, loading: any, view: any, args: any, val: any, cb: (args: any) => any) {
        if (!args) { args = {}; }
        if (val && val.account) { args.account = UtilService.getId(val.account); }
        if (UserStore.account) { args.selectedAccount = UtilService.getId(UserStore.account); }
        if (view && view._action) { args._action = view._action; }
        if (view && view.style) { args._style = view.style; }
        return await ViewService.pageData(route, data, error, loading, view, args, cb);
    }

    static async addData(route: any, data: any, error: any, loading: any, view: any, args: any, val: any, cb: (args: any) => any) {
        if (!val) { val = {}; }
        if (!val.account && (UserStore.account || UserStore.lastAccount)) {
            val.account = UserStore.account || UserStore.lastAccount;
        } else if (val.account) {
            UserStore.SetLastAccount(val.account);
        }
        return await ViewService.viewData(route, data, error, loading, view, args, val, cb);
    }

    static async editData(route: any, data: any, error: any, loading: any, view: any, args: any, val: any, cb: (args: any) => any) {
        if (!val) { val = {}; }
        return await ViewService.viewData(route, data, error, loading, view, args, val, cb);
    }

    static async saveData(route: any, data: any, error: any, loading: any, view: any, args: any, cb: (args: any) => any, preSave: ((args: any) => any) | null, listFunc: ((args: any) => any) | null) {
        if (view && view.fields && view.fields.length > 0) {
            const __v = args.__v;
            args = LodashService.pickBy(args, (value, key) => LodashService.find(view.fields, (f) => f === key));
            if (!__v) { args.__v = __v; } // Put back the version
        }
        const params = {_action: view._action, _noAlert: view._noAlert, ...args};
        // if (view.args) { params = {...params, ...view.args}; }
        let err = preSave ? preSave(params) : '';
        if (!err) {
            // May be a call that dun supply the following as we dont want to update the main page
            if (!error) { error = {}; }
            if (!loading) { loading = {}; }
            const ret = await ViewService.pageData(route, data, error, loading, view, params, cb);
            if (ret && ret.value && data) { data.page.value = ret.value; }
            if (error && !error.page && !error.all) {
                AppStore.SetDirty(false);
                if (listFunc) {
                    await listFunc(null);
                }
            } else {
                err = error ? (error.page || error.all) : '';
            }
        }
        return err; // Caller may want to stop popup from closing
    }

    static async savePopupData(item: any, view: any, cb: (args: any) => any, preSave: ((args: any) => any) | null, listFunc: ((args: any) => any) | null) {
        try {
            const data = {_action: view._action, _noAlert: view._noAlert, ...item};
            const err = preSave ? preSave(data) : '';
            if (err) {
                throw err; // Let calling button process the error
            } else {
                await cb(data);
                AppStore.SetDirty(false);
                if (listFunc) {
                    await listFunc(null);
                }
            }
        } catch (err) {
            throw err; // Let calling button process the error
        }
    }

    static async deleteData(route: any, data: any, error: any, loading: any, view: any, args: any, val: any, cb: (args: any) => any, listFunc: (args: any) => any) {
        if (val && val.account) { args.account = UtilService.getId(val.account); }
        let forceDelete = false;
        if (args && args._forceDelete) { // First time dont force
            forceDelete = true;
            delete args._forceDelete;
        }
        await ViewService.pageData(route, data, error, loading, view, {_action: view._action, _noAlert: view._noAlert, ...args}, cb);
        if (error && error.err && error.err.status === 409 && forceDelete) {
            await new Promise((resolve, reject) => {
                ElementService.confirm(error.page, i18n.t('Continue to force delete?') as string, {
                    confirmButtonText: i18n.t('Yes') as string,
                    cancelButtonText: i18n.t('No') as string,
                    type: 'error',
                }, async () => {
                    await ViewService.pageData(route, data, error, loading, view, {_action: view._action, _noAlert: view._noAlert, ...args, _forceDelete: true}, cb);
                    if (error && (!error.page && !error.all)) {
                        RouteService.removeQueryParam(route, ['_id', '_action']);
                        await listFunc(null);
                    }
                    resolve(null);
                }, () => {
                    resolve(null);
                });
            });
        } else {
            if (error && (!error.page && !error.all)) {
                RouteService.removeQueryParam(route, ['_id', '_action']);
                await listFunc(null);
            }
        }
    }

    static async download(params: any, options: any, cb: ( args: any) => any) {
        const responseType = options.type === 'pdf' ? 'application/pdf' : (options.type === 'txt' ? 'text/plain' : (options.type === 'png' ? 'image/png' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
        const contentType = 'application/json';
        const response = await cb({params: {...params, _action: options._action}, responseType: options._action === 'print' ? 'base64' : 'arraybuffer', headers: {'Content-type': contentType, 'Accept': responseType}});
        const name = options.fileName || 'file';
        let filename = name + (options.type === 'pdf' ? '.pdf' : (options.type === 'txt' ? '.txt' : (options.type === 'png' ? '.png' : '.xlsx')));
        const header = response && response.headers['content-disposition'];
        if (header) {
            const content = header.replace(/( |")/g, '').split(';');
            for (const vv of content) {
                const v = vv.split('=');
                if (v[0] === 'filename') {
                    filename = decodeURIComponent(v[1]);
                    break;
                }
            }
        }
        switch (options._action) {
            case 'print':
                await printJS({printable: response.data.slice('data:application/pdf;base64,'.length), type: options.type === 'pdf' ? 'pdf' : 'image', base64: true, showModal: true});
                break;
            case 'view':
                const blob = new Blob([response.data], {type: responseType});
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(blob, filename);
                } else {
                    const objectUrl = window.URL.createObjectURL(blob);
                    // Safari don allow _blank due to security
                    window.open(objectUrl, /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent) ? '_self' : '_blank');
                }
                break;
            default:
                ElementService.addDownloadLink(response.data instanceof Blob ? window.URL.createObjectURL(response.data) : window.URL.createObjectURL(new Blob([response.data], {type: responseType})), filename);
                break;
        }
    }

    static async importData(router: any, args: any) {
        const route = router.resolve({name: 'system-import'});
        if (route && route.resolved) {
            await RouteService.goto(router, {route: route.resolved, query: args});
        } else {
            //
        }
    }

    static checkExportLimit(tableState: any, view: any) {
        if (view && view.options && view.options.args && tableState && tableState.pagination && Number(tableState.pagination.count) > view.options.args.max) {
            return String(i18n.t(`Exceed the limit of %s. Filter list before exporting again.`)).replace('%s', view.options.args.max);
        } else {
            return null;
        }
    }

    // Export list data
    static async exportData(route: any, data: any, error: any, loading: any, params: any, cb: ( args: any) => any) {
        error = ViewService.resetViewError(error);
        if (!loading) { loading = {}; }
        try {
            loading.list = loading.page = true;
            if (data && data.list && data.list.view && data.list.view.tableState) {
                params.tableState = data.list.view.tableState;
            }
            const ret = await ViewService.download(params, {}, cb);
            loading.list = loading.page = false;
            return ret;
        } catch (err) {
            if (data && data.list) {
                error.list = ErrorService.getMessage(err);
            } else {
                error.all = ErrorService.getMessage(err);
            }
            loading.list = loading.page = false;
        }
    }

    // Export Page Data
    static async downloadData(route: any, data: any, error: any, loading: any, params: any, cb: ( args: any) => any) {
        error = ViewService.resetViewError(error);
        if (!loading) { loading = {}; }
        try {
            loading.page = true;
            const ret = await ViewService.download(params, {}, cb);
            loading.page = false;
            return ret;
        } catch (err) {
            if (data && data.page) {
                error.page = ErrorService.getMessage(err);
            } else {
                error.all = ErrorService.getMessage(err);
            }
            loading.page = false;
        }
    }

    static async downloadZipFiles(files: any, options: any, prefix: string) {
        try {
            if (files && LodashService.isArray(files) && files.length > 0) {
                const zip = new JSZip();
                let message: any = null;
                
                for (const [i, f] of files.entries()) {
                    if (message) message.close();
                    message = ElementService.toast(i18n.t('Downloading...') as string + ` (${i+1}/${files.length})`, {type: 'info'});
                    const resp = await FilesService.downloadFile(f, options);
                    const headers = resp && resp.headers ? (typeof resp.headers === 'function' ? resp.headers() : resp.headers) : null;
                    const disposition = headers ? headers['Content-Disposition'] || headers['content-disposition'] : '';
                    const serverFilename = disposition ? disposition.split('filename=')  : null;
                    const filename = serverFilename && serverFilename.length > 1 && serverFilename[1] ? serverFilename[1].replace(';', '') : prefix + (files.length > 1 ? `-${i + 1}` : '') + f.ext;
                    zip.file(filename, resp.data);
                }
                await new Promise((resolve, reject) => {
                    zip.generateAsync({type: 'blob'}, (metadata: any) => {
                        if (message) message.close();
                        if (metadata.percent === 0) {
                            message = ElementService.toast(i18n.t('Downloading...') as string, {type: 'info'});
                        } else if (metadata.percent === 100) {          
                            message = ElementService.toast(i18n.t('Downloaded') as string, {type: 'success'});
                        }
                    }).then((content: any) => {
                        saveAs(content, prefix + UtilService.formatDate(MomentService.getMomentNow(), {format: {format: '-YYYY-MM-DD-HH-mm-ss'}}) + '.zip');
                        resolve(null);
                    }, (err: any) => {
                        resolve(err);
                        ElementService.toast(ErrorService.getMessage(err), {type: 'error'});
                    })
                });
            }
        } catch (err) {
            ElementService.toast(ErrorService.getMessage(err), {type: 'error'});
        }
    }

    static async processSinglePage(path: string, view: any, messageKey: string, field: string, selected: any[], showProgress: any, start: number, length: number): Promise<{processed: any, error: any}> {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                let processed = 0;
                let error = 0;
                const upgradeProgress = (percent: number) => {
                    if (showProgress) { showProgress(percent < 100, percent); }
                };
                for (const [i, s] of selected.entries()) {
                    if (!s.message && !s._done && !view.closed) {
                        let args = {};
                        if (view && view.fields && !LodashService.isEmpty(view.fields)) {
                            if (typeof view.fields === 'function') {
                                args = view.fields(s);
                            } else {
                                args = LodashService.pickBy(s, (value, key) => LodashService.find(view.fields, (f) => f === key));
                            }
                        }
                        const data = {_action: view._action, _id: s._id, ...view.args, ...args};
                        try {
                            let r: any = null;
                            const method = view.apiMethod || (view._action === 'delete' ? 'delete' : 'put');
                            Vue.set(s, messageKey || 'message', view._action === 'delete' ? i18n.t('Deleting...') : i18n.t('Processing...'));
                            const urlObj: any = {url: view.apiPath || `${path}${s._id ? '/' + s._id : ''}`, method};
                            if (method === 'get') {
                                urlObj.params = data;
                            } else {
                                urlObj.data = data;
                            }
                            r = await request(urlObj);
                            if (r && r.value && field) { Vue.set(s, field, LodashService.get(r.value, field)); }
                            Vue.set(s, messageKey || 'message', view._action === 'delete' ? i18n.t('Deleted') : i18n.t('Successful!'));
                            s._done = true;
                            processed++;
                            if (processed && view && view.setChange) {
                                view.setChange(true);
                                delete view.setChange; // Only need to do once
                            }
                            upgradeProgress(((start + i + 1) / length) * 100);
                        } catch (err) {
                            Vue.set(s, messageKey || 'message', ErrorService.getMessage(err, {showFields: true}));
                            s._done = false;
                            error++;
                            upgradeProgress(((start + i + 1) / length) * 100);
                        }
                    }
                }
                if (error > 0) {
                    ElementService.alert(`${i18n.t('Success')}: ${processed}/${length}` + `, ${i18n.t('Failure')}: ${error}/${length}.<br/>${i18n.t('Please check the message column for more info.')}`, '', {type: 'error'}, () => {
                        resolve({processed, error});
                    }, () => {
                        resolve({processed, error});
                    })
                } else {
                    resolve({processed, error});
                }
            }, 1000); // Delay to give server some breathing space
        });
    }

    static async processData(path: string, val: any, item: any, view: any, selectedKey: string, messageKey: string, field: string, firstTime: boolean) {
        AppStore.SetDirty(false);
        const limit = 5000;
        const tableState = view.table ? view.table.getTableState() : null;
        const selected = val && val.length > 0 ? (selectedKey ? LodashService.filter(val, (v) => v[selectedKey]) : val) : [];
        const length = selectedKey || !tableState ? selected.length : (tableState.pagination ? Number(tableState.pagination.count) : selected.length);
        if (length > 0 && length <= limit) {
            const showProgress = view && view.table && view.table.setPercent ? (show: boolean, value: number) => view.table.setPercent(show, value) : null;
            let startNum = 0;
            const func = async (listItems: any) => {
                const {processed, error} = await ViewService.processSinglePage(path, view, messageKey, field, listItems, showProgress, startNum, length);
                if (!error && processed) {
                    if (selectedKey) {
                        if (!view.options || !view.options.noRefresh) {
                            await new Promise<void>(async (resolve, reject) => {
                                ElementService.confirm(i18n.t('Refresh?') as string, '', {
                                    confirmButtonText: i18n.t('Yes') as string,
                                    cancelButtonText: i18n.t('No') as string,
                                    type: 'success',
                                }, () => {
                                    if (view.table && view.table.searchFunc) { view.table.searchFunc(); }
                                    resolve();
                                }, () => {
                                    if (view.table && view.table.resetSelectedItems) { view.table.resetSelectedItems(); }
                                    if (view.table && view.table.resetMessage) { view.table.resetMessage(); }
                                    resolve();
                                }); // Close
                            });
                        }
                    } else {
                        if (view.table && (view.table.searchFunc || view.table.nextPageFunc)) {
                            const list = (!view.options || !view.options.noRefresh) ? await view.table.searchFunc() : await view.table.nextPageFunc();
                            if (list && list.length > 0) {
                                startNum = startNum + processed + error;
                                await func(list);
                            }
                        }
                    }
                // } else if (!error && selected && length > selected.length && view.table && view.table.searchFunc) {
                //     const list = await view.table.searchFunc();
                //     if (list && list.length > 0) {
                //         startNum = startNum + processed + error;
                //         await func(list);
                //     }
                }
            };
            if (!view.closed) {
                if (selectedKey || firstTime) {
                    await new Promise<void>(async (resolve, reject) => {
                        ElementService.confirm(
                            i18n.t('Total') as string + ': ' + length,
                            i18n.t(view.label) as string, {
                            confirmButtonText: i18n.t('OK') as string,
                            cancelButtonText: i18n.t('Close') as string,
                            type: 'info',
                        }, async () => {
                            await func(selected);
                            resolve();
                        }, () => {
                            resolve();
                        });
                    });
                } else {
                    await func(selected);
                }
            }
        } else {
            ElementService.toast(i18n.t('No Data Selected!') as string, {type: 'error'});
        }
    }

    static async processSinglePageDownloadPrint(path: string, view: any, messageKey: string, selected: any[], showProgress: any, zip: any, ty: string, start: number, length: number): Promise<{processed: any, error: any}> {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                let processed = 0;
                let error = 0;
                const upgradeProgress = (percent: number) => {
                    if (showProgress) { showProgress(percent < 100, percent); }
                };
                for (const [i, s] of selected.entries()) {
                    if (!s.message && !s._done && !view.closed) {
                        let args = {};
                        if (view && view.fields && view.fields.length > 0) {
                            args = LodashService.pickBy(s, (value, key) => LodashService.find(view.fields, (f) => f === key));
                        }
                        const data = {_action: view._action, _id: s._id, ...view.args, ...args};
                        try {
                            let resp: any = null;
                            const method = view.apiMethod || 'get';
                            Vue.set(s, messageKey || 'message', i18n.t('Downloading...'));
                            const urlObj: any = {url: view.apiPath || `${path}`, method, responseType: 'arraybuffer', 
                                headers: {'Content-type': 'application/pdf', 'Accept': 'application/pdf'}};
                            if (method === 'get') {
                                urlObj.params = data;
                            } else {
                                urlObj.data = data;
                            }
                            resp = await request(urlObj);
                            const headers = resp && resp.headers ? (typeof resp.headers === 'function' ? resp.headers() : resp.headers) : null;
                            const disposition = headers ? headers['Content-Disposition'] || headers['content-disposition'] : '';
                            const serverFilename = disposition ? disposition.split('filename=')  : null;
                            const filename = serverFilename && serverFilename.length > 1 && serverFilename[1] ? serverFilename[1].replace(';', '') : ty + (UserStore.account && UserStore.account.name ? '-' + UserStore.account.name.toLowerCase() : '') + '-' + (i ? '-' + i : '') + (urlObj.ext || '.pdf');
                            zip.file(filename, resp.data);
                            Vue.set(s, messageKey || 'message', i18n.t('Downloaded'));
                            s._done = true;
                            processed++;
                            upgradeProgress(((start + i + 1) / length) * 100);
                        } catch (err) {
                            Vue.set(s, messageKey || 'message', ErrorService.getMessage(err, {showFields: true}));
                            s._done = false;
                            error++;
                            upgradeProgress(((start + i + 1) / length) * 100);
                        }
                    }
                }
                if (error > 0) {
                    ElementService.alert(`${i18n.t('Success')}: ${processed}/${length}` + `, ${i18n.t('Failure')}: ${error}/${length}.<br/>${i18n.t('Please check the message column for more info.')}`, '', {type: 'error'}, () => {
                        resolve({processed, error});
                    }, () => {
                        resolve({processed, error});
                    });
                } else {
                    resolve({processed, error});
                }
            }, 1000); // Delay to give server some breathing space
        });
    }

    static async processDownloadPrint(path: string, val: any, item: any, view: any, selectedKey: string, messageKey: string, firstTime: boolean, zip: any, ty: string) {
        AppStore.SetDirty(false);
        const limit = 500;
        const tableState = view.table ? view.table.getTableState() : null;
        const selected = val && val.length > 0 ? (selectedKey ? LodashService.filter(val, (v) => v[selectedKey]) : val) : [];
        const length = selectedKey || !tableState ? selected.length : (tableState.pagination ? Number(tableState.pagination.count) : selected.length);
        if (length > 0 && length <= limit) {
            const showProgress = view && view.table && view.table.setPercent ? (show: boolean, value: number) => view.table.setPercent(show, value) : null;
            const downloadZip = async () => {
                await new Promise((resolve, reject) => {
                    let message: any = null;
                    zip.generateAsync({type: 'blob'}, (metadata: any) => {
                        if (message) message.close();
                        if (metadata.percent === 0) {
                            message = ElementService.toast(i18n.t('Downloading...') as string, {type: 'info'});
                        } else if (metadata.percent === 100) {          
                        message = ElementService.toast(i18n.t('Downloaded') as string, {type: 'success'});
                        }
                    }).then((content: any) => {
                        saveAs(content, ty + (UserStore.account && UserStore.account.name ? '-' + UserStore.account.name.toLowerCase() : '') + UtilService.formatDate(MomentService.getMomentNow(), {format: {format: '-YYYY-MM-DD-HH-mm-ss'}})  + '.zip');
                        resolve(null);
                    }, (err: any) => {
                        resolve(err);
                        ElementService.toast(ErrorService.getMessage(err), {type: 'error'});
                    });
                });
            }
            let startNum = 0;
            const func = async (listItems: any, zip: any) => {
                const {processed, error} = await ViewService.processSinglePageDownloadPrint(path, view, messageKey, listItems, showProgress, zip, ty, startNum, length);
                if (!error && processed) {
                    if (selectedKey) {
                        await downloadZip();
                    } else {
                        if (view.table && view.table.nextPageFunc) {
                            const list = await view.table.nextPageFunc();
                            if (list && list.length > 0) {
                                startNum = startNum + processed + error;
                                await func(list, zip);
                            } else {
                                await downloadZip();
                            }
                        } else {
                            await downloadZip();
                        }
                    }
                }
            };
            if (!view.closed) {
                if (!zip) { zip = new JSZip(); }
                if (selectedKey || firstTime) {
                    await new Promise<void>(async (resolve, reject) => {
                        ElementService.confirm(
                            i18n.t('Total') as string + ': ' + length,
                            i18n.t(view.label) as string, {
                            confirmButtonText: i18n.t('OK') as string,
                            cancelButtonText: i18n.t('Close') as string,
                            type: 'info',
                        }, async () => {
                            await func(selected, zip);
                            resolve();
                        }, () => {
                            resolve();
                        });
                    });
                } else {
                    await func(selected, zip);
                }
            }
        } else {
            if (length > limit) {
                ElementService.toast(String(i18n.t('Cannot download more than %s!')).replace('%s', String(limit)), {type: 'error'});
            } else {
                ElementService.toast(i18n.t('No Data Selected!') as string, {type: 'error'});
            }
        }
    }

    static checkSelected(val: any, view: any, selectedKey: string) {
        const list = view && view.table ? view.table.getSelectedItems() : [];
        if (!LodashService.isEmpty(list) || !!LodashService.find(val, (v) => v[selectedKey])) { // Use cumulative selection if specified.
            ElementService.toast(i18n.t('Unselect All Selected Data First!') as string, {type: 'error'});
            return true;
        }
        return false;
    }

    static showPopup(obj: any, popup: string, ui: any) {
        obj[popup] = null;
        setTimeout(() => { obj[popup] = ui; }, 0);
    }

    static formatFileExists(val: any, item: any) {
        return !LodashService.isEmpty(val) ? '<i class="fa fa-paperclip"></i>' : '';
    }

    // Priority: route params (hight), view args (medium), route meta args (low)
    static getArgs(route: any, view: any) {
        if (!route.meta) { route.meta = {}; }
        if (!view) { view = {}; }
        return {...route.meta.args, ...view.args, ...route.params};
    }

    static async refreshSystem(path: string, force: boolean) {
        const pathnames = path.split('/');
        const p = pathnames && pathnames.length > 1 ? `/${pathnames[1]}` : `/`;
        if (force || !MenuStore.system || (p !== ('/' + MenuStore.system))) {
            await UserStore.getUser(p);
        }
    }

    static getReloadKey() {
        return String(MomentService.getNow().getTime());
    }

    static loadLayout(name: string) { // name is xxx-xxx
        if (name) {
            const ret = name.split('-').map(LodashService.capitalize).join('');
            // @ts-ignore
            if (!Vue.options.components[ret]) {
                // https://github.com/webpack/webpack/issues/2401  webpack fails with sub-directory
                const requestPath: string = `${name}/${name}` ; // TODO optimize chunk
                Vue.component(ret, () => import(`@/layouts/${requestPath}.vue`));
            }
            return ret;
        } else {
            return '';
        }

    }

    static async confirmResetDetails(details: any, okCb: any, cancelCb: any) {
        let empty = true;
        for (const d of details) {
            if (d && d.name) {
                empty = false;
                break;
            }
        }
        if (empty) {
            await okCb();
        } else {
            ElementService.confirm(
                i18n.t('Reset details?') as string,
                '', {
                confirmButtonText: i18n.t('Yes') as string,
                cancelButtonText: i18n.t('No') as string,
                type: 'warning',
            }, async () => { await okCb(); }, async () => { await cancelCb(); } );
        }
    }

    static setTabSearch(view: any, args: any) {
        let tab;
        if (view && view.tabs && view.tabs.model) {
            if (!args.search) { args.search = {}; }
            tab = LodashService.find(view.tabs.values, (t) => t.name === view.tabs.name);
            if (tab && tab.value !== null) { args.search[view.tabs.model] = tab.value; } else { delete args.search[view.tabs.model]; }
        }
        return tab;
    }

    static resetTab(view: any, args: any) {
        if (view.tabs && view.tabs.values && view.tabs.values.length > 0) {
            const value = args.search && args.search.hasOwnProperty(view.tabs.model) ? args.search[view.tabs.model] : view.tabs.default;
            const tab = LodashService.find(view.tabs.values, (t) => t.value === value);
            if (tab) {
                if (!view.args) { view.args = {}; }
                if (!view.args.search) { view.args.search = {}; }
                if (tab.value !== null) { view.args.search[view.tabs.model] = tab.value; } else { delete view.args.search[view.tabs.model] ; }
                view.tabs.name = tab.name;
            }
        }
    }

    static showLoading(text?: string) {
        return Loading.service({
            lock: true,
            text: text || 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });
    }

    // Only use ID that exists in the list
    static checkListParamId(params: any, list: any[]) {
        return params && params._id ? !!LodashService.find(list, (obj) => obj && ((obj._id || obj.id) === params._id)) : false;
    }

    // Get the data -> list -> value
    static getListValue(data: any) {
        return data && data.list && data.list.value ? data.list.value : [];
    }

    static async viewByAction(obj: any, action: string) {
        let view = false;
        if (action) {
            const params = (obj.$route.params && obj.$route.params._action) ? obj.$route.params : {_id: RouteService.getQueryParam('id') || RouteService.getQueryParam('_id')};
            if (action === 'read') {
                if (ViewService.checkListParamId(params, ViewService.getListValue(obj.data))) {
                    await obj.viewData({...params, _action: action}, {}, {});
                    view = true;
                }
            } else if (action !== 'add') {
                if (ViewService.checkListParamId(params, ViewService.getListValue(obj.data))) {
                    await obj.editData({...params, _action: action}, {}, {});
                    view = true;
                }
            } else {
                await obj.addData({...params, _action: action}, {}, {});
                view = true;
            }
        }
        return view;
    }

    static addDefaultRow(v: any, item: any, view: any) {
        let rows = v || [];
        if (view && view.args && view.args.defaultRow) {
            rows.push(UtilService.setUniqueId(UtilService.cloneObject(view.args.defaultRow)));
        }
        if (view && view.model) {
            Vue.set(item, view.model, rows);
        } else {
            ElementService.toast(i18n.t('Error!') as string, {type: 'error'});
        }
    }

}
