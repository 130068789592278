<template>
    <el-dropdown class="elem-lang international" @command="handleSetLanguage">
<!--        <svg-icon name="language" class="international-icon" ></svg-icon>-->
        <span class="el-dropdown-link">
            <span v-if="options.label">{{$t(options.label)}}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <svg-icon v-else name="language" class="international-icon" ></svg-icon>
        </span>
        <el-dropdown-menu slot="dropdown" style="margin-top:0">
            <el-dropdown-item v-if="languages.indexOf('ms') >= 0" :disabled="language==='ms'" command="ms">
                Bahasa Malaysia
            </el-dropdown-item>
            <el-dropdown-item v-if="languages.indexOf('id') >= 0" :disabled="language==='id'" command="id">
                Bahasa Indonesia
            </el-dropdown-item>
            <el-dropdown-item v-if="languages.indexOf('zh') >= 0" :disabled="language==='zh'" command="zh">
                中文
            </el-dropdown-item>
            <el-dropdown-item v-if="languages.indexOf('en') >= 0" :disabled="language==='en'" command="en">
                English
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script lang="ts">
    import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
    import i18n, { setMessage } from '@/lang';
    import i18nLang from '@/lang/component/elem-lang';
    import { UserStore } from '@/store/user.store';
    import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
    import '@/icons/components/language';
    import ErrorService from '@/services/common/error.service';
    import ElementService from '@/services/common/element.service';

    Vue.component(Dropdown.name, Dropdown);
    Vue.component(DropdownItem.name, DropdownItem);
    Vue.component(DropdownMenu.name, DropdownMenu);

    @Component
    export default class ElemLang extends Vue {

        @Prop() readonly view!: any;
        @Prop() readonly functions: any;
        @PropSync('value') data!: any;
        private languages: string[] = UserStore.sysLang || ['en'];
        private defaultOptions: any = {
            label: '',
        };

        get language() {
            return UserStore.language;
        }

        get options() {
            return ElementService.getOptions(this);
        }

        private async handleSetLanguage(lang: string) {
            try {
                await UserStore.SetLanguage(lang);
                ElementService.toast(i18n.t('Change Language Successful!') as string, {type: 'success'});
                if (this.view && this.view.changeFunc && this.functions && this.functions[this.view.changeFunc]) {
                    await this.functions[this.view.changeFunc](lang, this.data, this.view);
                }
            } catch (err) {
                ElementService.toast(ErrorService.getMessage(err), {type: 'error'});
            }
        }

        created() {
            setMessage(i18nLang);
        }

    }
</script>

<style lang="scss" scoped>

    .elem-lang {
        color: inherit;
    }

    .el-dropdown {
        font-size: 25px;
    }

    .el-dropdown-menu {

        .el-dropdown-menu__item {
            line-height: 28px;
        }

        i.el-icon-warning {
            color: red;
        }
    }

</style>
