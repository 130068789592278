import i18n from '@/lang';
import UtilService from '@/services/common/util.service';

export default class ErrorService extends Error {
    status: any;
    i18n: boolean = false;
    fieldErrors: any;

    constructor(status: any, message: string, translate: boolean, fieldErrors: any) {
        super(message);
        this.status = status;
        this.i18n = translate;
        this.fieldErrors = fieldErrors;
    }

    static getMessage(err: any, options?: any) {
        let message = '';
        if (err) {
            if (typeof err === 'string') {
                message = err;
            } else {
                err.data = err._body ? UtilService.parseJson(err._body, false) : err.data;
                message = err.data && err.data.message ? (err.data.i18n ? i18n.t(err.data.message) : err.data.message) : (err.i18n ? i18n.t(err.message) : err.message);
                if (message) {
                    const fieldErrors = err.data && err.data.fieldErrors && err.data.fieldErrors.length > 0 ? err.data.fieldErrors : err.fieldErrors;
                    if (fieldErrors && fieldErrors.length > 0) {
                        const errors = [];
                        for (const e of fieldErrors) {
                            if (!e.field || (options && options.showFields)) {
                                errors.push((e.label ? i18n.t(e.label) + ': ' : '') + (e.i18n ? i18n.t(e.message) : e.message));
                            }
                        }
                        if (errors.length > 0) {
                            message = (message ? message + ' ' : '') + errors.join(', ');
                        }
                    }
                } else {
                    message = (err.statusText ? (' ' + err.statusText) : '') + (err.status && err.status > 0 ? ' (' + String(err.status) + ')' : '');
                    message = 'Internet or Server Error!' + message;
                }
                if (message === '') {
                    message = 'Error!';
                }
            }
        }
        return(message);
    }

    static getFieldMessages(err: any) {
        if (err && err && err.fieldErrors && Object.keys(err.fieldErrors).length > 0) {
            return(err.fieldErrors);
        } else {
            return [];
        }
    }

}
