<template>
	<elem-scroll class="change-password-page align-vertical" :class="{['background-color-'+sysType]:true}">
		<el-form v-if="!gotVerification" class="form-wrapper align-vertical-middle" ref="getCodeForm" :model="getCodeForm" :rules="getCodeRules" autocomplete="off">
			<div class="form-box card-base card-shadow--extraLarge">
				<h2>{{$t('First Time User/Reset Password')}}</h2>
				<el-form-item prop="displayName">
					<float-label class="styled" :label="$t('Short Name')">
						<input type="text" v-model.trim="getCodeForm.displayName" :placeholder="$t('Short Name')">
					</float-label>
				</el-form-item>
				<el-form-item prop="verificationKey">
					<float-label class="styled" :label="verificationInputType === 'email' ? $t('Email') : $t('Mobile Number')">
						<input :type="verificationInputType" v-model.trim="getCodeForm.verificationKey" :placeholder="verificationInputType === 'email' ? $t('Email') : $t('Mobile Number')">
					</float-label>
				</el-form-item>
				<el-alert v-if="error" :title="error" type="error" :closable="false" show-icon></el-alert>
				<div class="flex text-center center pt-20 pb-10">
					<el-button plain size="small" :loading="loading" @click.native.prevent="handleGetVerification" class="send-btn tada animated">
						{{$t('Get Verification Code')}}
					</el-button>
				</div>

			</div>
		</el-form>
		<el-form v-else class="form-wrapper align-vertical-middle" ref="changePasswordForm" :model="changePasswordForm" :rules="changePasswordRules" autocomplete="off">
			<div class="form-box card-base card-shadow--extraLarge">
				<h2>{{$t('First Time User/Reset Password')}}</h2>
				<el-form-item prop="code">
					<float-label class="styled" :label="$t('Verification Code')">
						<input type="text" v-model.trim="changePasswordForm.code" :placeholder="$t('Verification Code')">
					</float-label>
				</el-form-item>
				<el-form-item prop="newPassword">
					<float-label class="styled" :label="$t('Password')">
						<input :type="passwordType" ref="password" v-model="changePasswordForm.newPassword" :placeholder="$t('Password')" />
					</float-label>
					<span class="show-pwd" @click.stop="showPwd">
						<svg-icon :name="passwordType === 'password' ? 'eye-off' : 'eye-on'"></svg-icon>
       		 		</span>
				</el-form-item>
				<el-form-item prop="verifyPassword">
					<float-label class="styled" :label="$t('Confirm Password')">
						<input :type="passwordType" ref="password" v-model="changePasswordForm.verifyPassword" :placeholder="$t('Confirm Password')" @keyup.enter.native="handleChangePassword"/>
					</float-label>
				</el-form-item>
				<el-alert v-if="error" :title="error" type="error" :closable="false" show-icon></el-alert>
				<div class="flex text-center center pt-20 pb-10">
					<el-button plain size="small" :loading="loading" @click.native.prevent="handleChangePassword" class="send-btn tada animated">
						{{$t('Submit')}}
					</el-button>
				</div>
			</div>
		</el-form>>
	</elem-scroll>
</template>

<script lang="ts">
	import request from '@/services/common/request.service';
	import { getAppType } from '../../services/common/cookies.service';
	import { Vue, Component } from 'vue-property-decorator';
	import i18n, { setMessage } from '@/lang';
	import i18nPassword from '@/lang/common/password';
	import i18nLogin from '@/lang/common/login';
	import VueFloatLabel from 'vue-float-label';
	import { Button, Input, Form, Message } from 'element-ui';
	import ElemScroll from '@/views/component/elem-scroll.vue';
	import { UserStore } from '@/store/user.store';
	import ValidationService from '@/services/common/validation.service';
	import ViewService from '@/services/common/view.service';
	import RouteService from '@/services/common/route.service';
	import ErrorService from '@/services/common/error.service';
	import ElementService from '@/services/common/element.service';

	Vue.component(Button.name, Button);
	Vue.component('elem-scroll', ElemScroll);
	Vue.use(VueFloatLabel);

	@Component
	export default class ChangePassword extends Vue {

		private verificationInputType: string = UserStore.verificationType === 'mobile' ? 'tel' : 'email';
		private gotVerification: boolean = false;
		private passwordType = 'password';
		private loading = false;
		private error: string = '';
		private validator: ValidationService = new ValidationService();
		private sysType = UserStore.sysType || '';
		private retry = 0;

		private getCodeForm: any = {
			displayName: '',
			verificationKey: '',
			appType: getAppType() || 'Staff',
			countryCode: UserStore.countryCode,
		};

		private changePasswordForm: any = {
			code: '',
			newPassword: '',
			verifyPassword: '',
			appType: getAppType() || 'Staff',
			countryCode: UserStore.countryCode,
		};

		private validateConfirmPassword(rule: any, value: string, callback: any) {
			if (value !== this.changePasswordForm.newPassword) {
				callback(new Error(i18n.t('Must confirm password.') as string));
			} else {
				callback();
			}
		}

		private getCodeRules = {
			displayName: [{ validator: this.validator.validateRequired, trigger: 'blur' }],
			verificationKey: [{ validator: this.validator.validateRequired, trigger: 'blur' }],
		};

		private changePasswordRules = {
			code: [{ validator: this.validator.validateRequired, trigger: 'blur' }],
			newPassword: [{ validator: this.validator.validatePassword, trigger: 'blur' }],
			verifyPassword: [{ validator: this.validateConfirmPassword, trigger: 'blur' }],
		};

		private showPwd() {
			if (this.passwordType === 'password') {
				this.passwordType = 'text';
			} else {
				this.passwordType = 'password';
			}
			this.$nextTick(() => {
				(this.$refs.password as Input).focus();
			});
		}

		private handleGetVerification() {
			this.error = '';
			(this.$refs.getCodeForm as Form).validate(async (valid: boolean) => {
				if (valid) {
					try {
						this.loading = true;
						this.getCodeForm.verificationType = UserStore.verificationType;
						const resetCode = RouteService.getQueryParam('reload') || ''; // reload means failed before, so change code
						await request({url: `/api/auth/get_code`, method: 'post', params: {resetCode}, data: this.getCodeForm});
						this.gotVerification = true;
						this.loading = false;
					} catch (err) {
						this.error = err ? (err as any).message : 'Error!';
						this.loading = false;
					}
				} else {
					return false;
				}
			});
		}

		private handleChangePassword() {
			this.error = '';
			(this.$refs.changePasswordForm as Form).validate(async (valid: boolean) => {
				if (valid) {
					try {
						this.loading = true;
						this.changePasswordForm.verificationType = UserStore.verificationType,
						this.changePasswordForm.verificationKey = this.getCodeForm.verificationKey;
						this.changePasswordForm.displayName = this.getCodeForm.displayName;
						const ret = await request({url: `/api/auth/verify_code`, method: 'post', data: this.changePasswordForm});
						await UserStore.Login(ret as any);
						this.$router.push({path: '/'});
						this.loading = false;
					} catch (err) {
						this.loading = false;
						if (err && (err as any).message === 'Invalid verification code!') {
							this.retry++;
							if (this.retry > 10) {
								ElementService.toast(i18n.t('You have tried too many times. Please start again.') as string, {type: 'error'});
								this.$router.replace({name: 'change-password', query: {reload: ViewService.getReloadKey()}});
							} else {
								this.error = err ? ErrorService.getMessage(err) : 'Error!';
							}
						} else {
							this.error = err ? ErrorService.getMessage(err) : 'Error!';
						}
					}
				} else {
					return false;
				}
			});
		}

		created() {
			setMessage(i18nPassword);
			setMessage(i18nLogin);
		}

	}
</script>

<style lang="scss">
	@import '../../layouts/system-layout/scss/_variables';

	.change-password-page {
		background: $text-color;
		margin-left: -30px;
		margin-right: -30px;

		&.background-color-masjid {
			background-color: $background-color-masjid;
		}

		.el-form-item {
			margin: 0 0 10px 0;
		}

		.el-form-item__content {
			line-height: unset;
		}

		.el-form-item__error {
			top: 70%;
		}

		.show-pwd {
			position: absolute;
			right: 0;
			top: 7px;
			font-size: 16px;
			color: darken($background-color, 25%);
			cursor: pointer;
			user-select: none;
		}

		.form-wrapper {
			width: 100%;
		}

		.form-box {
			width: 100%;
			max-width: 400px;
			padding: 30px;
			box-sizing: border-box;
			margin: 20px auto;

			.vfl-has-label.styled {
				max-width: 100%;
			}

            a {
                font-size: 14px;
                color: $text-color-accent;
                text-decoration: none;
                font-weight: 500;
            }

            .send-btn {
                width: 160px;
            }
        }
    }

    @media (max-width: 768px) {
        .layout-container .container .view.forgot-password-page {
            margin-left: -5px;
            margin-right: -5px;
            max-width: calc(100% + 10px);
        }
    }
</style>
