<template>
	<Layout :name="name" :site="site">
		<router-view class="view box grow" />
	</Layout>
</template>

<script lang="ts">
	import { Vue, Component } from 'vue-property-decorator';
	import Layout from '../../layouts/layout';
	import { setMessage } from '@/lang';
	import i18nAll from '@/lang/system/all';

	@Component({
		components: {
			Layout,
		},
	})
	export default class System extends Vue {
		name!: string;
		site: any = {};

		created() {
			setMessage(i18nAll);
			this.name = 'system-layout';
		}

	}
</script>

