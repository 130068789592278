import { RouteConfig } from 'vue-router';
import System from '../views/system/system.vue';
import Login from '../views/common/login.vue';
import ChangePassword from '../views/common/change-password.vue';
import SystemStatic from '../views/system/system-static.vue';
import ErrPage from '../views/common/err-page.vue';
import layoutConfig from '../layouts/layout-config';

const commonRoutes: RouteConfig[] = [{
	path: '/authentication',
	name: 'authentication',
	component: System,
	meta: {
	},
	children: [
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: {
				title: 'Login',
				layout: layoutConfig.contentOnly,
			},
		}, {
			path: '/change-password',
			name: 'change-password',
			component: ChangePassword,
			meta: {
				title: 'Change Password',
				layout: layoutConfig.contentOnly,
				public: true,
			},
		},
	]}, {
		name: 'system-terms',
		path: '/system/terms/view',
		component: SystemStatic,
		meta: {
			title: 'Terms of Service',
			menu: false,
			public: true,
			layout: 'navTop',
			apiPath: '/api/static',
			args: {type: 'terms'},
		},
	}, {
		name: 'system-privacy',
		path: '/system/privacy-policy/view',
		component: SystemStatic,
		meta: {
			title: 'Privacy Policy',
			menu: false,
			public: true,
			layout: 'navTop',
			apiPath: '/api/static',
			args: {type: 'privacy'},
		},
	}, {
		path: '*',
		name: 'err-page',
		component: ErrPage,
		meta: {
			// title: 'Error',
			layout: layoutConfig.contentOnly,
		},
	},
];

export default commonRoutes;

