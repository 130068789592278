<template>
    <section class="ps-container" :is="tagName"
             @mouseover.once="update"
             @ps-scroll-y="scrollHandle"
             @ps-scroll-x="scrollHandle"
             @ps-scroll-up="scrollHandle"
             @ps-scroll-down="scrollHandle"
             @ps-scroll-left="scrollHandle"
             @ps-scroll-right="scrollHandle"
             @ps-y-reach-start="scrollHandle"
             @ps-y-reach-end="scrollHandle"
             @ps-x-reach-start="scrollHandle"
             @ps-x-reach-end="scrollHandle">
        <slot />
    </section>
</template>

<script lang="ts">
    import PerfectScrollbar from 'perfect-scrollbar';
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class ElemScroll extends Vue {

        @Prop({default: true})
        switcher!: boolean;

        @Prop({default: 'section'})
        tagName!: string;

        @Prop({default: () => ({
                wheelSpeed: 1.5,
                wheelPropagation: true,
            }),
        })
        settings!: any;

        ps: any = null;
        psInitiated: boolean = false;

        scrollHandle(evt: any) {
            this.$emit(evt.type, evt);
        }

        update() {
            if (this.ps) {
                this.ps.update();
            }
        }

        init() {
            this.$nextTick(() => {
                if (this.switcher) {
                    if (!this.psInitiated) {
                        this.psInitiated = true;
                        this.ps = new PerfectScrollbar(this.$el as HTMLElement, this.settings);
                    } else {
                        this.update();
                    }
                }
            });
        }

        unInit() {
            if (this.ps) {
                this.ps.destroy(this.$el);
            }
            this.ps = null;
            this.psInitiated = false;
        }

        @Watch('switcher')
        onSwitcher(val: boolean) {
            if (val && !this.psInitiated) {
                this.init();
            }
            if (!val && this.psInitiated) {
                this.unInit();
            }
        }

        @Watch('$route')
        onRoute() {
            this.update();
        }

        mounted() {
            this.init();
        }

        updated() {
            this.$nextTick(this.update);
        }

        activated() {
            this.init();
        }

        deactivated() {
            this.unInit();
        }

        beforeDestroy() {
            this.unInit();
        }
    }
</script>

<style lang="scss">
    @import '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';
    .ps-container {
        position: relative;
    }
</style>
