import i18n from '@/lang';
import UtilService from './util.service';
import LodashService from './lodash.service';
import MomentService from './moment.service';

export default class ValidationService {

    validatePassword(rule: any, value: string, callback: any) {
        if (!value || value.length < 6) {
            callback(new Error(i18n.t('Must not be less than 6 digits.') as string)); //
        } else {
            callback();
        }
    }

    validateRequired(rule: any, value: string, callback: any) {
        if (!value || !(value.trim())) {
            callback(new Error(rule.message || i18n.t('Please input data.') as string));
        } else {
            callback();
        }
    }

    validateFormat(rule: any, value: string, callback: any) {

        if (value) {
            switch (rule.format) {
                case 'url':
                    const pattern = /^((http|https):\/\/)/;
                    if (!pattern.test(String(value).trim().toLowerCase())) {
                        callback(new Error(rule.message || i18n.t('Please input URL.') as string));
                    } else {
                        callback();
                    }
                    break;
                case 'number':
                    if (value && !(/^\d+$/.test(value))) {
                        callback(new Error(rule.message || i18n.t('Invalid Number!') as string));
                    } else {
                        callback();
                    }
                    break;
                case 'amount':
                    if (value && !(/^[\d.]+$/.test(value))) {
                        callback(new Error(rule.message || i18n.t('Invalid Amount!') as string));
                    } else {
                        callback();
                    }
                    break;
                // case 'email': // type: email is provided by default in elementui
                case 'mobile':
                    if (value && !(/^[\s\d+-]+$/.test(value))) { // TODO improve phone number checking
                        callback(new Error(rule.message || i18n.t('Invalid mobile phone number.') as string));
                    } else {
                        callback();
                    }
                    break;
                case 'phone':
                    if (value && !(/^[\s\d+-]+$/.test(value))) { // TODO improve phone number checking
                        callback(new Error(rule.message || i18n.t('Invalid phone number.') as string));
                    } else {
                        callback();
                    }
                    break;
                case 'email':
                    if (value && !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) { // TODO improve phone number checking
                        callback(new Error(rule.message || i18n.t('Invalid email address.') as string));
                    } else {
                        callback();
                    }
                    break;
                default:
                    callback(new Error(i18n.t('Unknown format!') as string));
                    break;
            }
        } else {
            callback();
        }
    }

    validateStartEnd(rule: any, value: any, cb: any) {
        let err = '';
        if (value && rule.args && rule.item && rule.args.model) {
            const value1 = UtilService.getId(value);
            const value2 = UtilService.getId(LodashService.get(rule.item, rule.args.model));
            if (!LodashService.isEmpty(value1) && !LodashService.isEmpty(value2)) {
                const v1: any = rule.args.type === 'date' ? MomentService.getMoment(value1, '') : Number(value1);
                const v2: any = rule.args.type === 'date' ? MomentService.getMoment(value2, '') : Number(value2);
                let diff = 0;
                if (rule.args.type === 'date') {
                    diff = MomentService.getDiff(v2, v1, 'd');
                } else {
                    diff = v1 - v2
                }
                if (rule.args.greater) {
                    if (diff < 0) {
                        err = rule.message || 'Error!';
                    }
                } else {
                    if (diff > 0) {
                        err = rule.message || 'Error!';
                    }
                }
            }
        }
        if (err) {
            cb(err);
        } else {
            cb();
        }
    }

    validatePartial(rule: any, value: any, cb: any) {
        let err = '';
        if (rule.args && rule.item && rule.args.model) {
            let found = false;
            for (const m of rule.args.model) {
                if (!LodashService.isEmpty(UtilService.getId(LodashService.get(rule.item, m)))) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                err = rule.message || 'Error!';
            }
        }
        if (err) {
            cb(err);
        } else {
            cb();
        }
    }

}
