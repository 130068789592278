
import { UserStore } from '@/store/user.store';
import moment, {Moment} from 'moment-timezone';
import LodashService from './lodash.service';
import UtilService from './util.service';

class MomentService {

    static initLocale(lang: string) {
        moment.locale(UtilService.getLocale(lang));
    }

    static initTimeZone(timeZone: string) {
        if (timeZone) { moment.tz.setDefault(timeZone); }
    }

    static isValid(d: Moment) {
        return d ? d.isValid() : false;
    }

    static convertTimeZone(m: Moment) {
        if (UserStore && UserStore.timeZone) {
            if (m.tz() !== UserStore.timeZone) {
                m = m.tz(UserStore.timeZone);
            }
        }
        return m;
    }

    static getMoment(date: (string | Date | Moment | number[]), format: string): Moment {
        let m;
        if (date && (moment.isMoment(date) || moment.isDate(date))) {
            m = moment(date);
        } else if (date && LodashService.isString(date)) {
            if (date.toLowerCase().indexOf('t') >= 0) {
                m = moment(date);
            } else {
                m = moment.tz(date, format, UserStore ? UserStore.timeZone : '');
            }
        } else if (date && LodashService.isArray(date)) {
            m = moment.tz(date, UserStore ? UserStore.timeZone : '');
        } else {
            m = moment();
        }
        return MomentService.convertTimeZone(m);
    }

    static isBefore(d1: any, d2: any) {
        return MomentService.getMoment(d1, '').isBefore(MomentService.getMoment(d2, ''));
    }

    static isSameOrBefore(d1: any, d2: any) {
        return MomentService.getMoment(d1, '').isSameOrBefore(MomentService.getMoment(d2, ''));
    }

    static isAfter(d1: any, d2: any) {
        return MomentService.getMoment(d1, '').isAfter(MomentService.getMoment(d2, ''));
    }

    static isSameOrAfter(d1: any, d2: any) {
        return MomentService.getMoment(d1, '').isSameOrAfter(MomentService.getMoment(d2, ''));
    }

    static getNow(): Date {
        return this.getMomentNow().toDate();
    }

    static getMomentNow(): Moment {
        return MomentService.convertTimeZone(moment());
    }

    static setDateWithTime(date: (string | Date | Moment), time: (string | Date | Moment)): (Date) {
        let d = MomentService.getMoment(date, '');
        const t = time ? (LodashService.isString(time) && time.length < 6 ? time : MomentService.getMoment(time, '').format('HH:mm:00')) : null;
        if (t) {
            d = moment.tz(d.format('YYYY-MM-DD ') + t, UserStore ? UserStore.timeZone : '');
        } else {
            d = moment.tz(d.format('YYYY-MM-DD 00:00:00'), UserStore ? UserStore.timeZone : '');
        }
        return d.toDate();
    }

    static getISODateString(date: (string | Date | Moment)): string {
        const s = date ? MomentService.getMoment(date, '') : null;
        return s ? s.toISOString() : '';
    }

    // Convert browser time zone to user timezone without changing the value
    static converToUserTimeZone(date: Date | string) {
        return date ? MomentService.getISODateString(MomentService.getMoment(date, '')) : date;
    }

    // Convert user time zone to device timezone without changing the value
    static converToDeviceTimeZone(date: Date | string, dateOnly: boolean) {
        const d = moment.isDate(date) ? date : new Date(date);
        return date ? MomentService.getISODateString(new Date(d.getFullYear(), d.getMonth(), d.getDate(), dateOnly ? 0 : d.getHours(), dateOnly ? 0 : d.getMinutes(), dateOnly ? 0 : d.getSeconds())) : date;
    }

    static format(date: Date | Moment, format: string) {
        const m = moment.isMoment(date) ? date : MomentService.convertTimeZone(moment(date));
        return m.format(format);
    }

    static formatRange(startDate: Date | string, endDate: Date | string | null, dayFormat: string, timeFormat: string) {
        if (startDate) {
            startDate = startDate && LodashService.isString(startDate) ? MomentService.getMoment(startDate, '').toDate() : startDate as Date;
            endDate = endDate && LodashService.isString(endDate) ? MomentService.getMoment(endDate, '').toDate() : endDate as Date;
            const sameMonth = !endDate || (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth());
            const sameDay = !endDate || (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth() && startDate.getDate() === endDate.getDate());
            if (!timeFormat) {
                if (sameDay) {
                    return MomentService.format(startDate, dayFormat);
                } else if (sameMonth) {
                    return MomentService.format(startDate, 'DD') + ' ~ ' + MomentService.format(endDate, dayFormat);
                } else {
                    return MomentService.format(startDate, dayFormat) + ' ~ ' + MomentService.format(endDate, dayFormat);
                }
            } else {
                const sameTime = !endDate || (startDate.getHours() === endDate.getHours() && startDate.getMinutes() === endDate.getMinutes());
                const startMidNite = startDate && startDate.getHours() === 0 && startDate.getMinutes() === 0;
                const endMidNite = endDate && endDate.getHours() === 0 && endDate.getMinutes() === 0;
                if (sameDay && sameTime) {
                    return MomentService.format(startDate, !startMidNite ? (dayFormat + ' ' + timeFormat) : dayFormat);
                } else if (sameDay && !sameTime) {
                    return MomentService.format(startDate, dayFormat + ' ' + timeFormat) + ' ~ ' + MomentService.format(endDate, timeFormat);
                } else {
                    return MomentService.format(startDate, dayFormat + (!startMidNite ? ' ' + timeFormat : '')) + ' ~ ' + MomentService.format(endDate, dayFormat + (!endMidNite ? ' ' + timeFormat : ''));
                }
            }
        } else {
            return '';
        }
    }

    static getCenturyStartDate() {
        return MomentService.getMoment([2000, 0, 1, 0, 0, 0, 0], '');
    }

    // 1 - Sun
    static getDayNameFromNumber(dayNum: number) {
        return moment().isoWeekday(dayNum).format('ddd');
    }

    static getDay(d?: Date | string | null | number[] | Moment): number {
        return MomentService.getMoment(d || MomentService.getMomentNow(), '').isoWeekday() - 1;  // Start at 1 for sunday
    }

    static getDiff(d1: (string | Date | Moment), d2: (string | Date | Moment), type: string) {
        // TODO use duration method above
        const duration = moment.duration(MomentService.getMoment(d2, '').diff(MomentService.getMoment(d1, '')));
        switch (type) {
            case 'd':
                return duration.asDays();
            case 'm':
                return duration.asMinutes();
            case 'h':
            default:
                return duration.asHours();
        }
    }

}
export default MomentService;
