<template>
    <component :is="layout" :site="site" :functions="functions">
        <router-view :layout.sync="layout" :site.sync="site" :functions.sync="functions" :key="appVersion+$route.fullPath" />
    </component>
</template>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import { getWebVersion } from '@/services/common/cookies.service';
    import ViewService from '@/services/common/view.service';

    @Component
    export default class App extends Vue {
        layout: string = '';
        site: any = {};
        functions: any = {};
        appVersion = getWebVersion() || '';

        created() {
            this.layout = ViewService.loadLayout(ViewService.getMenuLayout(this.$route) || 'system-layout');
        }

  }

</script>
