<template>
	<elem-scroll class="login-page" :class="{'align-vertical':!!sysName, ['background-color-'+sysType]:true}">
		<div class="m-10" v-if="!sysName"><el-alert :title="'Failed!'" type="error" :closable="false" show-icon :description="'Failed to connect to server!'"></el-alert></div>
		<el-form class="form-wrapper align-vertical-middle" ref="loginForm" :model="loginForm" :rules="loginRules">
			<div v-if="sysName" class="form-box card-base card-shadow--extraLarge">
				<h1 class="text-center title">{{sysName ? sysName : $t('Login')}}</h1>
				<el-form-item prop="username">
					<float-label class="styled" :label="usernameType === 'email' ? $t('Email') : $t('Mobile Number')">
						<input :type="usernameType" autocomplete="username" ref="username" v-model.trim="loginForm.username" :placeholder="usernameType === 'email' ? $t('Email') : $t('Mobile Number')" />
					</float-label>
				</el-form-item>
				<el-form-item prop="password">
					<float-label class="styled" :label="$t('Password')">
						<input :type="passwordType" autocomplete="current-password" ref="password" v-model="loginForm.password" :placeholder="$t('Password')" @keyup.enter="handleLogin(accName)" />
					</float-label>
					<span class="show-pwd" @click.stop="showPwd">
						<svg-icon :name="passwordType === 'password' ? 'eye-off' : 'eye-on'"></svg-icon>
       		 		</span>
				</el-form-item>
				<div class="flex text-left pb-20">
					<el-radio v-model="loginForm.appType" label="Staff" @change="setAppTypeData()">{{$t('Staff')}}</el-radio>
					<el-radio v-model="loginForm.appType" label="User" @change="setAppTypeData()">{{sysType!=='school' ? $t('Member') : $t('Student or Parent')}}</el-radio>
				</div>
				<div class="flex pb-20">
					<div class="box grow text-left reset-password"><router-link to="/change-password">{{$t('First Time User/Reset Password')}}</router-link></div>
					<div class="box text-right"><elem-lang class="set-language" /></div>
					<div class="box align-center text-right">
						<el-dropdown class="set-country" @command="onCommandCountry">
							<span class="el-dropdown-link">
								<i class="flag-icon" :class="{['flag-icon-'+countryCode]:true}"></i>
							</span>
							<el-dropdown-menu slot="dropdown" style="margin-top:0">
								<el-dropdown-item command="id"><i class="flag-icon flag-icon-id mr-15"></i>{{$t('Indonesia')}}</el-dropdown-item>
								<el-dropdown-item command="my"><i class="flag-icon flag-icon-my mr-15"></i>{{$t('Malaysia')}}</el-dropdown-item>
								<el-dropdown-item command="ph"><i class="flag-icon flag-icon-ph mr-15"></i>{{$t('Philippines')}}</el-dropdown-item>
								<el-dropdown-item command="sg"><i class="flag-icon flag-icon-sg mr-15"></i>{{$t('Singapore')}}</el-dropdown-item>
								<el-dropdown-item command="th"><i class="flag-icon flag-icon-th mr-15"></i>{{$t('Thailand')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<el-alert v-if="error" :title="error" type="error" :closable="false" show-icon></el-alert>
				<div class="flex text-center center pt-10 pb-10">
					<el-button plain size="small" :loading="loading" @click.native.prevent="handleLogin(accName)" class="login-btn tada animated">
						{{$t('Login')}}
					</el-button>
				</div>
			</div>
		</el-form>
	</elem-scroll>
</template>

<script lang="ts">

	import { Vue, Component } from 'vue-property-decorator';
	import { setAppType, getAppType, getAccount, setAccount } from '../../services/common/cookies.service';
	import i18n, { setMessage } from '@/lang';
	import i18nPassword from '@/lang/common/password';
	import i18nLogin from '@/lang/common/login';
	import VueFloatLabel from 'vue-float-label';
	import { Button, Checkbox, Input, Form, Radio, FormItem, Alert } from 'element-ui';
	import ElemScroll from '@/views/component/elem-scroll.vue';
	import ElemLang from '@/views/component/elem-lang.vue';
	import '@/icons/components/eye-off';
	import '@/icons/components/eye-on';
	import { UserStore } from '@/store/user.store';
	import ValidationService from '@/services/common/validation.service';
	import {getLocale} from '@/lang';
	import { MenuStore } from '@/store/menu.store';
	import AccountsService from '@/services/hub/accounts.service';
	import RouteService from '@/services/common/route.service';
	import ElementService from '@/services/common/element.service';

	Vue.component(Button.name, Button);
	Vue.component(Checkbox.name, Checkbox);
	Vue.component(Radio.name, Radio);
	Vue.component(Form.name, Form);
	Vue.component(FormItem.name, FormItem);
	Vue.component(Alert.name, Alert);
	Vue.component('elem-scroll', ElemScroll);
	Vue.use(VueFloatLabel);

	@Component({
		components: {
			ElemLang,
		},
	})
	export default class Login extends Vue {

		private usernameType: string = 'text'; // UserStore.verificationType === 'mobile' ? 'tel' : 'email';
		private countryCode = UserStore.countryCode;
		private sysType = UserStore.sysType || '';
		private sysName = UserStore.accName || UserStore.sysName || '';
		private accName = UserStore.accName || '';
		private appType = 'Staff';
		private passwordType = 'password';
		private loading = false;
		private error: string = '';
		private validator: ValidationService = new ValidationService();
		private loginForm: any = {
			username: '',
			password: '',
			appType: getAppType() || 'Staff',
			language: getLocale(),
		};

		private onCommandCountry(countryCode: string) {
			this.countryCode = countryCode;
			UserStore.SetCountryCode(countryCode);
		}

		private loginRules = {
			username: [{ validator: this.validator.validateRequired, trigger: 'blur' }],
			password: [{ validator: this.validator.validatePassword, trigger: 'blur' }],
		};

		private showPwd() {
			if (this.passwordType === 'password') {
				this.passwordType = 'text';
			} else {
				this.passwordType = 'password';
			}
			this.$nextTick(() => {
				(this.$refs.password as Input).focus();
			});
		}

		private setAppTypeData() {
			setAppType(this.loginForm.appType);
		}

		private handleLogin(accName: string, reset: boolean) {
			this.error = '';
			setAppType(this.loginForm.appType);
			(this.$refs.loginForm as Form).validate(async (valid: boolean) => {
				if (valid) {
					try {
						this.loading = true;
						this.loginForm.language = UserStore.language;
						this.loginForm.accName = accName;
						let currentPath = '/';
						if (!reset) {
							const acc = RouteService.getQueryParam('account');
							if (acc || window.location.href.indexOf('account') >= 0) {  // Hub may pass account = all.
								this.loginForm.account = acc;
							}
							if (this.$route.query.redirect) {
								this.loginForm.system = currentPath = String(this.$route.query.redirect);
							}
						}
						await UserStore.Login(this.loginForm);
						this.$router.onReady(async () => {
							if (RouteService.checkExist(this.$router, {path: currentPath})) {
								await RouteService.reload(this.$router, currentPath, {}, false);
							} else if (currentPath.indexOf('http') === 0) {
								window.location.href = currentPath;
							} else {
								await AccountsService.routeToSystem(this.$router, MenuStore.system);
							}
						});
					} catch (err) {
						if (accName && err && (err as any).status === 424) {
							ElementService.confirm(
								i18n.t((err as any).message) as string,
								accName, {
								confirmButtonText: i18n.t('Continue to login to') as string + ' ' + UserStore.sysName,
								cancelButtonText: i18n.t('Close') as string,
								type: 'error',
							}, () => {
								new URL(location.href).searchParams.set('account', '');
								setAccount('');
								this.loginForm.account = '';
								this.loginForm.accName = '';
								this.sysName = UserStore.sysName;
								this.handleLogin('', true);
							});
						} else {
							this.error = err ? (err as any).message : 'Error!';
						}
					} finally {
						this.loading = false;
					}
				} else {
					return false;
				}
			});
		}

		created() {
			setMessage(i18nPassword);
			setMessage(i18nLogin);
			if (this.$route.query && this.$route.query.appType) {
				const ty = String(this.$route.query.appType).toLowerCase() === 'user' ? 'User' : 'Staff';
				setAppType(ty);
				this.loginForm.appType = ty;
			}
		}

		mounted() {
			this.$nextTick(() => {
				if ((this.$refs.username as Input)) {
					(this.$refs.username as Input).focus();
				}
			});


		}
	}
</script>

<style lang="scss" scoped>

	@import '../../layouts/system-layout/scss/_variables';
	@import '../../assets/icons/flag-icon-css/css/flag-icon.css';

	.login-page {
		background: $text-color;
		margin-left: -30px;
		margin-right: -30px;

		.title {
			color: $background-color;
			background-color: $text-color;
			padding: 5px;
		}

		&.background-color-masjid, &.background-color-masjid .title {
			background-color: $background-color-masjid;
		}

		::v-deep .el-form-item {
			margin: 0 0 10px 0;
		}

		::v-deep .el-form-item__content {
			line-height: unset;
		}

		::v-deep .el-form-item__error {
			top: 70% !important;
		}

		.set-language {
			color: darken($background-color, 25%);
			position: relative;
			font-size: 25px;
			right: 0;
			cursor: pointer;
		}

		.reset-password {
			text-decoration: underline;
            text-underline-position: under;
		}

		.el-dropdown.set-country {
			line-height: 1;
			margin-left: 10px;
			font-size: 25px;

			.flag-icon {
				line-height: 1;
				box-shadow: 0 2px 5px 0 rgba(49,49,93,.1), 0 1px 2px 0 rgba(0,0,0,.08);
				cursor: pointer;
				border: 1px solid lighten($background-color, 20%);
				background-color: lighten($background-color, 20%);
			}
		}

		.show-pwd {
			position: absolute;
			right: 0;
			top: 7px;
			font-size: 16px;
			color: darken($background-color, 25%);
			cursor: pointer;
			user-select: none;
		}

		.form-wrapper {
			width: 100%;
		}

		.form-box {
			width: 100%;
			max-width: 400px;
			padding: 30px;
			box-sizing: border-box;
			margin: 20px auto;

			::v-deep .vfl-has-label.styled {
				max-width: 100%;
			}

			a {
				font-size: 14px;
				color: $text-color-accent;
				text-decoration: none;
				font-weight: 500;
			}

			.image-logo {
				min-width: 80px;
				height: 80px;
				margin: 0 auto;
				margin-bottom: 30px;
				display: block;
			}

			.login-btn ,
			.social-btn {
				width: 160px;

				&.google {
					margin-bottom: 10px;
					background-color: #d73d32;
					color: white;

					&:hover {
						border-color: #d73d32;
					}
				}
				&.facebook {
					background-color: #3f5c9a;
					color: white;

					&:hover {
						border-color: #3f5c9a;
					}
				}
			}

			.signin-box {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 768px) {
		.layout-container .container .view.login-page {
			margin-left: -5px;
			margin-right: -5px;
			max-width: calc(100% + 10px);
		}
	}
</style>
