
import i18n from '@/lang';
import request from '@/services/common/request.service';
import { UserStore } from '@/store/user.store';
import ElementService from '../common/element.service';
import LodashService from '../common/lodash.service';
import RouteService from '../common/route.service';
import UtilService from '../common/util.service';
import CommonService from '../system/common.service';
import AccountService from './account.service';

export default class AccountsService {

    static apiPath = '/api/accounts';
    private functions = (o: any) => {
        return {
            selectCountryStates: this.selectCountryStates.bind(o),
            formatLogUser: CommonService.formatLogUser,
            formatAccGroupName: this.formatAccGroupName.bind(o),
            formatGroup: this.formatGroup.bind(o),
            formatBeacon: AccountService.formatBeacon.bind(o),
        };
    }

    constructor(obj: any, meta: any) {
        ElementService.initService(obj, meta, this.functions(obj));
    }

    static async selectAccounts(val: any, item: any, view: any, args: any) {
        return request({url: `${AccountsService.apiPath}/select`, method: 'get', params: ElementService.getSelectParams(val, item, view, args)});
    }

    private selectCountryStates() {
        const data = (this as any).data;
        return  data.page && data.page.value && data.page.value.country && data.page.value.country.states ? {value: data.page.value.country.states} : {value: []};
    }

    private formatAccGroupName(val: any, item: any, ui: any) {
        return (val ? val + (item.accGroup && item.accGroup.namePath ? '<br/>Group: ' + item.accGroup.namePath : '') + (item.apiUser && item.apiUser.username ? '<br/>Integration: ' + item.apiUser.username : '') : '');
    }

    private formatGroup(val: any, item: any, ui: any) {
        switch (val) {
            case 'all':
                return i18n.t('All');
            case 'assigned':
                return i18n.t('Assigned');
            case 'main':
                return i18n.t('Main');
            default:
                return val;
        }
    }

    static async routeToSystem(router: any, currSystem: string) {
        const systems = !UtilService.isEmpty(UserStore.menu) ? LodashService.map(UserStore.menu, (m: any) => {
            return {path: m.path, title: m.title, name: m.name, query: m.meta && m.meta.args ? m.meta.args : {}};
        }) : [];
        let system = LodashService.find(systems, (m: any) => {
            return m.name === currSystem;
        });
        if (!system && systems && systems.length > 0) {
            system = systems[0];
        }
        const path = system ? system.path : '/';
        await RouteService.reload(router, path, system ? system.query : {}, false);
    }

}
