import { getAccount } from '@/services/common/cookies.service';
import { AppStore } from '@/store/app.store';
import { UserStore } from '@/store/user.store';
import { SettingsStore } from '@/store/settings.store';
import layoutConfig from '@/layouts/layout-config';
import i18n from '@/lang';
import LodashService from './lodash.service';
import UtilService from './util.service';
import ViewService from './view.service';
import MomentService from './moment.service';
import ElementService from './element.service';
export default class RouteService {

    static savedAccount = null;

    static routeLoginRedirect(url: string, appType: string, account: string) {
        const args = [];
        if (url) {
            args.push('redirect=' + url.replace(new RegExp('[?&]reload=[^&#]*(#.*)?$'), '$1').replace(new RegExp('([?&])reload=[^&]*&'), '$1'));
        }
        if (appType) { args.push('appType=' + appType); }
        if (account) { args.push('account=' + UtilService.getId(account)); }
        const loginUrl = '/login' + (args && args.length > 0 ? '?' + args.join('&') : '');
        window.location.href = loginUrl;
    }

    static checkLogin(router: any, to: any, from: any, next: any) {

        const nextFunc = () => {
            if (to.path === '/') {
                // Need to handle redirect to other full path.
                let u =  to.matched && to.matched.length > 0 && to.matched[0] ? to.matched[0].path : '';
                if (!u) {
                    const found: any = LodashService.find(UserStore.menu, (m: any) => m && m.alias === '/');
                    if (found) { u = found.path; }
                }
                const pattern = /^((http|https):\/\/)/;
                if (u && pattern.test(u)) {
                    if (!RouteService.isCurrentUrl(u)) { // Must check, otherwise goes into loop
                        window.location.href = u; // Must be external path
                    }
                    next(false);
                } else {
                    next();
                }
            } else {
                next();
            }
        };
        if (UserStore.sysType) {
            const login = UserStore._id;
            const url: string = to && to.query && to.query.redirect ? to.query.redirect : '';
            const appType: string = to && to.query && to.query.appType ? to.query.appType : '';
            const redirectFunc = () => {
                if (url && (!appType || UserStore.appType === appType)) { // redirect if already in
                    window.location.href = url;
                    next(false);
                } else {
                    nextFunc();
                }
            };
            if ((to && to.meta && !to.meta.public)) {
                if (login) {
                    if (to.name === 'login' && url !== '/login') {
                        redirectFunc(); // Already login, so redirect to url in login url
                    } else {
                        nextFunc(); // Already login, so continue on
                    }
                } else {
                    if (to.name !== 'login') {
                        const site: any = UserStore.menu ? LodashService.find(UserStore.menu, (m: any) => m.name === 'site') : null;
                        if (site && site.meta && site.meta.status === 'Published') {
                            // Route to home page
                            RouteService.loadLocationHref(site.path, site.params, site.meta && site.meta.args ? site.meta.args : {});
                        } else {
                            // Goto login page and add redirect.
                            RouteService.routeLoginRedirect(to.fullPath, appType, to && to.query ? to.query.account : '');
                            next(false);
                        }
                    } else {
                        nextFunc(); // Already in login page
                    }
                }
            } else {
                nextFunc(); // Continue on as it is a public url
            }
        } else {
            // No routing info from server??
            next();
        }
    }

    static checkChange(cb: any, cancelCb: any) {
        if (AppStore.dirty) {
            ElementService.confirm(i18n.t('You have not save changes! Continue?') as string, i18n.t('Warning') as string, {
                confirmButtonText: i18n.t('OK') as string,
                cancelButtonText: i18n.t('Cancel') as string,
                type: 'warning',
            }, () => {
                AppStore.SetDirty(false);
                cb();
            }, () => {
                cancelCb();
            });
        } else {
            cb();
        }
    }

    static setRouteAccount(to: any) {
        if (to && to.meta && to.meta.noAccount) {
            if (UserStore.account) {
                RouteService.savedAccount = UserStore.account;
            }
            UserStore.SetAccount(null);
        } else {
            if (RouteService.savedAccount && !UserStore.account && UtilService.compareObject(RouteService.savedAccount, getAccount())) {
                UserStore.SetAccount(RouteService.savedAccount);
            }
        }
    }

    static checkTransition(router: any, to: any, from: any, next: any) {
        RouteService.checkChange(() => {
            RouteService.checkLogin(router, to, from, (cont: any) => {
                if (cont !== false) {
                    setTimeout(() => { // Give time for i18n to load
                        document.title = to.meta.title ? i18n.t(to.meta.title) as string : UserStore.sysName;
                    }, 300);
                    RouteService.setRouteAccount(to);
                }
                next(cont);
            });
        }, () => {
            // next(false);
        });
    }

    static checkExist(router: any, params: any) {
        const l = router.resolve(params);
        return l && l.resolved && l.resolved.matched && l.resolved.matched.length > 0 && l.resolved.matched[0].name !== 'err-page';
    }

    static addRouterRoutes(router: any, rr: any) {
        if (rr) {
            for (const r of rr) {
                if (r) { router.addRoute(r); }
            }
        }
    }

    static addRoutes(router: any, routes: Array<{name: string, path: string, component: any}>) {
        const rr = LodashService.filter(routes, (r) => !this.checkExist(router, {path: r.path}));
        RouteService.addRouterRoutes(router, rr);
    }

    static setLayout(to: any) {
        if ((to && to.meta && to.meta.layout)) {
            SettingsStore.ChangeSetting(to.meta.layout);
        } else {
            SettingsStore.ChangeSetting(layoutConfig.contentOnly as any);
        }
    }

    static getMenu(siteName: string, routes: any[]): any[] {
        const s: any = LodashService.find(routes, (d) => d.name === siteName );
        const ret: any[] = [];
        const addMenuItem = (m: any[], e: any, children: any) => {
            return m.push({name: e.name, title: (e.meta && e.meta.title ? e.meta.title : ''), icon: (e.meta && e.meta.icon ? e.meta.icon : ''), path: e.path, children});
        };
        if (s && (!s.meta || s.meta.menu !== false)) {
            let h = false;
            if (s.children && s.children.length > 0) {
                for (const c of s.children) {
                    if (c && (!c.meta || c.meta.menu !== false)) {
                        if (c && c.children && c.children.length > 0) {
                            const dd: any = [];
                            let hh = false;
                            for (const d of c.children) {
                                if (d && (!d.meta || d.meta.menu !== false)) {
                                    if (d && d.children && d.children.length > 0) {
                                        const ddd: any[] = [];
                                        let hhh = false;
                                        for (const e of d.children) {
                                            if (e && (!e.meta || e.meta.menu !== false)) {
                                                addMenuItem(ddd, e, null);
                                                hhh = true;
                                            }
                                        }
                                        if (hhh) { addMenuItem(dd, d, ddd); hh = true; }
                                    } else {
                                        if (d && (!d.meta || d.meta.menu !== false)) {
                                            hh = true;
                                            addMenuItem(dd, d, null);
                                        }
                                    }
                                }
                            }
                            if (hh) { addMenuItem(ret, c, dd); h = true; }
                        } else {
                            if (c && (!c.meta || c.meta.menu !== false)) {
                                addMenuItem(ret, c, null);
                                h = true;
                            }
                        }
                    }
                }
            } else {
                if (h && (s && (!s.meta || s.meta.menu !== false))) { addMenuItem(ret, s, null); }
            }
        }
        return ret;
    }

    static addParamsToUrl(route: any, params: any) {
        // This does not update Vuew Router
        history.replaceState({}, '', route.path + '?' + Object.keys(params).map((key) => {
            return (encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }).join('&'));
    }

    static getQueryParam(name: string) {
        // Cannot use router.query because of addParamsToUrl does not actually update router.
        return new URL(location.href).searchParams.get(name) || '';
    }

    static removeQueryParam(route: any, names: string[]) {
        if (route.query) {
            const keys = LodashService.filter(Object.keys(route.query), (k) => !LodashService.find(names, (name) => k === name));
            for (const name of names) {
                delete route.query[name];
            }
            history.replaceState({}, '', route.path + '?' + keys.map((key) => {
                return (encodeURIComponent(key) + '=' + encodeURIComponent(route.query[key]));
            }).join('&'));
        }
    }

    static loadLocationHref(path: string, params: any, query: any) {
        let s = null;
        if ((params || query) && path && path.length > 0 && path[0] === '/') {
            s = new URLSearchParams(query || {});
            if (params) {
                const paths = path.split('/');
                for (let p = 0; p < paths.length; p++) {
                    if (paths[p] && paths[p].length > 0 && paths[p][0] === ':') {
                        const pp = paths[p].substring(1);
                        if (paths[p] && params[pp]) {
                            paths[p] = params[pp];
                            delete params[pp];
                        }
                    }
                }
                path = paths.join('/');
                for (const a in params) {
                    if (params.hasOwnProperty(a)) {
                        s.set(a, params[a]);
                    }
                }
            }
        }
        window.location.href = s ? decodeURIComponent(`${path}?${s}`) : path;
    }

    static async goto(router: any, args: {route: any, query: any}) {
        if (args && args.route) {
            router.push({name: args.route.name, params: args.route.params, query: args.query});
        } else {
            router.push({path: '/'});
        }
    }

    static goToSystem(obj: any, sys: any) {
        RouteService.checkChange(async () => {
            // TODO Have to reload for different layout
            // TODO remove the following after migration as help will not use site/site
            const currComponent = obj.$route && obj.$route.name === 'system-help' ? 'system/system' : obj.currComponent;
            if ((currComponent && (currComponent !== sys.component)) || (sys.path.indexOf('http') === 0)) {
                RouteService.loadLocationHref(sys.path, sys.params, sys.query);
            } else {
                obj.currComponent = sys.component;
                await ViewService.refreshSystem(sys.path, false);
                obj.$router.push({path: sys.path, params: sys.params, query: sys.query});
            }
        }, () => {});
    }

    static async reload(router: any, path: string, query: any, fullReload: boolean) {
        if (path) {
            const getQuery = () => {
                const sep = path.indexOf('?') >= 0 ? '&' : '?';
                return !LodashService.isEmpty(query) ? sep + Object.keys(query).map((key) => {
                    return (encodeURIComponent(key) + '=' + encodeURIComponent(query[key]));
                }).join('&') : '';
            };
            if (path && path.indexOf('http') === 0) {
                window.location.href = path + getQuery();
            } else if (fullReload) { // TODO Cant use router.replace as layout not refreshed if it is different.
                history.replaceState({}, '', path + getQuery());
                window.location.reload();
            } else {
                await router.replace({path, query: {...query, reload: String(MomentService.getNow().getTime())}});
            }
        }
    }

    static isCurrentUrl(url: string) {
        if (url && url.indexOf('http') >= 0) {
            let p = window.location.href;
            if ((url[url.length - 1] !== '/') && (p && p[p.length - 1] === '/')) {
                p = p.slice(0, -1);
            }
            return (p === url);
        } else {
            return window.location.pathname === url;
        }
    }

}
