export default {
    en: {
        'Change Language Successful!': 'Change Language Successful!',
    },
    zh: {
        'Change Language Successful!': '成功更改语言！',
    },
    ms: {
        'Change Language Successful!': 'Tukar Bahasa Berjaya!',
    },
    id: {
        'Change Language Successful!': 'Ubah Bahasa Berhasil!',
    },
};
