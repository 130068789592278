export default {
  en: {
    'Please upload.': 'Please upload.',
    'Select text and click on <i class="fa fa-link"></i> to link to a web site.': 'Select text and click on <i class="fa fa-link"></i> to link to a web site.',
  },
  zh: {
    'Please upload.': '请上传。',
    'Select text and click on <i class="fa fa-link"></i> to link to a web site.': '选择文本，然后单击 <i class="fa fa-link"></i> 链接到网站。',
  },
  ms: {
    'Please upload.': 'Sila muat naik.',
    'Select text and click on <i class="fa fa-link"></i> to link to a web site.': 'Highlight text and click on <i class="fa fa-link"></i> to link to a web site.',
  },
  id: {
    'Please upload.': 'Harap unggah.',
    'Select text and click on <i class="fa fa-link"></i> to link to a web site.': 'Highlight text and click on <i class="fa fa-link"></i> to link to a web site.',
  },
};
