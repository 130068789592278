import Vue from 'vue';
import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n';

import { getLanguage } from '@/services/common/cookies.service';

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import elementMsLocale from 'element-ui/lib/locale/lang/id'; // No Malay Locale
import elementIdLocale from 'element-ui/lib/locale/lang/id';
import commonLocale from '@/lang/common/all';
import { UserStore } from '@/store/user.store';

Vue.use(VueI18n);

// User defined lang
const messages = {
  en: {
    ...elementEnLocale,
    ...commonLocale.en,
  },
  zh: {
    ...elementZhLocale,
    ...commonLocale.zh,
  },
  ms: {
    ...elementMsLocale,
    ...commonLocale.ms,
  },
  id: {
    ...elementIdLocale,
    ...commonLocale.id,
  },
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const loc of locales) {
    if (language.indexOf(loc) > -1) {
      return loc;
    }
  }
  // Default language is english
  return UserStore && UserStore.sysLang && UserStore.sysLang.length > 0 ? UserStore.sysLang[0] : 'en';
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
  fallbackLocale: 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
});

export const setMessage = (message: any) => {
  if (message) {
    for (const m of Object.keys(message)) {
      i18n.mergeLocaleMessage(m, message[m]);
    }
  }
};

// @ts-ignore
locale.i18n((key: string, value: string) => i18n.t(key, value));

export default i18n;

