import { Vue } from 'vue-property-decorator';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

const cookies: any = {};

export const setTemp = (name: string, val: string) => {
    if (val) {
        cookies[name] = val;
    } else {
        cookies[name] = undefined;
    }
};

export const getTemp = (name: string): string => {
    return cookies[name] || '';
};

export const setPerm = (key: string, param: any) => {
    if (window.localStorage) {
        window.localStorage.setItem(key, param ? JSON.stringify(param) : '');
    } else {
        setTemp(key, param);
    }
};

export const getPerm = (key: string) => {
    if (window.localStorage) {
        const c = window.localStorage.getItem(key);
        try {
            return (c ? JSON.parse(c) : c);
        } catch (e) {
            return c;
        }
    } else {
        return getTemp(key);
    }
};

export const setCookie = (name: string, val: string) => {
    if (val) {
        Vue.$cookies.set(name, val);
    } else {
        Vue.$cookies.remove(name);
    }
};

export const getCookie = (name: string): string => {
    return Vue.$cookies.get(name);
};

// App Layout
const sidebarStatusKey = 'sidebarStatus';
export const getSidebarStatus = () => getTemp(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => setTemp(sidebarStatusKey, sidebarStatus);

// Web Version
const webVersionKey = 'webVersion';
export const getWebVersion = () => getCookie(webVersionKey);
export const setWebVersion = (webVersion: string) => setCookie(webVersionKey, webVersion);

// Account
const accountKey = 'account';
export const getAccount = () => getCookie(accountKey);
export const setAccount = (account: string) => setCookie(accountKey, account);

// Account
const appTypeKey = 'appType';
export const getAppType = () => getPerm(appTypeKey);
export const setAppType = (appType: string) => setPerm(appTypeKey, appType);


// Language
const languageKey = 'language';
export const getLanguage = () => getCookie(languageKey);
export const setLanguage = (language: string) => setCookie(languageKey, language);


// App Size
const sizeKey = 'size';
export const getSize = () => getTemp(sizeKey);
export const setSize = (size: string) => setTemp(sizeKey, size);

