export default {
    en: {
        'Get Verification Code': 'Get Verification Code',
        'Verification Code': 'Verification Code',
        'Confirm Password': 'Confirm Password',
        'Short Name': 'Short Name',
        'Get verification code again in %s minutes.': 'Get verification code again in %s minutes.',
        'Invalid verification code!': 'Invalid verification code!',
        'Must confirm password.': 'Must confirm password.',
        'Must not be less than 6 digits.': 'Must not be less than 6 digits.',
        'New passwords are not the same.': 'New passwords are not the same.',
        'You have tried too many times. Please start again.': 'You have tried too many times. Please start again.',
    },
    zh: {
        'Get Verification Code': '获取验证码',
        'Verification Code': '验证码',
        'Confirm Password': '确认密码',
        'Short Name': '简称',
        'Get verification code again in %s minutes.': '在 %s 分钟后再次获取验证码。',
        'Invalid verification code!': '错误的验证码！',
        'Must confirm password.': '必须确认密码。',
        'Must not be less than 6 digits.': '不得少于6位数字。',
        'New passwords are not the same.': '新密码不一样。',
        'You have tried too many times. Please start again.': '你已经尝试了太多次了。 请重新开始。',
     },
    ms: {
        'Get Verification Code': 'Dapatkan Kod Pengesahan',
        'Verification Code': 'Kod Pengesahan',
        'Confirm Password': 'Sahkan Kata Laluan',
        'Short Name': 'Nama Pendek',
        'Get verification code again in %s minutes.': 'Dapatkan kod pengesahan sekali lagi dalam %s minit.',
        'Invalid verification code!': 'Kod pengesahan tidak sah!',
        'Must confirm password.': 'Mesti mengesahkan kata laluan.',
        'Must not be less than 6 digits.': 'Tidak boleh kurang dari 6 digit.',
        'New passwords are not the same.': 'Kata laluan baru tidak sama.',
        'You have tried too many times. Please start again.': 'Anda telah mencuba terlalu banyak kali. Sila mulakan semula.',
    },
    id: {
        'Get Verification Code': 'Dapatkan Kode Verifikasi',
        'Verification Code': 'Kode Verifikasi',
        'Confirm Password': 'konfirmasi sandi',
        'Short Name': 'Nama Pendek',
        'Get verification code again in %s minutes.': 'Dapatkan kode verifikasi lagi dalam %s menit.',
        'Invalid verification code!': 'Kode verifikasi tidak benar!',
        'Must confirm password.': 'Harus mengonfirmasi kata sandi.',
        'Must not be less than 6 digits.': 'Tidak boleh kurang dari 6 digit.',
        'New passwords are not the same.': 'Kata sandi baru tidak sama.',
        'You have tried too many times. Please start again.': 'Anda sudah mencoba berkali-kali. Silakan mulai lagi.',
    },
};
