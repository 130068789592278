import { Vue } from 'vue-property-decorator';
import { AppStore } from '@/store/app.store';
import axios from 'axios';
import i18n from '@/lang';
import UtilService from './util.service';
import ValidationService from './validation.service';
import LodashService from './lodash.service';
import MomentService from './moment.service';
import { Message, MessageBox} from 'element-ui';

export default class ElementService {

    static getOptions(obj: any) {
        if (obj.view && obj.view.options) {
            // some Option value is dependent on other data field
            const options: any = {};
            if (obj.view.options && obj.view.options.hasOwnProperty('readOnly') && obj.view.options.readOnly && LodashService.isObject(obj.view.options.readOnly) && obj.view.options.readOnly.model) {
                options.readOnly = !!LodashService.get(obj.data, obj.view.options.readOnly.model); 
            }
            return UtilService.mergeObject(obj.defaultOptions, {...obj.view.options, ...options});
        } else {
            return obj.defaultOptions;
        }
    }

    static initSearchParams(params: any) {
        if (!params) { params = {}; }
        if (!params.search) { params.search = {}; }
        return params;
    }

    static addFieldParams(params: any, item: any) {
        if (params && params.search && params.search._fields) {  // Replace field with value from item for dependency search
            params =  UtilService.cloneObject(params);
            for (const f in params.search._fields) {
                if (params.search._fields.hasOwnProperty(f)) {
                    const searchField = params.search._fields[f] && params.search._fields[f].model ? params.search._fields[f].model : params.search._fields[f];
                    if (searchField && searchField.indexOf('.') >= 0) {
                        const fields = searchField.split('.');
                        const k = fields[fields.length - 1];
                        fields.splice(fields.length - 1, 1);
                        const key = fields.join('.');
                        const o = LodashService.get(item, key);
                        if (!UtilService.isEmpty(o)) {
                            if (LodashService.isArray(o)) {
                                params.search[f] = LodashService.map(o, k); // If array, need to get it out using map.
                                if (params.search._fields[f].operator) { // Condition may be to match all
                                    params.search[f] = {[params.search._fields[f].operator]: params.search[f]};
                                } // Default is to match any one
                            } else {
                                if (!UtilService.isEmpty(o[k])) { params.search[f] = o[k]; }
                            }
                        }
                    } else if (!UtilService.isEmpty(item[searchField])) {
                        params.search[f] = item[searchField];
                    }
                }
            }
            delete params.search._fields;
        }
        return params;
    }

    static getListParams(item: any, view: any, args: any) {
        let params = ElementService.initSearchParams(args || {});
        if (item && item.account) {
            params.account = params.search.account = UtilService.getId(item.account);
        }
        if (view && view.args) { params = LodashService.merge(params, view.args); } // Module will be sent for module level role determination
        if (view && view.tableState) { params.tableState = view.tableState; }
        return ElementService.addFieldParams(params, item);
    }

    static getSelectParams(val: any, item: any, view: any, args: any) {
        let params = ElementService.initSearchParams(args || {});
        if (val) { params = LodashService.merge(params, val); }
        if (item && item.account) {
            params.account = params.search.account = UtilService.getId(item.account);
        }
        if (view && view.args) { params = LodashService.merge(params, view.args); } // Module will be sent for module level role determination
        return ElementService.addFieldParams(params, item);
    }

    static findFormField(tabs: any[], model: string, ty: string) {
        let found;
        if (tabs) {
            for (const t of tabs) {
                if (t && t.fields) {
                    for (const f of t.fields) {
                        if (f && f.model === model && ((f.type || '') === ty)) {
                            found = f;
                            break;
                        } else if (f && f.fields) {
                            for (const f2 of (f.fields)) {
                                if (f2 && f2.model === model && ((f2.type || '') === ty)) {
                                    found = f2;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return found;
    }

    static setDirty(form: any, d: boolean) {
        if (AppStore && form && form.setDirty) {
            AppStore.SetDirty(!!d);
        }
    }

    static getTabFromField(view: any, prop: string): any {
        let tab = null;
        for (const t of view.tabs) {
            if (t && t.fields && t.fields.length > 0) {
                for (const f of t.fields) {
                    if (f && f.type === 'form') {
                        tab = ElementService.getTabFromField(f, prop);
                    } else if (f) {
                        if ((f.model || f.prop) === prop) {
                            tab = t;
                            break;
                        } else if (f.fields && f.fields.length > 0) {
                            for (const f2 of f.fields) {
                                if (f2) {
                                    if (f2.type === 'form') {
                                        tab = ElementService.getTabFromField(f2, prop);
                                    } else {
                                        if ((f2.model || f2.prop) === prop) {
                                            tab = t;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return tab;
    }

    static checkRequired(model: any, rule: any, value: any, cb: any) {
        const useItem = rule.item || model; // Use rule.item (current row instead of whole data) as the value of model is not the prop (e.g. employee.0.ic)
        if (rule && rule.field && (!rule.depend || UtilService.checkDependency(useItem, rule.depend))) {
            if (LodashService.isArray(rule.isRequired)) {
                let empty = true;
                for (const m of rule.isRequired) {
                    if (!UtilService.isEmpty(LodashService.get(useItem, m))) { // TODO pass in correct rule field so that we can check against default value
                        empty = false;
                        break;
                    }
                }
                cb(empty ? new Error(rule.message) : undefined);
            } else {
                cb(UtilService.isEmpty(LodashService.get(useItem, rule.model), rule.defaultRow) ? new Error(rule.message) : undefined);
            }
        } else {
            cb();
        }
    }

    static formatCompulsory(v: any, item: any, view: any, functions: any) {
        const vv = view.i18n ? i18n.t(v) : v;
        return (item.compulsory === true ? (vv + ' <i class="el-icon-star-on warning-text"></i>') : vv);
    }

    static formatOptional(v: any, item: any, view: any, functions: any) {
        const vv = view.i18n ? i18n.t(v) : v;
        return (item.optional !== true ? (vv + ' <i class="el-icon-star-on warning-text"></i>') : vv);
    }

    static isRequired(rules: any) {
        let isRequired = false;
        if (rules) {
            if (!LodashService.isArray(rules)) { rules = [rules]; }
            for (const r of rules) {
                if (r.required) {
                    isRequired = true;
                    break;
                }
            }
        }
        return isRequired;
    }

    static isPartial(rules: any) {
        let isPartial = false;
        if (rules) {
            if (!LodashService.isArray(rules)) { rules = [rules]; }
            for (const r of rules) {
                if (LodashService.isArray(r.required)) {
                    isPartial = true;
                    break;
                }
            }
        }
        return isPartial;
    }

    static setRule(rr: any, f: any, pp: any, t: any, model: any, functions: any) {
        if (f) {
            const checkRequireFunc = (rule: any, value: any, cb: any) => {
                rule.func && functions && functions[rule.func] ? functions[rule.func](model, rule, value, cb) : ElementService.checkRequired(model, rule, value, cb);
            };
            if (!LodashService.isArray(rr)) { rr = [rr]; }
            // let isRequired = f.isRequired;
            // let isPartial = f.isPartial;
            for (const r of rr) {
                if (r && pp) {
                    if (r.required) {
                        if (t) { Vue.set(t, 'required', true); }
                        Vue.set(r, 'isPartial', LodashService.isArray(r.required));
                        // if (r.isPartial) { isPartial = true; }
                        Vue.set(r, 'isRequired', r.required);
                        // isRequired = true;
                        // delete r.required;
                        Vue.set(r, 'validator', checkRequireFunc);
                        if (!r.isTranslated) { Vue.set(r, 'message', i18n.t(r.message || 'Please input data.')); }
                        Vue.set(r, 'model', pp);
                        if (f.depend) { Vue.set(r, 'depend', f.depend); }
                        if (f.options && f.options.defaultRow) {
                            Vue.set(r, 'defaultRow', f.options.defaultRow);
                        }
                    } else if (r.format) {
                        Vue.set(r, 'validator', new ValidationService().validateFormat);
                        Vue.set(r, 'model', pp);
                        if (r.message && !r.isTranslated) { Vue.set(r, 'message', i18n.t(r.message)); }
                    } else if (r.func && functions && functions[r.func]) {
                        if (!r.item) r.item = model; // functions may need the item to check on relation with other field.
                        Vue.set(r, 'validator', functions[r.func]);
                        Vue.set(r, 'model', pp);
                        if (r.message && !r.isTranslated) { Vue.set(r, 'message', i18n.t(r.message)); }
                    } else {
                        if (r.message && !r.isTranslated) { Vue.set(r, 'message', i18n.t(r.message)); }
                    }
                    if (!r.trigger) { Vue.set(r, 'trigger', 'change'); }
                    r.isTranslated = true; // prevent repeat translation
                }
            }
            // if (f.isPartial !== isPartial) { Vue.set(f, 'isPartial', isPartial); }
            // if (f.isRequired !== isRequired) { Vue.set(f, 'isRequired', isRequired); }
        }
    }

    static setFormValidate(view: any, functions: any, model: any) {
        if (view.tabs && view.tabs.length > 0) {

            // Initialize objects so that form will work
            const setValidate = (t: any, f: any) => {
                const p = f.prop || f.model;
                const r: any = f.rules ? f.rules : (view.rules && view.rules.hasOwnProperty(p) ? LodashService.get(view.rules, p) : null);
                if (r) { // Add view rules to element level rules
                    if (!f.rules) {
                        Vue.set(f, 'rules', r);
                    }
                    ElementService.setRule(r, f, p, t, model, functions);
                }
            };

            for (const t of view.tabs) {
                if (t && t.fields && t.fields.length > 0) {
                    for (const f of t.fields) {
                        if (f && f.type === 'form') {
                            ElementService.setFormValidate(f, functions, model);
                        } else if (f) {
                            if (f.type === 'list') { f.tab = t; } // To be used later for setting tab required
                            UtilService.initObject(model, f.model, f.columns && f.columns.length > 0);
                            setValidate(t, f);
                            if (f.fields && f.fields.length > 0) {
                                for (const f2 of f.fields) {
                                    if (f2) {
                                        if (f2.type === 'form') {
                                            ElementService.setFormValidate(f2, functions, model);
                                        } else {
                                            if (f2.type === 'list') { f2.tab = t; } // To be used later for setting tab required
                                            UtilService.initObject(model, f2.model, f2.columns && f2.columns.length > 0);
                                            setValidate(t, f2);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // TODO - iterate into embeded form and also list
    static updateFormFieldErrors(value: any, view: any, error: any, fieldErrors: any) {
        const message = [];
        Vue.set(error, 'fields', {});
        if (fieldErrors && fieldErrors.length > 0) {
            for (const e of fieldErrors) {
                if (e && e.field) {
                    let found = false ;
                    const parseTables = (f: any) => {
                        for (const c of f.columns) {
                            if (c && c.columns && c.columns.length > 0) {
                                parseTables(c);
                                if (found) { break; }
                            } else {
                                if (LodashService.get(value, e.field) !== undefined) {
                                    Vue.set(error.fields, e.field, (e.i18n ? i18n.t(e.message) : e.message));
                                    found = true;
                                    break;
                                }
                            }
                        }
                    };
                    const parseFields = (f: any) => {
                        for (const ff of f.fields) {
                            if (ff && ff.fields && ff.fields.length > 0) {
                                parseFields(ff);
                                if (found) { break; }
                            } else {
                                if (ff && ((ff.prop || ff.model) === e.field && UtilService.checkDependency(value, ff.depend))) {
                                    Vue.set(error.fields, e.field, (e.i18n ? i18n.t(e.message) : e.message));
                                    found = true;
                                    break;
                                }
                            }
                        }
                    };
                    if (view && view.tabs) {
                        for (const t of view.tabs) {
                            if (t && t.fields && t.fields.length > 0) {
                                for (const f of t.fields) {
                                    if (f && ((f.prop || f.model) === e.field && UtilService.checkDependency(value, f.depend))) {
                                        Vue.set(error.fields, e.field, (e.i18n ? i18n.t(e.message) : e.message));
                                        found = true;
                                        break;
                                    } else if (f && f.fields && f.fields.length > 0) {
                                        parseFields(f);
                                        if (found) { break; }
                                    } else if (f && f.columns && f.columns.length > 0) { // is a list or table
                                        parseTables(f);
                                        if (found) { break; }
                                    }
                                }
                            }
                        }
                    }
                    if (!found) {
                        message.push((e.label ? i18n.t(e.label) + ': ' : '') + (e.i18n ? i18n.t(e.message) : e.message));
                    }
                }
            }
        }
        return message && message.length > 0 ? message.join(', ') : '';
    }

    static async validateCaptcha(params: any) {
        return await new Promise((resolve, reject) => {
          axios.post(`/api/auth/recaptcha`, params)
            .then((response) => {
              if (response.data.hasErrors) {
                reject(response.data.message);
              } else {
                resolve(response.data);
              }
            })
            .catch((error) => {
              if (error.response.data.hasOwnProperty('hasErrors')) {
                reject(error.response.data.message);
              } else {
                reject(error.message);
              }
            });
        });
    }

    static initService(obj: any, meta: any, func: any) {
        obj.functions = {meta, ...obj.functions, ...func};
    }

    static addDownloadLink(url: string, filename: string) {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    static hideFieldForReset(view: any, search: any) {
        let fields = null;
        if (view && view.filters && view.filters.length > 0) {
            fields = LodashService.filter(view.filters, (c) => c && c.model && c.hideReset);
        }
        if (!LodashService.isEmpty(search) && !LodashService.isEmpty(fields)) {
            let ret: any = {};
            for (const a of Object.keys(search)) {
                if (!LodashService.find(fields, (f) => f && f.model === a)) {
                    ret[a] = search[a];
                }
            }
            return ret;
        } else {
            return search;
        }
    }

    // Filter fields from 1) Table Column Headers - view.columns 2) Top Search Bar - view.filters 3) Side Bar - view.widgets
    static removeFilterFields(view: any, search: any) {
        search = UtilService.cloneObject(search); // Dont change original
        let fields = [];
        if (view.columns && view.columns.length > 0) {
            fields = LodashService.map(view.columns, (c) => c && c.search ? (c.search.model || c.model) : null);
        }
        if (view.filters && view.filters.length > 0) {
            fields = LodashService.concat(fields, LodashService.map(view.filters, (c) => c && c.model && (!c.kind || c.kind === 'search') && !c.hideReset ? c.model : null));
        }
        if (view.widgets && view.widgets.length > 0) {
            for (const w of view.widgets) {
                if (w && w.fields && w.fields.length > 0) {
                    for (const field of w.fields) {
                        if (field && (!field.kind || field.kind === 'search') && field.model) {
                            fields.push(field.model);
                        }
                    }
                }
            }
        }
        for (const f of fields) {
            if (f && search.hasOwnProperty(f)) {
                delete search[f];
            }
        }
        return search;

    }

    static getHelpMessage(help: any, val: any, item: any, view: any, functions: any) {
        const format = (m: string) => {
            const a = (help.i18n !== false ? i18n.t(m) as string : m);
            return help.class ? `<span class="${help.class}">${a}</span>` : a;
        };
        if (help && help.messageFunc && functions && functions[help.messageFunc]) {
            return functions[help.messageFunc](val, item, view);
        } else {
            const message = help.message;
            if (message && LodashService.isArray(message)) {
                let ret = '';
                for (const m of message) {
                    if (m) { ret = ret + (ret ? '<br/>' : '') + format(m); }
                }
                return ret;
            } else {
                return message ? format(message) : message;
            }
        }
    }

    static getColWidth(model: any, col: any, pagination: any, data: any, totalWidth: number, functions: any) {
        if (col && col.nowrap) {
            let width = 0;
            let w = 0;
            if (col.model) {
                if (model && model.length > 0) {
                    for (const m of model) {
                        let content = '';
                        let v = col;
                        if (m.column && col.footer && m.column[col.footer]) { // Footer
                            content = m.column[col.footer] ? LodashService.get(data, col.footer) : '';
                            v = m.column[col.footer] || null;
                        } else {
                            content = LodashService.get(m, col.model);
                        }
                        if (v && v.format && (v.format.type === 'amount' || v.format.type === 'date' || v.format.type === 'time' || v.format.type === 'duration' || v.format.type === 'boolean' || v.format.type === 'length')) {
                            content = UtilService.formatData(content, null, v, null);
                        } else if (col.i18n && content) {
                            content = i18n.t(content) as string;
                        }
                        if (content && String(content).length > width) {
                            width = String(content).length;
                        }
                    }
                }
                w = width ? (width * 8.9) + 10 : 0;
            } else if (pagination) {
                width = String(Number(pagination.start || 0) + (Number(pagination.pageSize || 20))).length;
                w = width ? (width * 8.9 + 10) : 0;
            } else if (model) {
                width = String(model && model.length).length;
                w = width ? (width * 8.9 + 10) : 0;
            }
            return w ? (((col.nowrap !== true && w < col.nowrap) ? col.nowrap : w).toString() + 'px') : (col.width || (col.nowrap !== true ? col.nowrap.toString() + 'px' : ''));
        } else if (col.cell && col.cell.width && col.cell.width.func && functions && functions[col.cell.width.func] ) {
            return functions[col.cell.width.func](model, col, data, totalWidth);
        } else if (totalWidth && col.width && String(col.width).indexOf('%') >= 0) {
            return Number(String(col.width).replace('%', '')) * totalWidth / 100;
        } else {
            return col.width || '';
        }
    }

    static getCellClass(obj: any, data: any, view: any, functions: any) {
        const viewColumns = view && view.columns ? LodashService.filter(view.columns, (cc) => !UtilService.isEmpty(cc)) : [];
        const c = obj && obj.columnIndex && (obj.columnIndex < viewColumns.length) ? viewColumns[obj.columnIndex] : null;
        let d = obj.row;
        if (obj.row && obj.row.column) { // Footer
            d = data;
        }
        if (c && c.cell) {
            if (c.cell.type === 'badge') {
                return 'cell-badge cell-' + UtilService.getColor(viewColumns[obj.columnIndex].cell, LodashService.get(d, viewColumns[obj.columnIndex].model));
            } else if (LodashService.isString(c.cell.class)) {
                return c.cell.class;
            } else if (c.cell.class && c.cell.class.func) {
                return functions && functions[c.cell.class.func] ? functions[c.cell.class.func](LodashService.get(d, viewColumns[obj.columnIndex].model), d, viewColumns[obj.columnIndex]) : '';
            }
        } else {
            return '';
        }
    }

    static getCellValueClass(col: any, nowrap: string, wrap: string) {
        let c = col && col.nowrap ? nowrap : wrap;
        if (col && col.type === 'list') {
            c = c + ' elem-cell-list-auto';
        }
        return c;
    }

    static getPageValue(obj: any) {
        return (obj as any).data && (obj as any).data.page && (obj as any).data.page.value ? (obj as any).data.page.value : null;
    }

    static getPageView(obj: any) {
        return (obj as any).data && (obj as any).data.page && (obj as any).data.page.view ? (obj as any).data.page.view : null;
    }

    static getListValue(obj: any) {
        return (obj as any).data && (obj as any).data.list && (obj as any).data.list.value ? (obj as any).data.list.value : null;
    }

    static getListView(obj: any) {
        return (obj as any).data && (obj as any).data.list && (obj as any).data.list.view ? (obj as any).data.list.view : null;
    }

    static startDateChanged(tabs: any, item: any) {
        const endUi = ElementService.findFormField(tabs, 'endDate', 'date');
        if (endUi) {
            if (!endUi.options) { Vue.set(endUi, 'options', {}); }
            Vue.set(endUi.options, 'min', item.startDate ? MomentService.getMoment(item.startDate, '') : null);
            if (item.endDate && MomentService.getMoment(item.startDate, '').isAfter(MomentService.getMoment(item.endDate, ''))) { Vue.set(item, 'endDate', null); }
            if (!item.endDate) {
                const startUi = ElementService.findFormField(tabs, 'startDate', 'date');
                if (startUi) {
                    if (!startUi.options) { Vue.set(startUi, 'options', {}); }
                    Vue.set(startUi.options, 'max', null);
                }
            }
        }
    }

    static endDateChanged(tabs: any, item: any) {
        const startUi = ElementService.findFormField(tabs, 'startDate', 'date');
        if (startUi) {
            if (!startUi.options) { Vue.set(startUi, 'options', {}); }
            Vue.set(startUi.options, 'max', item.endDate ? MomentService.getMoment(item.endDate, '') : null);
            if (item.startDate && MomentService.getMoment(item.startDate, '').isAfter(MomentService.getMoment(item.endDate, ''))) { Vue.set(item, 'startDate', null); }
            if (!item.startDate) {
                const endUi = ElementService.findFormField(tabs, 'endDate', 'date');
                if (endUi) {
                    if (!endUi.options) { Vue.set(endUi, 'options', {}); }
                    Vue.set(endUi.options, 'min', null);
                }
            }
        }
    }

    static formatWeekDay(val: any, item: any, view: any) {
        const v = UtilService.getId(val);
        const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const index = LodashService.findIndex(weekDays, (d) => d === v);
        return index >= 0 ? MomentService.getDayNameFromNumber(index + 1) : '';
    }

    static alert(message: string, title: string, options: any, ok_cb?: any, close_cb?: any) {
        if (!options) { options = {}; }
        options.dangerouslyUseHTMLString = true;
        if (!options.type) { options.type = 'info'; }
        MessageBox.alert(message || '', title || '', options).then(async () => {
            if (ok_cb) {
                await ok_cb();
            }
        }).catch(async () => {
            if (close_cb) {
                await close_cb();
            }
        });
    }

    static confirm(message: string, title: string, options: any, ok_cb?: any, close_cb?: any) {
        if (!options) { options = {}; }
        options.dangerouslyUseHTMLString = true;
        if (!options.type) { options.type = 'info'; }
        MessageBox.confirm(message || '', title || '', options).then(async () => {
            if (ok_cb) {
                await ok_cb();
            }
        }).catch(async () => {
            if (close_cb) {
                await close_cb();
            }
        });
    }

    static toast(message: string, options: any) {
        if (!options) { options = {}; }
        options.dangerouslyUseHTMLString = true;
        if (!options.type) { options.type = 'info'; }
        options.message = message;
        Message(options);
    }

}
