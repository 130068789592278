<template>
     <div class="elem-help">
          <el-popover
               v-if="view && view.help && view.help.type === 'popup'"
               placement="top-start"
               :title="view.help.i18n !== false ? $t(view.help.title) : view.help.title"
               :trigger="trigger">
               <span slot="reference"><i :class="view.help.icon || 'far fa-hand-point-right'"></i> <span class="help-label" v-html="getHelpMessage()"></span></span>
               <div v-html="view.help.i18n !== false ? $t(view.help.content) : view.help.content"></div>
          </el-popover>
          <span v-else><i :class="view.help.icon || 'far fa-hand-point-right'"></i> <span v-html="getHelpMessage()"></span></span>
     </div>
</template>

<script lang="ts">

     import { Vue, Component, Prop } from 'vue-property-decorator';
     import { Popover } from 'element-ui';
     import ElementService from '../../services/common/element.service';
     import LodashService from '@/services/common/lodash.service';

     Vue.component(Popover.name, Popover);

     @Component
     export default class ElemHelp extends Vue {

          @Prop() view!: any;
          @Prop() readonly modelKey!: string;
          @Prop() readonly value!: any;
          @Prop() readonly functions!: any;
          @Prop({default: 'hover'}) readonly trigger!: string;

          private getHelpMessage() {
            return ElementService.getHelpMessage(this.view.help, this.value ? LodashService.get(this.value, this.modelKey) : null, this.value, this.view, this.functions);
          }

     }
</script>

<style lang="scss">

     @import '../../layouts/system-layout/scss/variables';

     .elem-help {
          font-size: 1rem;
          color: lighten($text-color, 30%);
          margin-top: 7px;

          .help-label {
               text-decoration: underline;
               text-underline-position: under;
               cursor: pointer;
          }
     }

</style>
