import Vue from 'vue';
import Vuex from 'vuex';
import { IAppState } from './app.store';
import { IUserState } from './user.store';
import { IErrorLogState } from './error-log.store';
import { IMenuState } from './menu.store';
import { ISettingsState } from './settings.store';

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
  user: IUserState;
  errorLog: IErrorLogState;
  permission: IMenuState;
  settings: ISettingsState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});
