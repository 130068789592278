export default {
    en: {
        'Continue to login to': 'Continue to login to',
        'Indonesia': 'Indonesia',
        'Malaysia': 'Malaysia',
        'Mobile Number': 'Mobile number',
        'Philippines': 'Philippines',
        'Singapore': 'Singapore',
        'Thailand': 'Thailand',
        'Email': 'Email',
        'Password': 'Password',
        'First Time User/Reset Password': 'First Time User/Reset Password',
        'Student or Parent': 'Student or Parent',
    },
    zh: {
        'Continue to login to': '继续登录',
        'Indonesia': '印度尼西亚',
        'Malaysia': '马来西亚',
        'Mobile Number': '手机号码',
        'Philippines': '菲律宾',
        'Singapore': '新加坡',
        'Thailand': '泰国',
        'Email': '电邮',
        'Password': '密码',
        'First Time User/Reset Password': '首次使用/重置密码',
        'Student or Parent': '学生或家长',
    },
    ms: {
        'Continue to login to': 'Terus log masuk ke',
        'Indonesia': 'Indonesia',
        'Malaysia': 'Malaysia',
        'Mobile Number': 'Nombor Telefon Bimbit',
        'Philippines': 'Filipina',
        'Singapore': 'Singapura',
        'Thailand': 'Thailand',
        'Email': 'E-mel',
        'Password': 'Kata laluan',
        'First Time User/Reset Password': 'Pengguna Kali Pertama/Tukar Kata Laluan',
        'Student or Parent': 'Pelajar atau Ibu Bapa',
    },
    id: {
        'Continue to login to': 'Lanjutkan untuk masuk ke',
        'Indonesia': 'Indonesia',
        'Malaysia': 'Malaysia',
        'Mobile Number': 'Nomor ponsel',
        'Philippines': 'Filipina',
        'Singapore': 'Singapura',
        'Thailand': 'Thailand',
        'Email': 'Surel',
        'Password': 'Kata sandi',
        'First Time User/Reset Password': 'Pengguna Pertama Kali/Ganti Kata Sandi',
        'Student or Parent': 'Pelajar atau Orangtua',
    },
};
