
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import { constantRoutes } from '@/router';
import store from '@/store/index';
import layoutConfig from '@/layouts/layout-config';
import UtilService from '@/services/common/util.service';

const setRoute = async (r: any, system: string) => {
    if (r.component) {
        // https://github.com/webpack/webpack/issues/2401  webpack fails with sub-directory
        const split = r.component.split('/');
        if (split.length > 1) {
            // https://github.com/webpack/webpack/issues/2401  webpack fails with sub-directory
            const requestPath: string = `${split[0]}/${split[1]}` ;
            r.component = async () => await import(`@/views/${requestPath}.vue`);
        }
    }
    if (r.meta && r.meta.layout) {
        r.meta.layout = (layoutConfig as any)[r.meta.layout];
    }
    return r;
};

export const filterAsyncRoutes = async (menu: any[], system: string, children: boolean) => {
    const res: RouteConfig[] = [];
    if (!UtilService.isEmpty(menu)) {
        for (const route of menu) {
            if (route.name === system || children) {
                const r = await setRoute({...route}, system); // Convert vuex to normal object
                if (r.children) { // Show up in menu
                    r.children = await filterAsyncRoutes(r.children, system, true);
                }
                if (r.links && r.links.length > 0) { // Show up from other ui
                    if (route.name !== system) {
                        for (const link of r.links) {
                            res.push(await setRoute({...link}, system));
                        }
                    } else {
                        // Add to children if at highest level
                        if (!r.children) { r.children = []; }
                        for (const link of r.links) {
                            r.children.push(await setRoute({...link, path: `/${system}${link.path}`}, system));
                        }
                    }
                    delete r.links;
                }
                res.push(r);
            }
        }
    }
    return res;
};

export interface IMenuState {
    system: string;
    routes: RouteConfig[];
}

@Module({ dynamic: true, store, name: 'menu' })
class Menu extends VuexModule implements IMenuState {
    system: string = '';
    routes: RouteConfig[] = [];
    menuRoutes: RouteConfig[] = [];
    noAccount: boolean = false;
    unlock: boolean = false;
    member: any = null;

    @Mutation
    private SET_SYSTEM(system: string) {
        this.system = system;
        this.member = null; // Change system, remove default member
    }

    @Mutation
    private SET_NO_ACCOUNT(noAccount: boolean) {
        this.noAccount = noAccount;
    }

    @Mutation
    private SET_UNLOCK(unlock: boolean) {
        this.unlock = unlock;
    }

    @Mutation
    private SET_MEMBER(member: any) {
        this.member = member;
    }

    @Mutation
    private SET_ROUTES(routes: RouteConfig[]) {
        this.menuRoutes = routes;
        this.routes = constantRoutes.concat(routes);
    }

    @Action({rawError: true})
    async GenerateRoutes(menu: any[]) {
        if (!UtilService.isEmpty(menu)) {
            for (const route of menu) {
                if (route && route.alias === '/') {
                    this.SET_SYSTEM(route.name as string);
                    break;
                }
            }
        }
        this.SET_ROUTES(await filterAsyncRoutes(menu, this.system, false));
    }

    @Action({rawError: true})
    SetSystem(system: string) {
        this.SET_SYSTEM(system);
    }

    @Action({rawError: true})
    SetRoute(meta: any) {
        this.SET_NO_ACCOUNT(meta.noAccount);
        if (!meta.lock) { // Preseve unlock only when switching between locked pages.
            this.SET_UNLOCK(false); // Need to unlock again
        }
    }

    @Action({rawError: true})
    SetUnLock(unlock: boolean) {
        this.SET_UNLOCK(unlock);
    }

    @Action({rawError: true})
    SetMember(member: any) {
        this.SET_MEMBER(member);
    }

}

export const MenuStore = getModule(Menu);
